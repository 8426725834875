const color = {
  paper1: 'var(--bs-flair-paper-1)',
  paper2: 'var(--bs-flair-paper-2)',
  paper3: 'var(--bs-flair-paper-3)',
  paper4: 'var(--bs-flair-paper-4)',
  paper5: 'var(--bs-flair-paper-5)',
  paper6: 'var(--bs-flair-paper-6)',
  paper7: 'var(--bs-flair-paper-7)',
  accentPaper: 'var(--bs-flair-accent-paper)',
  bgPaper: 'var(--bs-flair-bg-paper)',
  gray: 'var(--bs-gray)',
  gray1: 'var(--bs-flair-gray-1)',
  gray2: 'var(--bs-flair-gray-2)',
  gray3: 'var(--bs-flair-gray-3)',
  gray4: 'var(--bs-flair-gray-4)',
  gray5: 'var(--bs-flair-gray-5)',
  gray6: 'var(--bs-flair-gray-6)',
  accentGray: 'var(--bs-flair-accent-gray)',
  bgGray1: 'var(--bs-flair-bg-gray-1)',
  bgGray2: 'var(--bs-flair-bg-gray-2)',
  teal1: 'var(--bs-flair-teal-1)',
  teal2: 'var(--bs-flair-teal-2)',
  teal3: 'var(--bs-flair-teal-3)',
  accentTeal: 'var(--bs-flair-accent-teal)',
  bgTeal: 'var(--bs-flair-bg-teal)',
  blue1: 'var(--bs-flair-blue-1)',
  blue2: 'var(--bs-flair-blue-2)',
  blue3: 'var(--bs-flair-blue-3)',
  blue4: 'var(--bs-flair-blue-4)',
  blue5: 'var(--bs-flair-blue-5)',
  blue6: 'var(--bs-flair-blue-6)',
  accentBlue: 'var(--bs-flair-accent-blue)',
  accentBlueDark: 'var(--bs-flair-accent-blue-dark)',
  bgBlue: 'var(--bs-flair-bg-blue)',
  honey1: 'var(--bs-flair-honey-1)',
  accentHoney: 'var(--bs-flair-accent-honey)',
  bgHoney: 'var(--bs-flair-bg-honey)',
  red1: 'var(--bs-flair-red-1)',
  red2: 'var(--bs-flair-red-2)',
  red3: 'var(--bs-flair-red-3)',
  red4: 'var(--bs-flair-red-4)',
  accentRed: 'var(--bs-flair-accent-red)',
  bgRed: 'var(--bs-flair-bg-red)',
  black: 'rgb(var(--bs-black-rgb))',
  white: 'var(--bs-white)',
  primary: 'var(--bs-primary)',
  secondary: 'var(--bs-secondary)',
  success: 'var(--bs-success)',
  warning: 'var(--bs-warning)',
  danger: 'var(--bs-danger)',
  light: 'var(--bs-light)',
  dark: 'var(--bs-dark)',
  green3: 'var(--bs-flair-green-3)',
  purple1: 'var(--bs-flair-purple-1)',
  purple2: 'var(--bs-flair-purple-2)',
};

// in some cases like in chart.js, the var(--bs-flair-sth) colors doesn't work
// Please keep in sync with _user-variables.scss vars
const colorHex = {
  paper1: '#8d8c7d',
  paper2: '#d5cebc',
  paper3: '#e5e4db',
  paper4: '#f2efe6',
  paper5: '#fdfcfa',
  paper6: '#fffdfb',
  paper7: '#FAF8F7',
  accentPaper: '#8b816d',
  bgPaper: '#eeeae2',
  gray1: '#1d1d1d',
  gray2: '#50565e',
  gray3: '#c3ccd6',
  gray4: '#eeeef7',
  gray5: '#f4f4f4',
  gray6: '#dbe0e6',
  accentGray: '#8fa0b2',
  bgGray1: '#dddddd',
  bgGray2: '#edf0f3',
  teal1: '#007d71',
  teal2: '#00cbbf',
  teal3: '#c7fff8',
  accentTeal: '#00877f',
  bgTeal: '#ccf5f2',
  blue1: '#0f3e49',
  blue2: '#145361',
  blue3: '#196879',
  blue4: '#437f8c',
  blue5: '#a3c2c9',
  blue6: '#eaf3f5',
  accentBlue: '#196879',
  accentBlueDark: '#105564',
  bgBlue: '#cae1e6',
  honey1: '#ff9822',
  accentHoney: '#c36400',
  bgHoney: '#ffe5c8',
  red1: '#c4100f',
  red2: '#fd553e',
  red3: '#eb5757',
  red4: '#FF8666',
  accentRed: '#c1200a',
  bgRed: '#fed5cf',
  white: '#fff',
  purple1: '#565ADD',
  purple2: '#EEE6FF',
};

const border = {
  width: 'var(--bs-border-width)',
  radius: 'var(--bs-border-radius)',
  radiusXs: 'var(--bs-border-radius-xs)',
  radiusSm: 'var(--bs-border-radius-sm)',
  radiusLg: 'var(--bs-border-radius-lg)',
};

const spacer = {
  0: 'var(--bs-spacer-0)',
  1: 'var(--bs-spacer-1)',
  2: 'var(--bs-spacer-2)',
  3: 'var(--bs-spacer-3)',
  4: 'var(--bs-spacer-4)',
  5: 'var(--bs-spacer-5)',
  6: 'var(--bs-spacer-6)',
  7: 'var(--bs-spacer-7)',
  8: 'var(--bs-spacer-8)',
};

const input = {
  border: {
    color: 'var(--bs-input-border-color)',
    focusColor: 'var(--bs-input-focus-border-color)',
    errorColor: 'var(--bs-flair-red-1)',
  },
  placeholder: {
    color: 'var(--bs-input-placeholder-color)',
  },
  value: {
    color: color.black,
  },
  select: {
    option: {
      color: color.black,
      bg: color.white,
      focusedBg: color.gray5,
      hoverBg: color.gray5,
    },
  },
};

const font = {
  size: {
    base: 'var(--bs-fs-base)',
    xs: 'var(--bs-fs-xs)',
    sm: 'var(--bs-fs-sm)',
    lg: 'var(--bs-fs-lg)',
    1: 'var(--bs-fs-1)',
    2: 'var(--bs-fs-2)',
    3: 'var(--bs-fs-3)',
    4: 'var(--bs-fs-4)',
    5: 'var(--bs-fs-5)',
    6: 'var(--bs-fs-6)',
  },
  weight: {
    light: 'var(--bs-fw-light)',
    normal: 'var(--bs-fw-normal)',
    bold: 'var(--bs-fw-bold)',
  },
  height: {
    base: 'var(--bs-lh-base)',
    sm: 'var(--bs-lh-sm)',
    lg: 'var(--bs-lh-lg)',
  },
};

const shift = {
  self: {
    bg: '#C8DBDF',
    border: '#90B4BB',
  },
  other: {
    bg: color.paper3,
    border: color.paper2,
  },
  open: {
    unavailable: {
      bg: color.gray3,
    },
    available: {
      bg: '#F6F9F9',
      hoverBg: '#DEEAEC',
    },
    requested: {
      bg: '#E7EEEF',
      hoverBg: '#C8DBDF',
    },
  },
};

const absence = {
  specialLeave: {
    color: color.blue3,
    accentColor: color.accentBlue,
    bgColor: color.bgBlue,
  },
  vacationDays: {
    color: color.honey1,
    accentColor: color.accentHoney,
    bgColor: color.bgHoney,
  },
  homeOffice: {
    color: color.red2,
    accentColor: color.accentRed,
    bgColor: color.bgRed,
  },
  businessTrip: {
    color: color.teal2,
    accentColor: color.accentTeal,
    bgColor: color.bgTeal,
  },
  sickness: {
    color: color.paper2,
    accentColor: color.accentPaper,
    bgColor: color.bgPaper,
  },
  group: {
    color: color.gray2,
    accentColor: color.gray1,
    bgColor: color.bgGray1,
  },
  nonWorkingDay: {
    bgColor: color.gray5,
  },
  holiday: {
    bgColor: color.purple2,
  },
  warning: {
    bgColor: color.red3,
  },
  today: {
    bgColor: color.blue3,
  },
  hover: {
    bgColor: color.blue3,
  },
};

// css variables don't work for charts
const chart = {
  timeTracking: {
    gridLines: {
      color: '#C3CCD6',
    },
    ticks: {
      color: '#1D1D1D',
    },
    tracked: {
      color: '#196879',
    },
    deficit: {
      color: 'rgba(25, 104, 121, 0.2)',
    },
    overtime: {
      color: '#FD553E',
    },
    absence: {
      color: '#CAE1E6',
    },
    holiday: {
      color: '#EEEEF7',
    },
  },
  compensatoryTime: {
    gridLines: {
      color: '#C3CCD6',
    },
    ticks: {
      color: '#1D1D1D',
    },
    overtime: {
      color: '#196879',
    },
    deficit: {
      color: '#FD553E',
    },
  },
  workloadDays: {
    workday: {
      color: 'rgba(25, 104, 121, 0.2)',
    },
    nonWorkday: {
      color: '#EEEEF7',
    },
  },
  skills: {
    line: {
      borderColor: '#196879',
      backgroundColor: '#a3c2c9',
    },
    point: {
      color: '#196879',
    },
    gridLines: {
      color: '#C3CCD6',
    },
    ticks: {
      color: '#C3CCD6',
    },
    labels: {
      color: '#1D1D1D',
    },
  },
};

const skills = {
  level: {
    1: {
      bgColor: '#D0EEED',
    },
    2: {
      bgColor: '#9FE6E2',
    },
    3: { bgColor: '#84E1DD' },
    4: { bgColor: '#49D7CF' },
    5: { bgColor: color.teal2 },
    na: { bgColor: color.paper3 },
    none: { bgColor: color.bgGray2 },
  },
};

const goals = {
  statusBar: {
    DRAFT: {
      color: color.bgGray1,
    },
    BEHIND: {
      color: color.bgHoney,
    },
    ON_TRACK: {
      color: color.bgBlue,
    },
    DONE: {
      color: color.blue3,
    },
    CANCELED: {
      color: color.gray3,
    },
  },
  statusInput: {
    DRAFT: {
      color: color.gray2,
    },
    BEHIND: {
      color: color.honey1,
    },
    ON_TRACK: {
      color: color.blue4,
    },
    DONE: {
      color: color.blue3,
    },
    CANCELED: {
      color: color.gray2,
    },
  },
};

const workflows = {
  status: {
    NOT_STARTED: { color: color.gray3, softColor: color.bgGray1 },
    IN_PROGRESS: { color: color.honey1, softColor: color.bgHoney },
    PENDING_APPROVAL: { color: color.blue4, softColor: color.bgBlue },
    COMPLETED: { color: color.blue3, softColor: color.bgBlue },
    REJECTED: { color: color.red3, softColor: color.bgRed },
  },
};

const expenses = {
  status: {
    APPROVED: {
      color: color.bgBlue,
    },
    PENDING: {
      color: color.bgHoney,
    },
    DECLINED: {
      color: color.bgRed,
    },
    REIMBURSED: {
      color: color.bgPaper,
    },
  },
};

const inventory = {
  status: {
    APPROVED: {
      color: color.teal2,
    },
    PENDING: {
      color: color.bgHoney,
    },
    REJECTED: {
      color: color.bgRed,
    },
  },
};
const ticket = {
  status: {
    OPEN: {
      color: color.gray3,
    },
    CLOSED: {
      color: color.teal2,
    },
    PENDING: {
      color: color.bgHoney,
    },
  },
};
const documents = {
  status: {
    APPROVED: {
      color: color.teal2,
    },
    PENDING: {
      color: color.bgHoney,
    },
    REJECTED: {
      color: color.bgRed,
    },
  },
};

const course = {
  status: {
    NOT_STARTED: { color: color.gray3, softColor: color.bgGray1 },
    IN_PROGRESS: { color: color.honey1, softColor: color.bgHoney },
    COMPLETED: { color: color.blue3, softColor: color.bgBlue },
  },
};

const changeRequest = {
  status: {
    PENDING: { color: color.honey1, softColor: color.bgHoney },
    APPROVED: { color: color.teal2, softColor: color.bgTeal },
    REJECTED: { color: color.red2, softColor: color.bgRed },
  },
};

const carouselItem = {
  iconBackground: '#EAF3F5',
  borderColor: '#E5E4DB',
};

const companyUpdates = {
  paper2: '#AAA997',
};

const employeeHistory = {
  date: '#AAA997',
};

export const Theme = {
  color,
  colorHex,
  course,
  border,
  spacer,
  input,
  font,
  shift,
  absence,
  chart,
  skills,
  goals,
  documents,
  workflows,
  expenses,
  carouselItem,
  companyUpdates,
  inventory,
  employeeHistory,
  ticket,
  changeRequest,
};
