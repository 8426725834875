import React from 'react';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTableId,
  ExtraTableItemType,
  ExtraTableRenderSettings,
  RecordWithExtraFields,
} from './types';
import { useExtraTableColumns } from './useExtraTableColumns';
import {
  TableContent,
  Props as TableContentProps,
} from '../../manager/components/TableContent';
import { toInputValue } from './logic';
import { SkeletonTable } from '../Skeleton/Table';
import {
  TableContentManual,
  Props as TableContentManualProps,
} from '../../manager/components/TableContentManual';

type TableContentManualVarProps<T extends RecordWithExtraFields> = {
  useTableContentManual: true;
} & Omit<TableContentManualProps<ExtraTableItemType<T>>, 'data' | 'columns'>;

type TableContentVarProps<T extends RecordWithExtraFields> = {
  useTableContentManual?: false | undefined;
} & Omit<TableContentProps<ExtraTableItemType<T>>, 'data' | 'columns'>;

type Props<T extends RecordWithExtraFields> = (
  | TableContentManualVarProps<T>
  | TableContentVarProps<T>
) & {
  objectApiName: ExtraFieldsSupportedObject;
  renderSettings?: ExtraTableRenderSettings<T>;
  records: T[];
  tableId: ExtraFieldsTableId;
};

export const ExtraFieldsTable = <T extends RecordWithExtraFields>({
  records,
  objectApiName,
  renderSettings,
  tableId,
  useTableContentManual,
  ...tableContentProps
}: Props<T>) => {
  const { columns, loading } = useExtraTableColumns<T>({
    objectApiName,
    renderSettings,
    tableId,
  });
  if (loading) {
    return <Loading />;
  }
  const data: ExtraTableItemType<T>[] = records.map((record) =>
    enrichWithExtraFields<T>(record),
  );
  if (useTableContentManual) {
    return (
      <TableContentManual
        {...tableContentProps}
        data={data}
        columns={columns}
      />
    );
  }
  return <TableContent {...tableContentProps} data={data} columns={columns} />;
};

const Loading: React.FC = () => (
  <SkeletonTable className="card-table" columns={4} rows={15} />
);

function enrichWithExtraFields<T extends RecordWithExtraFields>(
  record: T,
): ExtraTableItemType<T> {
  const extraFieldData = record.extraFields.reduce(
    (acc, extraField) => {
      acc[extraField.fieldApiName] = toInputValue(extraField);
      acc.rawExtraFields[extraField.fieldApiName] = extraField;
      return acc;
    },
    { rawExtraFields: {} } as ExtraTableItemType<T>,
  );
  return {
    ...record,
    ...extraFieldData,
  };
}
