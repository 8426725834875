import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import FlairButton from '../../../../../../atomic/atoms/FlairButton';
import { DocumentUploadModalConnected } from '../../../Documents2/DocumentUploadModal/DocumentUploadModalConnected';
import { useUserInfo } from '../../../../context/UserInfo';
import { useState } from 'react';
import { useDocumentCategoriesQuery } from '../../../../__generated__/graphql';
import { useFolder } from '../../../Documents2/hooks/useFolder';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';

const i18Path = 'dashboard.profileWidget';

const UploadDocument: React.FC = () => {
  const history = useHistory();
  const t = useNamespacedTranslation(i18Path);
  const { id } = useUserInfo();
  const { currentFolderId } = useFolder({
    employeeId: id,
  });
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);

  const { data, loading, error } = useDocumentCategoriesQuery();

  const categoryOptionsWithId =
    data?.documentCategories.map((c) => ({
      value: c.Id,
      label: c.Name,
    })) ?? [];

  const showUploadModal = () => setUploadVisible(true);

  const handleOnSuccess = () =>
    history.push(routes.documentsPersonal.route.create({}));

  return (
    <>
      <FlairButton
        label={t('uploadDocument')}
        icon="file-arrow-up"
        onClick={showUploadModal}
        isProcessing={loading}
        disabled={!!error}
      />
      <DocumentUploadModalConnected
        employeeId={id}
        onClose={() => setUploadVisible(false)}
        visible={uploadVisible}
        categories={categoryOptionsWithId}
        currentFolderId={currentFolderId}
        onSuccess={handleOnSuccess}
        hideVisibilitySelection={true}
      />
    </>
  );
};

export default UploadDocument;
