import React, { useMemo } from 'react';
import { ExtraTableRenderSettings } from '../../components/ExtraFields';
import { AbsenceRequest } from './types';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { ExtraFieldsTableCustomColumns } from '../../components/ExtraFields/types';
import { Link } from '../../../../Router';
import { routes } from '../../routes';
import { EmployeeWithAvatar } from '../../components/Employee';
import AbsenceDateTimeRange from '../../pages/Absences/components/AbsenceDateTimeRange';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import AbsenceCategoryIcon from '../../components/AbsenceCategoryIcon';
import { useAbsencesTableRenderSettings } from '../../pages/Absences/useAbsencesTableRenderSettings';
import AbsenceStatus from '../../pages/Absences/components/AbsenceStatus';
import FormattedDateTime from '../../../../components/datetime/FormattedDateTime';

type Props = {
  onLoomVideoClick: (loomVideo: AbsenceRequest['loomVideo']) => void;
};

const i18Path = 'absences.myAbsences.table';

export const useAbsenceRequestTableRenderSettings = ({
  onLoomVideoClick,
}: Props) => {
  const t = useNamespacedTranslation(i18Path);

  const { columns: absenceColumns } =
    useAbsencesTableRenderSettings<AbsenceRequest>({
      onLoomVideoClick,
    });

  const columns: ExtraTableRenderSettings<AbsenceRequest>['columns'] =
    useMemo(() => {
      return {
        ...absenceColumns,
        [ExtraFieldsTableCustomColumns.absences.employee]: {
          Header: t('employee'),
          Cell: (props) => (
            <Link
              to={routes.absenceRequestToMe.route}
              absenceRequestId={props.row.original.id}>
              <EmployeeWithAvatar employee={props.row.original.requester} />
            </Link>
          ),
        },
        [ExtraFieldsTableCustomColumns.absences.name]: {
          Header: t('duration'),
          accessor: 'startDate', // for sorting
          Cell: ({ row }) => {
            return (
              <Link
                to={routes.absenceRequestToMe.route}
                absenceRequestId={row.original.id}>
                <AbsenceDateTimeRange absence={row.original} />
                {row.original.comment && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip`}>{row.original.comment}</Tooltip>
                    }>
                    <span>
                      <FlairIcon
                        role="button"
                        className={'ms-2 cursor-auto'}
                        icon="chatbubble-outline"
                      />
                    </span>
                  </OverlayTrigger>
                )}
                {row.original.attachments.length > 0 && (
                  <span>
                    <FlairIcon
                      role="button"
                      className={'ms-2'}
                      icon="document-outline"
                    />
                  </span>
                )}
              </Link>
            );
          },
        },
        [ExtraFieldsTableCustomColumns.absences.absenceCategory]: {
          Header: t('type'),
          accessor: 'categoryName',
          Cell: (props) => (
            <div className="d-flex gap-2">
              <AbsenceCategoryIcon icon={props.row.original.categoryIcon} />
              <span>{props.row.original.categoryName}</span>
            </div>
          ),
        },
        flair__Approval_Status__c: {
          Header: t('status'),
          Cell: ({ row }) => {
            return <AbsenceStatus status={row.original.approvalStatus} />;
          },
        },
        CreatedDate: {
          Header: t('requested'),
          Cell: ({ value }) => {
            return <FormattedDateTime dateTime={value} format="long" />;
          },
        },
      };
    }, [t, absenceColumns]);

  return {
    columns,
  };
};
