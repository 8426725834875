import {
  AbsenceApprovalRequestToMeFragment,
  AbsenceApprovalStatus,
} from '../../../__generated__/graphql';
import { mapDocumentFile } from '../../../pages/Absences/mappings';
import { mapApprovalRequests } from '../../../pages/Absences/MyAbsences/mappings';
import { mapLoomVideo } from '../../../components/LoomVideo';
import { AbsenceRequest } from '../types';

export const mapAbsenceRequests = (
  src: AbsenceApprovalRequestToMeFragment[],
): AbsenceRequest[] => {
  return src.map((item) => {
    return {
      id: item.Id,
      absenceId: item.absence.Id,
      approvalStatus: item.absence.flair__Approval_Status__c,
      categoryName: item.absence.flair__Category_Name__c,
      categoryIcon: item.absence.flair__Category_Icon__c,
      amount: item.absence.flair__Amount__c,
      workingAmount: item.absence.flair__Working_Amount__c,
      startDate: item.absence.flair__Start_Date__c,
      startTime: item.absence.flair__Start_Time__c,
      startDateHalfDay: item.absence.flair__Start_Date_Half_Day__c,
      endDate: item.absence.flair__End_Date__c,
      endTime: item.absence.flair__End_Time__c,
      endDateHalfDay: item.absence.flair__End_Date_Half_Day__c,
      type: item.absence.flair__Type__c,
      requester: {
        id: item.absence.employee.Id,
        name: item.absence.employee.Name,
        avatarUrl: item.absence.employee.avatar?.url,
      },
      approvalRequests: mapApprovalRequests(item.absence.approvalRequests),
      comment: item.absence.flair__Comment__c,
      commentsCount: item.absence.commentsCount,
      createdDate: item.CreatedDate,
      lastModifiedDate: item.LastModifiedDate,
      attachments:
        item.absence.ContentDocumentLinks__r?.map(mapDocumentFile) ?? [],
      recordId: item.absence.Id,
      loomVideo: mapLoomVideo(item.absence.loomVideo),
      // absence id and extra fields
      extraFields: item.absence.extraFields,
      Id: item.absence.Id,
    };
  });
};

export const filterOutNotPending = (
  src: ReadonlyArray<AbsenceApprovalRequestToMeFragment>,
): AbsenceApprovalRequestToMeFragment[] =>
  src.filter(
    (x) =>
      x.absence.flair__Approval_Status__c === AbsenceApprovalStatus.Pending,
  );
