import React, { useCallback } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import {
  RequesterType,
  useFetchDocumentTemplateQuery,
  useGenerateAndFetchPdfForPerformanceReviewMutationMutation,
  useTrackDownloadPdfEventMutation,
  UserRole,
} from '../../../__generated__/graphql';
import { mapDocumentTemplate } from './mappings';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type DownloadPdfProps = {
  feedbackOutcomeId: string;
  employeeId: string;
  userRole: UserRole;
};

const i18Path = 'employeePage.performanceReview';

const DownloadPdf: React.FC<DownloadPdfProps> = ({
  feedbackOutcomeId,
  employeeId,
  userRole,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const errorHandler = useMutationErrorHandler();

  const {
    data: documentTemplatesData,
    loading: loadingDocumentTemplates,
    error: errorDocumentTemplates,
  } = useFetchDocumentTemplateQuery({
    variables: {
      feedbackOutcomeId,
    },
  });

  const [getDownloadPdfLink, { loading: loadingDownloadingPDF }] =
    useGenerateAndFetchPdfForPerformanceReviewMutationMutation();

  const [trackDownloadPDFEvent] = useTrackDownloadPdfEventMutation();

  const handleDownload = useCallback(
    async (templateId: string) => {
      const requesterType =
        userRole === UserRole.Reviewer
          ? RequesterType.Manager
          : RequesterType.Employee;

      await getDownloadPdfLink({
        variables: {
          recordId: feedbackOutcomeId,
          templateId,
          requesterType,
        },
      })
        .then((result) => {
          const downloadLink = result.data?.documentTemplate?.getDownloadLink;

          if (downloadLink) {
            window.open(downloadLink, '_blank');
          }

          trackDownloadPDFEvent({
            variables: {
              input: {
                templateId,
                employeeId: employeeId,
                outcomeId: feedbackOutcomeId,
                userRole,
                platform: 'HUB',
              },
            },
          });
        })
        .catch(errorHandler);
    },
    [
      getDownloadPdfLink,
      feedbackOutcomeId,
      errorHandler,
      trackDownloadPDFEvent,
      employeeId,
      userRole,
    ],
  );

  if (errorDocumentTemplates) {
    return <ServerError />;
  }

  const documentTemplates =
    documentTemplatesData?.documentTemplates?.documentTemplatesForPR.map(
      mapDocumentTemplate,
    );

  const isLoading = loadingDocumentTemplates || loadingDownloadingPDF;

  if (!documentTemplates || documentTemplates.length === 0) {
    return null;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        disabled={isLoading}>
        {isLoading ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            {t('generatingPdf')}
          </>
        ) : (
          t('downloadPdf')
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-2">
        {documentTemplates.map((template) => (
          <Dropdown.Item
            key={template.value}
            onClick={() => handleDownload(template.value)}
            disabled={isLoading}>
            {template.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadPdf;
