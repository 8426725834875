import React, { useEffect, useMemo } from 'react';
import { FieldInfo } from '../../EmployeeData';
import { FormGroup } from '../../../../../components/form/FormGroup';
import InputLabel from '../../../../../components/form/InputLabel';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { useExtraFieldLookupOptionsQuery } from '../../../__generated__/graphql';
import { SelectDropdownControlled } from '../../../../../components/form/Selects';
import { ExtraFieldContextParam } from '../types';
import { useErrorHandler } from '../../../../../hooks/useErrorHandler';

type Props = {
  fieldId: string;
  fieldInfo: FieldInfo;
  contextParams?: ExtraFieldContextParam[];
};

export const ExtraFieldLookup: React.FC<Props> = ({
  fieldId,
  contextParams,
  fieldInfo,
}) => {
  const { data, loading, error } = useExtraFieldLookupOptionsQuery({
    variables: { id: fieldId, contextParams: contextParams ?? [] },
  });

  const addError = useErrorHandler();

  useEffect(() => {
    if (error) {
      addError(error);
    }
  }, [error, addError]);

  const options = useMemo(() => {
    if (!data?.extraFields.extraFieldLookupOptions.length) {
      return [];
    }
    return [
      ...data.extraFields.extraFieldLookupOptions.map((x) => ({
        value: x.id,
        label: x.label,
      })),
    ];
  }, [data]);

  return (
    <FormGroup>
      <InputLabel
        label={fieldInfo.label}
        required={fieldInfo.required}
        hint={
          fieldInfo.hint ? (
            <InfoHint className="mt-2" text={fieldInfo.hint} />
          ) : undefined
        }
      />
      <SelectDropdownControlled
        isLoading={loading}
        name={fieldInfo.name}
        options={options}
        heightMode="large"
      />
    </FormGroup>
  );
};
