import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PendingApprovalCard from '../../../pages/Home/PendingApprovalCard';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import EmployeesList from './EmployeesList';
import { ManagerReportsModeSwitch } from '../../components/ManagerReportsModeSwitch';

export const MyTeamOverview: React.FC = () => {
  const [reportsMode, setReportsMode] = useManagerReportsMode('myteam');

  return (
    <>
      <Row>
        <Col className="text-end">
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <EmployeesList />
        </Col>
        <Col md={4}>
          <PendingApprovalCard />
        </Col>
      </Row>
    </>
  );
};
