import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../components/button';
import ServerError from '../../../../../../components/ServerError';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { Redirect } from '../../../../../../Router';
import { EmployeeFeedbackQuestionnaire } from '../../../../components/EmployeeFeedbackQuestionnaire';
import PageHeader from '../../../../components/PageHeader';
import { useUserInfo } from '../../../../context/UserInfo';
import { routes } from '../../../../routes';
import {
  EmployeeFeedbackState,
  FeedbackCycleState,
  FeedbackCycleTimelineTypes,
  ManagerEmployeeReviewsDocument,
  PerformanceEmployeeEvaluationDocument,
  ReviewsListDocument,
  usePerformanceEmployeeEvaluationQuery,
  useSubmitEmployeeFeedbackMutation,
} from '../../../../__generated__/graphql';
import { useReviewerSignAndFinish } from '../../helpers';
import {
  isViewer,
  visibleForManager,
  visibleForPeers,
} from '../EmployeeFeedbacks/utils';
import FeedbackStatusSection from '../EmployeePerformanceReviewMeeting/FeedbackStatusSection';
import EvaluationProgressSection from '../EvaluationProgressSection';
import FeedbackMeetingSection from '../FeedbackMeetingSection';
import { GoalsSection } from '../Goals2/GoalsSection';
import { LoadingEmployeeEvaluation } from './LoadingEmployeeEvaluation';
import './styles.css';
import ConfirmButton from '../../../../../../components/button/ConfirmButton';
import PeerReviews from './PeerReviews';
import { usePeerReviewsHook } from '../hooks/usePeerReviewsHook';
import AssignPeerReviewersWarning from './AssignPeerReviewersWarning';
import { useFeedbackRestriction } from '../../../../hooks/performanceReview/feedbackCycle/useFeedbackRestriction';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { EmployeeFeedbackMeetingModal } from '../../../../components/EmployeeFeedbackMeetingModal';
import { useFlairBreadcrumbHook } from '../../../../../../hooks/useFlairBreadcrumbHook';

type Params = {
  feedbackId: string;
};

const i18Path = 'employeePage.performanceReview.meeting';

const Content: React.FC<Params> = ({ feedbackId }) => {
  const { id: myId } = useUserInfo();
  const t = useNamespacedTranslation(i18Path);
  const tNav = useNamespacedTranslation('navigation.menuItems.teamDevelopment');
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [showAssignPeersWarningModal, setShowAssignPeersWarningModal] =
    useState<boolean>(false);
  const [showScheduleMeetingModal, setShowScheduleMeetingModal] =
    useState<boolean>(false);

  const { data, loading, error } = usePerformanceEmployeeEvaluationQuery({
    variables: {
      feedbackId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    isPeerReviewsEnabled,
    isPeerReviewer,
    hasAnyNonFinishedPeerFeedback,
    peerFeedbacks,
  } = usePeerReviewsHook(data?.employeeFeedback);

  useFlairBreadcrumbHook(
    [
      { label: tNav('title') },
      {
        label: tNav('subMenuItems.reviews.title'),
        url: routes.performanceReviewsForReviewer.route.create({}),
      },
      { label: data?.employeeFeedback.feedbackCycle.Name ?? '' },
    ],
    loading,
  );

  const { isFeedbackCycleIsInactive } = useFeedbackRestriction({
    isReviewer: !isPeerReviewer,
  });

  const [submitFeedback, { loading: submitting }] =
    useSubmitEmployeeFeedbackMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [
        {
          query: ReviewsListDocument,
        },
        {
          query: ManagerEmployeeReviewsDocument,
        },
      ],
    });

  const errorHandler = useMutationErrorHandler();
  const [isFinishing, signAndFinish] = useReviewerSignAndFinish();

  const handleSubmitFeedback = useCallback(async () => {
    await submitFeedback({
      variables: {
        input: {
          id: feedbackId,
        },
      },
    })
      .then()
      .catch(errorHandler);
  }, [submitFeedback, feedbackId, errorHandler]);

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingEmployeeEvaluation />;
  }

  const feedback = data.employeeFeedback;

  const {
    flair__Meeting_Required__c: meetingRequired,
    flair__Manage_Goals__c: manageGoals,
    flair__Number_of_Peers__c: maxNumberOfPeers,
    flair__With_Peers__c: withPeers,
    flair__Timeline_Type__c: timelineType,
    flair__Signature_required__c: signatureRequired,
  } = feedback.feedbackCycle;

  if (feedback.flair__Self_Evaluation__c) {
    return (
      <Redirect
        to={routes.selfPerformanceEvaluation.route}
        feedbackId={feedbackId}
      />
    );
  }

  if (
    feedback.flair__Feedback_From__c !== myId ||
    (feedback.outcome.flair__Ended_At__c && !isPeerReviewer)
  ) {
    return (
      <Redirect
        to={routes.performanceReviewerPreviousReviews.route}
        feedbackId={feedbackId}
      />
    );
  }

  if (feedback.flair__Submitted_At__c && meetingRequired && !isPeerReviewer) {
    return (
      <Redirect
        to={routes.performanceReviewerMeeting.route}
        feedbackId={feedbackId}
      />
    );
  }

  const isSubmitted =
    feedback.flair__State__c === EmployeeFeedbackState.Submitted;
  const isCycleCompleted =
    feedback.feedbackCycle.flair__State__c !== FeedbackCycleState.InProgress;

  const isQuestionnaireReadOnly =
    isCycleCompleted || (isPeerReviewer && isSubmitted);

  const isInactive = isFeedbackCycleIsInactive(
    timelineType,
    feedback.employeeFeedback?.flair__State__c,
  );

  const isSequentialTimelineType =
    timelineType === FeedbackCycleTimelineTypes.Sequential && !isPeerReviewer;

  const employeeAnswers = isSequentialTimelineType
    ? {
        answers: feedback.employeeFeedback?.answers ?? [],
        employeeName: feedback.reviewee.Name,
        submittedAt: feedback.employeeFeedback?.flair__Submitted_At__c,
      }
    : null;

  const { Name: employeeName, Id: employeeId } = feedback.reviewee;

  const renderSignAndCompleteBtn =
    !isPeerReviewer && !meetingRequired && isSubmitted;

  const isSignAndCompleteBtnDisabled =
    withPeers && hasAnyNonFinishedPeerFeedback;

  const isSubmitBtnDisabled = !isComplete || isInactive;

  const getSubmitBtnDisabledTooltip = () => {
    if (!isComplete) {
      return t('pleaseFillRequiredFields');
    } else if (isInactive && !isPeerReviewer) {
      return t('disabledTooltipForEmployeeSubmission');
    }

    return undefined;
  };

  const isSignatureRequired =
    feedback.feedbackCycle.flair__Signature_required__c;

  const canScheduleMeeting = !isPeerReviewer && meetingRequired;
  const meetingIsScheduled =
    !!feedback.outcome.meeting?.flair__Start_Datetime__c;

  const signAndCompleteBtnLbl = t(
    isSignatureRequired ? 'signComplete' : 'complete',
  );

  const handleFinish = () => {
    return signAndFinish(feedback.outcome.Id);
  };

  const feedbackAnswers = feedback.answers.filter(
    (item) => !item.question.flair__Is_Satisfaction_Section__c,
  );

  let answers = [];
  if (isPeerReviewer) {
    answers = feedbackAnswers.filter(visibleForPeers);
  } else if (feedback.feedbackCycle.flair__See_All_Questions__c) {
    answers = feedbackAnswers;
  } else {
    answers = feedbackAnswers.filter(visibleForManager);
  }

  const onSubmit = () => {
    //If configured with peers, and the manager didn't assign the max number of peers
    if (
      withPeers &&
      !isPeerReviewer &&
      peerFeedbacks.length < maxNumberOfPeers
    ) {
      setShowAssignPeersWarningModal(true);
    } else {
      handleSubmitWithMeetingLogic();
    }
  };

  const handleSubmitWithMeetingLogic = () => {
    if (!canScheduleMeeting || meetingIsScheduled) {
      // we submit normally
      handleSubmitFeedback();
    } else {
      //we force scheduling a meeting
      setShowScheduleMeetingModal(true);
    }
  };

  const handleConfirmAssignPeersWarningModal = () => {
    setShowAssignPeersWarningModal(false);
    handleSubmitWithMeetingLogic();
  };

  return (
    <>
      <PageHeader title={feedback.feedbackCycle.Name} subtitle={employeeName} />

      <Row>
        <Col
          xl={{ span: 8, order: 1 }}
          xs={{ span: 12, order: 2 }}
          style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 15.5rem)' }}>
          <EmployeeFeedbackQuestionnaire
            answers={answers}
            setIsComplete={setIsComplete}
            readOnly={isQuestionnaireReadOnly}
            seeAllQuestions={feedback.feedbackCycle.flair__See_All_Questions__c}
            isPeerReviewer={isPeerReviewer}
            employeeAnswers={employeeAnswers}
            questionsSettings={{
              ratingMin: feedback.feedbackCycle.flair__Rating_Min__c,
              ratingMax: feedback.feedbackCycle.flair__Rating_Max__c,
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 2 }}
          xs={{ span: 12, order: 1 }}
          style={{ position: 'sticky', top: 0, height: '75vh' }}>
          {canScheduleMeeting && feedback.outcome.meeting && (
            <FeedbackMeetingSection
              meetingId={feedback.outcome.meeting.Id}
              startDateTime={feedback.outcome.meeting.flair__Start_Datetime__c}
              feedbackId={feedbackId}
              readonly={
                isViewer(feedback.flair__Feedback_From__c, myId) ||
                isCycleCompleted
              }
            />
          )}

          {!isPeerReviewer && manageGoals && (
            <GoalsSection employeeId={employeeId} employeeName={employeeName} />
          )}

          {feedback.employeeFeedback && (
            <EvaluationProgressSection
              employeeName={employeeName}
              progress={feedback.employeeFeedback.flair__Progress__c}
              submittedTime={feedback.employeeFeedback.flair__Submitted_At__c}
            />
          )}

          {!meetingRequired && isSubmitted && (
            <FeedbackStatusSection
              ended={false}
              readonly={false}
              signatureRequired={signatureRequired}
            />
          )}

          {isPeerReviewsEnabled && (
            <PeerReviews
              maxPeers={maxNumberOfPeers}
              outcomeId={feedback.outcome.Id}
              peerFeedbacks={peerFeedbacks}
              currentFeedbackRequestId={feedbackId}
              revieweeId={feedback.reviewee.Id}
              isEditingEnabled={!isSubmitted}
            />
          )}

          {renderSignAndCompleteBtn && (
            <Row className="submit-button">
              <Col className="text-end">
                <ConfirmButton
                  variant="primary"
                  className="w-100 mb-3"
                  onConfirm={handleFinish}
                  isProcessing={isFinishing}
                  disabled={isSignAndCompleteBtnDisabled}
                  label={signAndCompleteBtnLbl}
                  confirmTitle={t('confirmTitle')}
                  confirmText={t('confirmText')}
                />
              </Col>
            </Row>
          )}

          {!isSubmitted && !isCycleCompleted && (
            <Row className="submit-button">
              <Col className="text-end">
                <Button
                  className="w-100 mb-3"
                  disabled={isSubmitBtnDisabled}
                  tooltip={getSubmitBtnDisabledTooltip()}
                  label={
                    canScheduleMeeting && !meetingIsScheduled
                      ? t('submitAndScheduleMeeting')
                      : t('submit')
                  }
                  isProcessing={submitting}
                  onClick={onSubmit}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <AssignPeerReviewersWarning
        show={showAssignPeersWarningModal}
        onClose={() => setShowAssignPeersWarningModal(false)}
        handleOnProceed={handleConfirmAssignPeersWarningModal}
      />

      {feedback.outcome.meeting?.Id && (
        <EmployeeFeedbackMeetingModal
          show={showScheduleMeetingModal}
          onClose={() => setShowScheduleMeetingModal(false)}
          onSuccess={handleSubmitFeedback}
          meetingId={feedback.outcome.meeting.Id}
          startDatetime={null}
          refetchQueries={[
            {
              query: PerformanceEmployeeEvaluationDocument,
              variables: {
                feedbackId,
              },
            },
          ]}
        />
      )}
    </>
  );
};

export const EmployeeEvaluation: React.FC = () => {
  const { feedbackId } = useParams<Params>();

  return (
    <>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Content feedbackId={feedbackId} />
        </Col>
      </Row>
    </>
  );
};
