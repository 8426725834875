import React from 'react';
import { routes as r } from '../../routes';
import { MyTeamOverview } from './MyTeamOverview';
import { MyTeamChangeRequests } from './MyTeamChangeRequests';

export const routes = () => ({
  myTeamOverview: {
    route: r.myTeamOverview.route,
    render: () => <MyTeamOverview />,
  },
  myTeamChangeRequests: {
    route: r.myTeamChangeRequests.route,
    render: () => <MyTeamChangeRequests />,
  },
});
