import {
  ExtraFieldFormInfoFragment,
  FieldType,
} from '../../__generated__/graphql';
import { getExtraFieldInputName, recordTypeFormKey } from './logic';
import { useObjectExtraFields } from './useObjectExtraFields';
import * as yup from 'yup';
import { TestFunction, TestOptionsMessage } from 'yup';
import i18next from 'i18next';
import { ExtraFieldsVisibility } from './extraFieldsVisibility';
import { useCallback, useMemo } from 'react';
import {
  ExtraFieldContextParam,
  ExtraFieldsFormId,
  ExtraFieldsSupportedObject,
} from './types';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  formId: ExtraFieldsFormId;
  contextParams: ExtraFieldContextParam[];
};

export const useExtraFieldsFormValidation = ({
  objectApiName,
  contextParams,
  formId,
}: Props) => {
  const { objectExtraFields, loading } = useObjectExtraFields(objectApiName);

  const extraFieldsValidationSchema = useMemo(() => {
    if (!objectExtraFields) {
      return null;
    }
    const requiredExtraFields = objectExtraFields?.form.filter(
      (f) => f.required && f.fieldInfo.type !== FieldType.Boolean,
    );
    const visibility: ExtraFieldsVisibility = new ExtraFieldsVisibility(
      objectExtraFields,
    );
    return requiredExtraFields?.map((f) => [
      getExtraFieldInputName(f.fieldApiName, f.fieldInfo.type),
      getYupValidation(visibility, formId, f, contextParams),
    ]);
  }, [objectExtraFields, contextParams, formId]);

  const applyExtraFieldsValidationSchema = useCallback(
    (schema: yup.ObjectSchema<any>) => {
      if (
        !extraFieldsValidationSchema ||
        Object.keys(extraFieldsValidationSchema).length === 0
      ) {
        return schema;
      }
      return schema.shape(Object.fromEntries(extraFieldsValidationSchema));
    },
    [extraFieldsValidationSchema],
  );

  return {
    extraFieldsValidationSchema,
    applyExtraFieldsValidationSchema,
    loading,
  };
};

function getYupValidation(
  visibility: ExtraFieldsVisibility,
  formId: ExtraFieldsFormId,
  field: ExtraFieldFormInfoFragment,
  contextParams: ExtraFieldContextParam[],
) {
  function requiredIfVisible(this: yup.TestContext, value: any) {
    const formatData = this.parent;
    const isVisible = visibility.isFieldVisible(field, {
      recordTypeName: formatData[recordTypeFormKey],
      contextParams,
      formId,
    });
    if (!isVisible) {
      return true;
    }
    return value !== null && value !== undefined && value !== '';
  }

  function addRequiredIfVisible<T>(schema: {
    test(name: string, message: TestOptionsMessage, test: TestFunction): T;
  }) {
    return schema.test(
      'requiredIfVisible',
      i18next.t('validations.mixed.required'),
      requiredIfVisible,
    );
  }

  const fieldType = field.fieldInfo.type;
  if (fieldType === FieldType.Boolean) {
    return addRequiredIfVisible(yup.boolean());
  } else if (
    fieldType === FieldType.Integer ||
    fieldType === FieldType.Currency ||
    fieldType === FieldType.Double
  ) {
    return addRequiredIfVisible(yup.number());
  } else if (fieldType === FieldType.Date) {
    return addRequiredIfVisible(yup.date());
  } else if (fieldType === FieldType.Datetime) {
    return addRequiredIfVisible(yup.date());
  }
  return addRequiredIfVisible(yup.string());
}
