import React from 'react';
import moment from 'moment';
import { AbsenceType } from '../../../__generated__/graphql';
import AbsenceDateTime from '../../../pages/Absences/components/AbsenceDateTime';
import { DateFormat } from '../../../../../components/date/FormattedDate';
import { DateTimeFormat } from '../../../../../components/datetime/FormattedDateTime';

export type AbsenceDateTimeRangeProps = {
  startDate: string;
  startTime: string;
  type: AbsenceType;
  startDateHalfDay: boolean;
  endTime: string;
  endDate: string;
  endDateHalfDay: boolean;
};

type Props = {
  absence: AbsenceDateTimeRangeProps;
  dateFormat?: DateFormat;
  dateTimeFormat?: DateTimeFormat;
};

const AbsenceDateTimeRange: React.FC<Props> = ({
  absence,
  dateFormat = 'short',
  dateTimeFormat = 'short',
}) => {
  const startDateTimeProps = {
    date: absence.startDate,
    time: absence.startTime,
    type: absence.type,
    halfDay: absence.startDateHalfDay,
    dateFormat,
    dateTimeFormat,
  };

  const endDateTimeProps = {
    date: absence.endDate,
    time: absence.endTime,
    type: absence.type,
    halfDay: absence.endDateHalfDay,
    dateFormat,
    dateTimeFormat: moment(absence.startDate).isSame(absence.endDate, 'day')
      ? 'time'
      : dateTimeFormat,
  };

  return (
    <>
      <AbsenceDateTime {...startDateTimeProps} />
      &nbsp;-&nbsp;
      <AbsenceDateTime {...endDateTimeProps} />
    </>
  );
};

export default AbsenceDateTimeRange;
