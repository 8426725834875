import { ApolloError } from '@apollo/client';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../context/Toast';
import { getRequestInfoFromError } from './errorHandlingRequestInfo';
import { GraphQLFormattedError } from 'graphql';
import { getErrorMessage } from '../utils/errorUtils';
import i18n from 'i18next';
import { Theme } from '../theme';

export const ERROR_AUTO_DISMISS_TIMEOUT_SECONDS = 30;

type ErrorMessageHandler = (err: ApolloError) => string;

// copy-pasted from packages/server/src/apps/internal-shared/apollo/customErrorHandling.ts
// should be synced

/* eslint-disable no-unused-vars */
export enum CustomErrorCodes {
  // WARNINGS: These errors will be reported to rollbar as warnings
  // USER_FRIENDLY_BACKEND_ERROR: caused by throw new UserFriendlyError('Absence is not found') or Promise.reject(new UserFriendlyError('...'))
  USER_FRIENDLY_BACKEND_ERROR = 'USER_FRIENDLY_BACKEND_ERROR',
  // SALESFORCE_FIELD_CUSTOM_VALIDATION: caused by Salesforce FIELD_CUSTOM_VALIDATION_EXCEPTION
  SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION = 'SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION',

  // CANNOT_EXECUTE_FLOW_TRIGGER: caused by Salesforce CANNOT_EXECUTE_FLOW_TRIGGER
  CANNOT_EXECUTE_FLOW_TRIGGER = 'CANNOT_EXECUTE_FLOW_TRIGGER',

  // ERRORS: These errors will be reported to rollbar as errors
  // SALESFORCE_EXCEPTION: caused by throw new IllegalArgumentException in Salesforce code
  SALESFORCE_EXCEPTION = 'SALESFORCE_EXCEPTION',
  // BACKEND_ERROR: caused by throw new Error('Absence is not found') or Promise.reject(new Error('...')) or Promise.reject('...')
  BACKEND_ERROR = 'BACKEND_ERROR',
}

export const useSystemErrorMessageHandler = (): ErrorMessageHandler => {
  const { t } = useTranslation();

  return useCallback(
    (err: ApolloError) => {
      let errorMessagesToShow = err.graphQLErrors
        .filter(isErrorMessageVisibleToUser)
        .map((x) => x.message);

      //Remove duplicate messages
      errorMessagesToShow = [...new Set(errorMessagesToShow)];

      return errorMessagesToShow.length > 0
        ? errorMessagesToShow.join('\n')
        : t('errors.toast.genericErrorContactSupport');
    },
    [t],
  );
};

const isErrorMessageVisibleToUser = (err: GraphQLFormattedError): boolean => {
  const errorCode = err.extensions?.code;
  switch (errorCode) {
    case CustomErrorCodes.USER_FRIENDLY_BACKEND_ERROR:
    case CustomErrorCodes.SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION:
    case CustomErrorCodes.SALESFORCE_EXCEPTION:
    case CustomErrorCodes.CANNOT_EXECUTE_FLOW_TRIGGER:
      return true;
    default:
      return false;
  }
};

export const useMutationErrorHandler = (
  errorMessageHandler?: ErrorMessageHandler,
) => {
  const { addError } = useToasts();
  const defaultMessageHandler = useSystemErrorMessageHandler();

  return useCallback(
    (err: unknown) => {
      if (err instanceof ApolloError) {
        let customErrorMessage = errorMessageHandler
          ? errorMessageHandler(err)
          : '';
        let defaultMessage = defaultMessageHandler(err);
        addError(
          addErrorMetadataToMessage(customErrorMessage, defaultMessage, err),
          {
            autoDismissTimeout: ERROR_AUTO_DISMISS_TIMEOUT_SECONDS * 1000,
          },
        );
      } else {
        addError(getErrorMessage(err));
      }
    },
    [addError, defaultMessageHandler, errorMessageHandler],
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  divider: {
    border: `0.03rem solid ${Theme.color.gray2}`,
    opacity: 0.1,
  },
  errorContainer: {
    maxWidth: '17rem',
    wordBreak: 'break-all',
  },
};

export const addErrorMetadataToMessage = (
  customMessage: string | null,
  defaultMessage: string,
  err: ApolloError,
): React.ReactNode => {
  const requestInfo = getRequestInfoFromError(err);
  if (requestInfo !== null) {
    return (
      <div style={styles.errorContainer}>
        {customMessage && (
          <>
            <b>{customMessage}</b>
            <hr style={styles.divider} />
          </>
        )}
        <div>{i18n.t('errors.toast.contactHR')}</div>
        <br />
        <div>{defaultMessage}</div>
        <br />
        <div>Operation Name: {requestInfo.operationName}</div>
        <br />
        <div>Code: {requestInfo.code}</div>
        <br />
        <div>Request Id: {requestInfo.requestId}</div>
        <br />
        <div>Timestamp: {requestInfo.timestamp}</div>
      </div>
    );
  }

  return <span>{customMessage ?? defaultMessage}</span>;
};
