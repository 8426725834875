import { ColumnWithLooseAccessor } from 'react-table';
import {
  ExtraFieldsTable_ExtraFieldValueFragment,
  ExtraFieldsTable_WithExtraFieldsFragment,
  FieldType,
} from '../../__generated__/graphql';

// that file acts like a documentation for all supported context params for extra fields
export const extraFieldsContextParams = {
  absenceRequestForm: {
    absenceCategoryName: 'absenceCategoryName',
    employeeAbsenceCategory: 'employeeAbsenceCategory',
  },
} as const;

export type ExtraFieldContextParam = {
  name: string;
  type: FieldType;
  value: string;
};

/* eslint-disable no-unused-vars */
export enum ExtraFieldsSupportedObject {
  Expense = 'flair__Expense__c',
  Absence = 'flair__Absence__c',
}

export enum ExtraFieldsTableId {
  AbsencesMy = 'flair__Absence__c.MyAbsences',
  AbsencesManager = 'flair__Absence__c.ManagerAbsences',
  ExpensesMy = 'flair__Expense__c.MyExpenses',
  ExpensesManager = 'flair__Expense__c.ManagerExpenses',
}

export enum ExtraFieldsFormId {
  AbsencesRequestAbsence = 'flair__Absence__c.RequestAbsence',
  AbsencesManagerDetails = 'flair__Absence__c.ManagerDetails',
  ExpensesRequestExpense = 'flair__Expense__c.RequestExpense',
  ExpensesDetails = 'flair__Expense__c.Details',
}

export const ExtraFieldsTableCustomColumns = {
  absences: {
    name: '$custom.name',
    absenceCategory: '$custom.absenceCategory',
    approver: '$custom.approver',
    comments: '$custom.comments',
    employee: '$custom.employee',
    loom: '$custom.loom',
  },
  expenses: {
    comments: '$custom.comments',
  },
} as const;

export type ExtraTableRenderSettings<T extends RecordWithExtraFields> = {
  columns: Record<string, ColumnRenderSettings<T>>;
  beforePersistentColumns?: Record<string, ColumnRenderSettings<T>>;
  afterPersistentColumns?: Record<string, ColumnRenderSettings<T>>;
  responsibleMobileColumns?: Record<string, ColumnRenderSettings<T>>;
};

export type ColumnRenderSettings<T extends RecordWithExtraFields> = Omit<
  ColumnWithLooseAccessor<ExtraTableItemType<T>>,
  'accessor'
>;

export type RecordWithExtraFields = { Id: string } & Record<string, any> &
  ExtraFieldsTable_WithExtraFieldsFragment;

export type ExtraTableItemType<TData extends RecordWithExtraFields> = {
  rawExtraFields: Record<
    string,
    ExtraFieldsTable_ExtraFieldValueFragment | null
  >;
} & TData;
