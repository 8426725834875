import React, { useCallback, useEffect } from 'react';
import {
  Cell,
  CellPropGetter,
  Column,
  ColumnInstance,
  HeaderPropGetter,
  Row,
  RowPropGetter,
  SortingRule,
  TableOptions,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  hasPagination,
  TableContentCommon,
  TableContentCommonProps,
} from './TableContentCommon';

export type Props<T extends object> = {
  data: T[];
  columns: Column<T>[];
  pageSize?: number;
  filter?: string;
  initialSortBy?: Array<SortingRule<T>>;
  getRowProps?: (row: Row<T>) => RowPropGetter<T>;
  getColumnProps?: (column: ColumnInstance<T>) => CellPropGetter<T>;
  getHeaderProps?: (column: ColumnInstance<T>) => HeaderPropGetter<T>;
  getCellProps?: (cell: Cell<T>) => CellPropGetter<T>;
  onRowClick?: (row: T) => void;
  onRowSelection?: (row: T[]) => void;
  resetSelectedRows?: boolean;
  expandAllRows?: boolean;
  selectAllRows?: boolean;
  tableOptions?: Partial<TableOptions<T>>;
  onSortChange?: (val: SortingRule<string>[]) => void;
  autoResetSortBy?: boolean;
};

const defaultPropGetter = () => ({});

export const TableContent = <T extends object>({
  data,
  columns,
  pageSize,
  filter,
  initialSortBy = [],
  autoResetSortBy = false,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  onRowClick = defaultPropGetter,
  onRowSelection = defaultPropGetter,
  resetSelectedRows,
  expandAllRows,
  selectAllRows,
  onSortChange,
  tableOptions = {},
}: Props<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    headers,
    prepareRow,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    rows,
    page,
    state: { pageIndex, selectedRowIds, sortBy },
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data,
      autoResetSortBy,
      sortTypes: React.useMemo(
        () => ({
          caseInsensitive: (a, b, id) => {
            const valueA = a.values[id].toLowerCase();
            const valueB = b.values[id].toLowerCase();
            return valueB > valueA ? -1 : valueB < valueA ? 1 : 0;
          },
        }),
        [],
      ),
      autoResetSelectedRows: false,
      initialState: {
        pageSize: hasPagination(pageSize) ? pageSize : undefined,
        sortBy: initialSortBy,
      },
      ...(tableOptions ?? {}),
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    expandAllRows && toggleAllRowsExpanded();
  }, [expandAllRows, toggleAllRowsExpanded, data, columns]);

  useEffect(() => {
    if (sortBy.length && onSortChange) {
      onSortChange(sortBy);
    }
  }, [onSortChange, sortBy]);

  useEffect(() => {
    onRowSelection(selectedFlatRows.map((row) => row.original));
    // for dependency selectedFlatRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, onRowSelection]);

  useEffect(() => {
    if (hasPagination(pageSize)) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  useEffect(() => {
    setGlobalFilter(filter);
  }, [setGlobalFilter, filter, data]);

  const handleSelectAllRows = useCallback(() => {
    toggleAllRowsSelected(true);
  }, [toggleAllRowsSelected]);

  const handleResetSelectedRows = useCallback(() => {
    toggleAllRowsSelected(false);
  }, [toggleAllRowsSelected]);

  const rowsToRender = hasPagination(pageSize) ? page : rows;

  useEffect(() => {
    if (resetSelectedRows) handleResetSelectedRows();
  }, [resetSelectedRows, handleResetSelectedRows]);

  useEffect(() => {
    if (selectAllRows) handleSelectAllRows();
  }, [selectAllRows, handleSelectAllRows]);

  const showPagination =
    pageSize !== undefined ? data.length > pageSize : false;

  const tableCommonProps: TableContentCommonProps<T> = {
    pagination: {
      showPagination,
      pageCount,
      pageIndex,
      pageSize,
      gotoPage,
      previousPage,
      nextPage,
    },
    getTableProps,
    headers,
    getTableBodyProps,
    prepareRow,
    getRowProps,
    getColumnProps,
    getCellProps,
    getHeaderProps,
    onRowClick,
    rowsToRender,
  };

  return <TableContentCommon {...tableCommonProps} />;
};
