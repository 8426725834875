import React from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { StepIndicator } from './components/StepIndicator';
import { FormatFieldValue } from '../components/FormatFieldValue';
import { StepOneData } from './StepOne';
import { StepTwoData } from './StepTwo';
import { RecordField } from './types';
import { RequestFormFragment } from '../../../../__generated__/graphql';
import { Attachments } from './components/Attachments';

type StepThreeProps = {
  onSubmit: () => void;
  onBack: () => void;
  formData: {
    stepOne?: StepOneData;
    stepTwo?: StepTwoData;
  };
  isSubmitting: boolean;
  recordFields: Array<RecordField>;
  selectedRequestForm: RequestFormFragment | null;
  selectedEmployee?: {
    Id: string;
    Name: string;
  } | null;
};

export const StepThree: React.FC<StepThreeProps> = ({
  onSubmit,
  onBack,
  formData,
  isSubmitting,
  recordFields,
  selectedRequestForm,
  selectedEmployee,
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');

  const handleSubmit = () => {
    if (!formData.stepOne?.requestForm || !formData.stepTwo) {
      return;
    }
    onSubmit();
  };

  return (
    <Stack gap={3}>
      <div>
        {!selectedRequestForm?.flair__Require_Approval__c && (
          <div className="alert alert-warning">{t('autoApproval.message')}</div>
        )}
        <h5 className="mb-2">{selectedRequestForm?.Name}</h5>
        {selectedRequestForm?.flair__Information__c && (
          <div className="text-muted">
            {selectedRequestForm.flair__Information__c}
          </div>
        )}
      </div>
      {selectedEmployee && (
        <div className="mb-2">
          <div className="fw-bold mb-1">{t('employee.label')}</div>
          <div>{selectedEmployee.Name}</div>
        </div>
      )}
      {formData.stepTwo?.reason && (
        <div className="mb-2">
          <div className="fw-bold mb-1">{t('reason.label')}</div>
          <div>{formData.stepTwo?.reason}</div>
        </div>
      )}

      {formData.stepTwo?.files && formData.stepTwo.files.length > 0 && (
        <div className="mb-2">
          <div className="fw-bold mb-1">{t('attachments.label')}</div>
          <Attachments files={formData.stepTwo.files} readonly />
        </div>
      )}
      <div className="">
        <div className="fw-bold mb-1">{t('changes')}</div>
        <div
          className="fields-container"
          style={{
            maxHeight: '22rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '0 0.25rem',
          }}>
          {Object.entries(formData.stepTwo?.fields || {}).map(
            ([fieldName, newValue]) => {
              const field = recordFields.find(
                (f) => f.fieldInfo.name === fieldName,
              );
              if (!field) {
                return null;
              }
              return (
                <Stack key={fieldName} className="bg-light rounded p-4 mb-4">
                  <div className="fw-bold mb-1">{field.fieldInfo.label}</div>
                  <div className="text-break">
                    <FormatFieldValue
                      value={newValue}
                      type={field.fieldInfo.type}
                    />
                  </div>
                  <div className="text-muted text-decoration-line-through text-break">
                    <FormatFieldValue
                      value={field.value}
                      type={field.fieldInfo.type}
                    />
                  </div>
                </Stack>
              );
            },
          )}
        </div>
      </div>

      <Row className="align-items-center mt-2">
        <Col>
          <Button
            variant="outline-primary"
            label={t('buttons.back')}
            onClick={onBack}
            disabled={isSubmitting}
          />
        </Col>
        <Col className="text-center">
          <StepIndicator totalSteps={3} currentStep={3} />
        </Col>
        <Col className="text-end">
          <Button
            variant="primary"
            label={t('buttons.confirm')}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Col>
      </Row>
    </Stack>
  );
};
