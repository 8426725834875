import React from 'react';
import { useWithExtraFieldsQuery } from '../../../__generated__/graphql';
import {
  ExtraFieldsValueToRender,
  ExtraFieldsViewAreaContent,
  Loading,
} from './ExtraFieldsViewAreaContent';
import { useObjectExtraFields } from '../useObjectExtraFields';
import { ExtraFieldsFormId, ExtraFieldsSupportedObject } from '../types';
import { ExtraFieldsError } from '../ExtraFieldsError';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  recordId: string;
  formId: ExtraFieldsFormId;
  columnsCount?: number;
  renderItems?: (items: ExtraFieldsValueToRender[]) => React.ReactNode;
};

export const ExtraFieldsViewArea: React.FC<Props> = ({
  objectApiName,
  recordId,
  columnsCount = 1,
  renderItems,
}) => {
  const { objectExtraFields, loading, error } =
    useObjectExtraFields(objectApiName);
  const skipValueLoading =
    objectExtraFields !== undefined && objectExtraFields.form.length === 0;
  const {
    data,
    loading: valuesLoading,
    error: valuesError,
  } = useWithExtraFieldsQuery({
    variables: { recordId, objectApiName },
    skip: skipValueLoading,
  });

  if (loading || valuesLoading) return <Loading columnsCount={columnsCount} />;
  if (error || valuesError)
    return (
      <ExtraFieldsError
        error={error?.message ?? valuesError?.message ?? 'Unknown error'}
      />
    );

  if (!objectExtraFields) return null;
  if (!data) return null;

  const { extraFields, recordType } = data.extraFields.withExtraFields;

  return (
    <ExtraFieldsViewAreaContent
      objectExtraFields={objectExtraFields}
      values={extraFields}
      recordType={recordType}
      columnsCount={columnsCount}
      renderItems={renderItems}
    />
  );
};
