import { t } from 'i18next';

import { NotificationItemData, NotificationQueryItem } from '../types';
import { mapMentions, replaceTagMentionsWithSpan } from '../../Comment/logic';
import { routes } from '../../../routes';
import { createCommentDeepLinkRoute } from '../../../pages/DeepLinks';

const i18prefix = 'notifications';

export const mapCommentNotificationRenderInfo = (
  currentUserId: string,
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };

  if (src.renderInfo.__typename === 'CommentNotificationRenderInfo') {
    const { comment, employee } = src.renderInfo;

    // Check required fields. If not present, the notification won't be rendered.
    if (!comment || !employee) {
      return null;
    }
    data.description = t(`${i18prefix}.commentedIn`);
    const rawComment = comment.flair__Comment__c || '';
    const mentions = comment.mentions;
    data.comment = replaceTagMentionsWithSpan(
      comment.Id,
      rawComment,
      mapMentions(mentions),
    );
    data.employeeName = employee?.Name;
    const employeeFirstName = employee?.flair__First_Name__c;
    data.employeeFirstName =
      employeeFirstName === null ? undefined : employeeFirstName;
    const employeeLastName = employee?.flair__Last_Name__c;
    data.employeeLastName =
      employeeLastName === null ? undefined : employeeLastName;
    const avatarUrl = employee?.avatar?.url;
    data.avatarUrl = avatarUrl === null ? undefined : avatarUrl;
    const commentedOnObject = comment.flair__Related_Object_Name__c;
    data.url =
      createCommentDeepLinkRoute(currentUserId, comment) ??
      getCommentFallbackUrl(comment.Id);
    data.urlLabel = getObjectLabel(commentedOnObject);
  }

  return data;
};

const getCommentFallbackUrl = (commentId: string) => {
  return routes.commentDeepLink.route.create({ commentId });
};

const getObjectLabel = (objectName: string): string => {
  return (
    objectNameToLabel.get(objectName) ?? getUserFriendlySFObjectName(objectName)
  );
};

// From packages/server/src/apps/internal/services/commentService.ts
const objectNameToLabel = new Map([
  ['flair__Absence__c', t(`${i18prefix}.urlLabels.absence`)],
  ['flair__Celebration__c', t(`${i18prefix}.urlLabels.cheers`)],

  ['flair__Workflow_Item__c', t(`${i18prefix}.urlLabels.workflowItem`)],
  ['flair__Expense__c', t(`${i18prefix}.urlLabels.expense`)],
  [
    'flair__Employee_Certificate__c',
    t(`${i18prefix}.urlLabels.employeeCertificate`),
  ],
  ['flair__Employee_Document__c', t(`${i18prefix}.urlLabels.employeeDocument`)],
  ['flair__Timesheet_Day__c', t(`${i18prefix}.urlLabels.timeSheetDay`)],
  [
    'flair__Company_Announcement__c',
    t(`${i18prefix}.urlLabels.companyAnnouncement`),
  ],
  ['flair__Candidate__c', t(`${i18prefix}.urlLabels.candidate`)],
  [
    'flair__Candidate_Evaluation__c',
    t(`${i18prefix}.urlLabels.candidateEvaluation`),
  ],
  ['flair__Inventory_Item__c', t(`${i18prefix}.urlLabels.inventoryItem`)],
  ['flair__Ticket__c', t(`${i18prefix}.urlLabels.ticket`)],
  [
    'flair__Employee_Data_Change_Request__c',
    t(`${i18prefix}.urlLabels.changeRequest`),
  ],
]);

// fallback method, usually we should prove translations in  objectNameToLabel
export const getUserFriendlySFObjectName = (sfObjectName: string): string => {
  // Written by ChatGPT
  // Step 1: Remove namespace prefix and suffix
  const nameWithoutNamespaceAndSuffix = sfObjectName
    .replace(/^[a-zA-Z0-9]+__/, '')
    .replace(/__c$/, '');
  // Step 2: Replace underscores with spaces
  const nameWithSpaces = nameWithoutNamespaceAndSuffix.replace(/_/g, ' ');
  // Step 3: Capitalize the first letter of each word
  const userFriendlyName = nameWithSpaces.replace(/\b\w/g, (char) =>
    char.toUpperCase(),
  );
  return userFriendlyName;
};
