import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  AbsenceType,
  useDashboardCompensatoryTimeQuery,
} from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import classNames from 'classnames';
import './CompensatoryTimeWidget.css';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import { useTimeBalanceForToday } from '../../../components/TimeBalanceForDay/useTimeBalanceForDay';
import { useIsTimeBalanceEnabled } from '../../../hooks/timeTrackingSettings/useIsTimeBalanceEnabled';
import AbsenceAmount from '../../Absences/components/AbsenceAmount';

const i18Path = 'dashboard.compensatoryTime';

const CompensatoryTimeWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardCompensatoryTimeQuery();
  const isTimeBalanceEnabled = useIsTimeBalanceEnabled();
  const timeBalance = useTimeBalanceForToday();

  if (loading) {
    return <LoadingWidget height={74} />;
  }

  if (!data) {
    return null;
  }

  const compensatoryCategories = data.me.absenceCategories
    .filter(
      (absenceCategory) => absenceCategory.category.flair__Compensatory_Time__c,
    )
    .map((absenceCategory) => ({
      id: absenceCategory.Id,
      name: absenceCategory.category.Name,
      amount: absenceCategory.allowancesSummary.availableAmount,
      type: absenceCategory.category.flair__Type__c,
    }));

  const isTimeBalanceVisible = isTimeBalanceEnabled && timeBalance !== null;

  if (!compensatoryCategories.length && !isTimeBalanceVisible) {
    return null;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <FlairCard contentClassName="py-4 d-flex flex-column gap-3">
      {compensatoryCategories.map((compensatoryCategory) => (
        <div
          key={compensatoryCategory.id}
          className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <FlairLabel className="h2-mini" text={compensatoryCategory.name} />
            <FlairLabel type="secondary" className="fs-4" text={t('title')} />
          </div>
          <BalanceIndicator
            amount={compensatoryCategory.amount}
            type={compensatoryCategory.type}
          />
        </div>
      ))}
      {isTimeBalanceVisible && (
        <div className="d-flex justify-content-between align-items-center">
          <FlairLabel className="h2-mini" text={t('timeBalance')} />
          <BalanceIndicator
            amount={timeBalance / 60}
            type={AbsenceType.Hourly}
          />
        </div>
      )}
    </FlairCard>
  );
};

const BalanceIndicator: React.FC<{ amount: number; type: AbsenceType }> = ({
  amount,
  type,
}) => {
  const isNegative = amount < 0;
  const sign = isNegative ? '' : '+';
  const variant = isNegative ? 'danger' : 'success';
  return (
    <div
      className={classNames(
        variant,
        'compensatory-time-balance-container',
        'h3',
        'm-0',
      )}>
      {sign}
      <AbsenceAmount amount={amount} type={type} variant="normal" />
    </div>
  );
};

export default CompensatoryTimeWidget;
