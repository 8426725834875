import React, { useCallback, useMemo, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import {
  AbsenceApprovalStatus,
  useDeleteUpcomingAbsenceMutation,
  useEmployeeAbsencesQuery,
} from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import Loading from './Loading';
import MyAbsenceFilters from './MyAbsenceFilters';
import useAbsenceFilters from './MyAbsenceFilters/useAbsenceFilters';
import RequestAbsenceButton from '../../../components/RequestAbsenceButton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  TYPE_FILTER,
  STATUS_FILTER,
  MyAbsenceFiltersType,
  Absence,
} from './MyAbsenceFilters/types';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { useEmployeeAbsencesRefetchOption } from '../useMyAbsencesRefetchOption';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../context/Toast';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { mapAbsences } from './mappings';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { useYearFromQueryString } from '../../../../../hooks/useYearFromQueryString';
import { routes } from '../../../routes';
import YearNav from '../../../components/YearNav';
import Hint from '../../../../../components/hint';
import { currentYearPeriod } from '../../../../../utils/date';
import { startOfDay } from 'date-fns';
import { LoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useUserInfo } from '../../../context/UserInfo';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTable,
} from '../../../components/ExtraFields';
import {
  ExtraFieldsTableId,
  ExtraTableRenderSettings,
} from '../../../components/ExtraFields/types';
import { useAbsencesTableRenderSettings } from '../useAbsencesTableRenderSettings';

const i18Path = 'absences';
const YEAR_PARAM_NAME = 'year';

const buildPath = (year: number, queryParams: any) =>
  routes.myAbsences.route
    .withQueryParams({
      ...queryParams,
      [YEAR_PARAM_NAME]: String(year),
    })
    .create({});

type Props = {
  absences: Absence[];
  year: number;
  goToNextYear: () => void;
  goToPrevYear: () => void;
  loading: boolean;
};

const canDeleteAbsence = (absence: Absence) =>
  (absence.categoryRestrictAbsenceDeletion &&
    absence.approvalStatus !== AbsenceApprovalStatus.Approved) ||
  !absence.categoryRestrictAbsenceDeletion;

const Spinner: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border spinner-border-sm" role="status"></div>
  </div>
);

const EmptyAbsenceRequestsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateCardBody
      title={t('absences.myAbsences.table.emptyStateMessage')}
    />
  );
};

const getQueryFiltersData = (query: URLSearchParams): MyAbsenceFiltersType => {
  return {
    status: query.get(STATUS_FILTER) || '',
    type: query.get(TYPE_FILTER) || '',
  };
};

const Content: React.FC<Props> = ({
  absences,
  year,
  goToNextYear,
  goToPrevYear,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const { id: meId } = useUserInfo();
  const [deletingAbsence, setDeletingAbsence] = useState<string>('');
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filterDataFromUrl = getQueryFiltersData(query);
  const filteredAbsences = useAbsenceFilters(filterDataFromUrl, absences);
  const { addSuccess } = useToasts();
  const period = year ? currentYearPeriod(year) : undefined;
  const employeeAbsencesRefetchQuery = useEmployeeAbsencesRefetchOption(
    meId,
    period,
  );
  const [deleteUpcomingAbsence, { loading }] = useDeleteUpcomingAbsenceMutation(
    {
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: employeeAbsencesRefetchQuery,
    },
  );
  const errorHandler = useMutationErrorHandler(() =>
    t('cards.upcomingAbsences.delete.failure'),
  );

  const handleDelete = useCallback(
    (id: string) => {
      setDeletingAbsence(id);
      deleteUpcomingAbsence({
        variables: { id },
      })
        .then(() => addSuccess(t('cards.upcomingAbsences.delete.success')))
        .catch(errorHandler)
        .finally(() => setDeletingAbsence(''));
    },
    [setDeletingAbsence, deleteUpcomingAbsence, addSuccess, t, errorHandler],
  );

  const isDeleting = useCallback(
    (id: string): boolean => loading && deletingAbsence === id,
    [loading, deletingAbsence],
  );

  const { columns } = useAbsencesTableRenderSettings<Absence>({
    onLoomVideoClick: setSelectedLoomVideo,
  });

  const renderSettings: ExtraTableRenderSettings<Absence> = useMemo(
    () => ({
      columns,
      afterPersistentColumns: {
        ActionColumn: {
          Cell: ({ row }) => {
            if (!canDeleteAbsence(row.original as any)) {
              return null;
            }
            return (
              <div className="ms-auto mt-2">
                {isDeleting(row.original.id) ? (
                  <Spinner />
                ) : (
                  <DropdownActions id={`${row.original.id}-actions`}>
                    <div className="d-flex align-items-center">
                      <DropdownActionItem
                        onClick={() => {
                          handleDelete(row.original.id);
                        }}
                        title={row.original.deleteDisabled?.reason}
                        disabled={
                          loading || row.original.deleteDisabled !== undefined
                        }>
                        {t('cards.upcomingAbsences.actions.delete')}
                      </DropdownActionItem>
                      {row.original.deleteDisabled?.reason && (
                        <div className="text-muted me-3">
                          <Hint
                            id={row.original.id}
                            text={row.original.deleteDisabled?.reason}
                          />
                        </div>
                      )}
                    </div>
                  </DropdownActions>
                )}
              </div>
            );
          },
        },
      },
    }),
    [columns, t, handleDelete, isDeleting, loading],
  );

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col className="small">
            <MyAbsenceFilters data={absences} filterData={filterDataFromUrl} />
          </Col>
          <Col className="col-auto text-end">
            <YearNav
              year={year}
              goToNextYear={goToNextYear}
              goToPrevYear={goToPrevYear}
            />
          </Col>
        </Row>
      </Card.Header>

      {absences.length > 0 ? (
        <ExtraFieldsTable
          records={filteredAbsences}
          objectApiName={ExtraFieldsSupportedObject.Absence}
          renderSettings={renderSettings}
          tableId={ExtraFieldsTableId.AbsencesMy}
          pageSize={50}
          onRowClick={() => {}}
        />
      ) : (
        <EmptyAbsenceRequestsCard />
      )}
      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}
    </Card>
  );
};

const MyAbsencesPage: React.FC<{ actions?: React.ReactNode }> = ({
  actions,
  children,
}) => {
  const t = useNamespacedTranslation('navigation.menuItems.absences');
  const title = t('submenuItems.myAbsences');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <>
      <PageHeader title={title} actions={actions} />
      {children}
    </>
  );
};

const MyAbsences: React.FC = () => {
  const year = useYearFromQueryString(YEAR_PARAM_NAME);
  const history = useHistory();
  const queryParams = useQueryParams();

  const goToPrevYear = useCallback(() => {
    history.push(buildPath(year - 1, queryParams));
  }, [year, history, queryParams]);

  const goToNextYear = useCallback(() => {
    history.push(buildPath(year + 1, queryParams));
  }, [year, history, queryParams]);

  const { data, loading, error } = useEmployeeAbsencesQuery({
    variables: currentYearPeriod(year),
  });

  const today = startOfDay(new Date());
  const absences = useMemo(
    () => (data ? mapAbsences(today, data.me.absences) : undefined),
    [today, data],
  );

  if (error) {
    return (
      <MyAbsencesPage>
        <ServerError />
      </MyAbsencesPage>
    );
  }

  if (loading || !absences || !data) {
    return (
      <MyAbsencesPage>
        <Loading />
      </MyAbsencesPage>
    );
  }

  const categories = data.me.absenceCategories;

  return (
    <MyAbsencesPage
      actions={
        <RequestAbsenceButton categories={categories} categoryId={null} />
      }>
      <Content
        absences={absences}
        year={year}
        goToNextYear={goToNextYear}
        goToPrevYear={goToPrevYear}
        loading={loading}
      />
    </MyAbsencesPage>
  );
};

export default MyAbsences;
