import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ChangeRequestsTable from './ChangeRequestsTable';
import {
  useTableContentFiltration,
  useTableContentPageSize,
} from '../../../components/useTableContent';
import ServerError from '../../../../../../components/ServerError';
import Loading from './Loading';
import {
  EmployeeDataChangeRequestStatus,
  ManagerChangeRequestsByStatusQuery,
  useManagerChangeRequestsByStatusQuery,
} from '../../../../__generated__/graphql';
import { PageSize } from '../../../components/CardHeaderFilter';
import { CardHeaderFilter } from '../../../components/CardHeaderFilter';
import { EmptyChangeRequests } from './EmptyChangeRequests';

type CardContentProps = {
  filter: string;
  pageSize: number;
  data: ManagerChangeRequestsByStatusQuery;
};

const CardContent: React.FC<CardContentProps> = ({
  filter,
  pageSize,
  data,
}) => {
  const [showAllRequests, setShowAllRequests] = React.useState(true);
  const onToggleShowAllRequests = () => {
    setShowAllRequests(!showAllRequests);
  };

  const filteredRequests = showAllRequests
    ? data.manager.changeRequests
    : data.manager.changeRequests.filter(
        (request) =>
          request.flair__Status__c === EmployeeDataChangeRequestStatus.Pending,
      );

  return (
    <ChangeRequestsTable
      data={filteredRequests}
      filter={filter}
      pageSize={pageSize}
      showAllRequests={showAllRequests}
      onToggleShowAllRequests={onToggleShowAllRequests}
    />
  );
};

const INITIAL_PAGE_SIZE: PageSize = 50;

const ChangeRequestsList: React.FC = () => {
  const { t } = useTranslation();
  const [filter, onFilterChange] = useTableContentFiltration();
  const [pageSize, onPageSizeChange] =
    useTableContentPageSize(INITIAL_PAGE_SIZE);

  const { data, loading, error } = useManagerChangeRequestsByStatusQuery();

  if (loading) {
    return <Loading />;
  }

  if (error || data === undefined) {
    return <ServerError />;
  }

  if (data.manager.changeRequests.length === 0) {
    return <EmptyChangeRequests />;
  }

  return (
    <Card>
      <CardHeaderFilter
        onFilterChange={onFilterChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        placeholder={t('employees.all.table.filterPlaceholder')}
      />
      <CardContent filter={filter} pageSize={pageSize} data={data} />
    </Card>
  );
};

export default ChangeRequestsList;
