import React, { useMemo } from 'react';
import { ExpenseStatus } from '../../__generated__/graphql';
import { EmptyExpenses } from './EmptyExpenses';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTable,
  ExtraTableRenderSettings,
} from '../../components/ExtraFields';
import {
  Item,
  useExpenseTableRenderSettings,
} from './useExpenseTableRenderSettings';
import { useTranslation } from 'react-i18next';
import { DropdownActions } from '../../../../atomic/templates/DropdownActions';
import { DropdownActionItem } from '../../../../atomic/molecules/DropdownActionItem';
import { ExtraFieldsTableId } from '../../components/ExtraFields/types';
import { Expense } from './types';

type Props = {
  onItemClick: (item: Item) => void;
  expenses: Expense[];
  onDelete: (id: string) => void;
};

export const MyExpensesTable: React.FC<Props> = ({
  onItemClick,
  expenses,
  onDelete,
}) => {
  const { t } = useTranslation();

  const { columns } = useExpenseTableRenderSettings({
    onItemClick,
  });

  const renderSettings: ExtraTableRenderSettings<Expense> = useMemo(
    () => ({
      columns,
      afterPersistentColumns: {
        ActionColumn: {
          Cell: ({ row }) => {
            return (
              row.original.flair__Status__c === ExpenseStatus.Pending && (
                <>
                  <DropdownActions id="expense-actions">
                    <DropdownActionItem
                      onClick={() => {
                        onDelete(row.original.id);
                      }}>
                      {t('expenses.deleteModal.button')}
                    </DropdownActionItem>
                  </DropdownActions>
                </>
              )
            );
          },
        },
      },
    }),
    [columns, onDelete, t],
  );

  if (!expenses.length) {
    return <EmptyExpenses />;
  }

  return (
    <ExtraFieldsTable
      records={expenses}
      objectApiName={ExtraFieldsSupportedObject.Expense}
      renderSettings={renderSettings}
      tableId={ExtraFieldsTableId.ExpensesMy}
    />
  );
};
