import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { EmployeeDataChangeRequestStatus } from '../../../../__generated__/graphql';
import { DataChangeRequestRow } from './ChangeRequestsTable';

type ChangeRequestsTableHeaderProps = {
  data: DataChangeRequestRow[];
  showAllRequests: boolean;
  onToggleShowAllRequests: () => void;
  onNewRequest: () => void;
};

export const ChangeRequestsTableHeader: React.FC<
  ChangeRequestsTableHeaderProps
> = ({ data, showAllRequests, onToggleShowAllRequests, onNewRequest }) => {
  const t = useNamespacedTranslation('changeRequests');

  const numberOfPendingRequests = useMemo(
    () =>
      data.filter(
        (request) => request.status === EmployeeDataChangeRequestStatus.Pending,
      ).length,
    [data],
  );

  return (
    <div className="mx-4 my-3">
      <Row className="align-items-center">
        <Col>
          <span className="me-3">
            {t('numberOfPendingRequests', {
              count: numberOfPendingRequests,
            })}
          </span>

          <Button
            label={t(
              showAllRequests ? 'showPendingRequests' : 'showAllRequests',
            )}
            variant="link"
            className="p-0"
            onClick={onToggleShowAllRequests}
          />
        </Col>
        <Col xs="auto">
          <Button
            label={t('newRequestButton')}
            variant="primary"
            onClick={onNewRequest}
            icon={'add-circle-outline'}
          />
        </Col>
      </Row>
    </div>
  );
};
