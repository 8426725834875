import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/button';
import { EmptyStateCardBody } from '../../../../components/EmptyStateCard';
import { ChangeRequestForm } from '../ChangeRequestForm';

export const EmptyChangeRequests: React.FC = () => {
  const { t } = useTranslation();
  const [showNewRequestModal, setShowNewRequestModal] = React.useState(false);

  return (
    <>
      <Card>
        <EmptyStateCardBody
          title={t('changeRequests.emptyState')}
          action={
            <Button
              label={t('changeRequests.newRequestButton')}
              variant="primary"
              onClick={() => setShowNewRequestModal(true)}
              icon={'add-circle-outline'}
            />
          }
        />
      </Card>
      <ChangeRequestForm
        show={showNewRequestModal}
        recordType="flair__Employee__c"
        showAllRequests={true}
        onHide={() => setShowNewRequestModal(false)}
      />
    </>
  );
};
