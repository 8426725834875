import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ChangeRequestsList from './ChangeRequestsList';

export const MyTeamChangeRequests: React.FC = () => {
  return (
    <>
      <Row>
        <Col>
          <ChangeRequestsList />
        </Col>
      </Row>
    </>
  );
};
