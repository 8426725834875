import React from 'react';
import { Alert } from 'react-bootstrap';
import i18n from 'i18next';

const i18Prefix = 'extraFields.errorBoundary';

type Props = {
  error: string;
};

export const ExtraFieldsError: React.FC<Props> = ({ error }) => {
  return (
    <Alert variant="danger">
      <Alert.Heading>{i18n.t(`${i18Prefix}.header`)}</Alert.Heading>
      <p>
        {i18n.t(`${i18Prefix}.message`)}
        {error && (
          <span className="d-block mt-2 font-monospace fs-5">
            {error.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < error.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        )}
      </p>
    </Alert>
  );
};
