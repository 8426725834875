import React, { useState } from 'react';
import { Stack, Tab, Tabs } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { EmployeeWithAvatar } from '../../../../../components/Employee';
import FormattedDateTime from '../../../../../../../components/datetime/FormattedDateTime';
import { StatusCircle } from '../../../../../components/StatusCircle';
import { Theme } from '../../../../../../../theme';
import {
  ContentDocumentLinkDataFragment,
  EmployeeDataChangeRequestStatus,
  ManagerChangeRequestByIdQuery,
  ObjectFieldValueFragment,
} from '../../../../../__generated__/graphql';
import ConfirmButton from '../../../../../../../components/button/ConfirmButton';
import ModalSidebar, {
  ModalSidebarContent,
} from '../../../../../components/ModalSidebar';
import CommentListWrapper from '../../../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { RelatedObjectNames } from '../../../../../components/Comment/types';
import { FormatFieldValue } from '../../components/FormatFieldValue';
import { Loading } from './Loading';
import { useChangeRequestData, useCancelChangeRequest } from './hooks';
import { Attachments } from '../../ChangeRequestForm/components/Attachments';

type ChangeRequestPreviewProps = {
  show: boolean;
  onHide: () => void;
  requestId: string;
  recordId: string;
  recordType: string;
  showItemComments?: boolean;
};

export const ChangeRequestPreview: React.FC<ChangeRequestPreviewProps> = ({
  show,
  onHide,
  requestId,
  recordId,
  recordType,
  showItemComments = false,
}) => {
  const t = useNamespacedTranslation('changeRequests');
  const [selectedTabKey, setSelectedTabKey] = useState<string>(
    showItemComments ? 'comments' : 'information',
  );

  const { request, recordFields, isLoading } = useChangeRequestData(
    requestId,
    recordId,
    recordType,
    show,
  );

  const { handleCancel, isCanceling } = useCancelChangeRequest(onHide);

  if (isLoading || !request) {
    return (
      <ModalSidebar
        show={show}
        onClose={onHide}
        header={t('previewModal.title')}
        content={<Loading />}
      />
    );
  }

  const attachments =
    request.ContentDocumentLinks__r?.map(mapDocumentFile) ?? [];

  const changesNewValues = getChangeRequestNewValues(request);
  const changesOldValues = getChangeRequestOldValues(request, recordFields);

  const modalContent = (
    <Tabs
      className="mx-4"
      activeKey={selectedTabKey}
      onSelect={(k) => setSelectedTabKey(k || 'information')}>
      <Tab
        eventKey="information"
        title={t('previewModal.tabs.information')}
        className="m-1 mt-4">
        <ModalSidebarContent
          body={
            <Stack gap={4}>
              <div>
                <h5>{request.Name}</h5>
                <div className="text-muted">
                  {t('table.header.requestedAt')}
                  <FormattedDateTime
                    dateTime={request.flair__Requested_At__c || ''}
                  />
                </div>
              </div>

              <div>
                <div className="fw-bold mb-2">{t('table.header.employee')}</div>
                <EmployeeWithAvatar
                  employee={{
                    name: request.employee?.Name || '',
                    avatarUrl: request.employee?.avatar?.url || '',
                  }}
                />
              </div>

              {request.flair__Change_Request_Reason__c && (
                <div>
                  <div className="fw-bold mb-2">
                    {t('requestForm.reason.label')}
                  </div>
                  <div>{request.flair__Change_Request_Reason__c}</div>
                </div>
              )}

              <div>
                <div className="fw-bold mb-2">{t('table.header.status')}</div>
                <div className="d-flex align-items-center gap-2">
                  <StatusCircle
                    color={
                      Theme.changeRequest.status[request.flair__Status__c].color
                    }
                    size={5}
                  />
                  <span>{t(`table.status.${request.flair__Status__c}`)}</span>
                </div>
              </div>

              {attachments.length > 0 && (
                <div>
                  <div className="fw-bold mb-3">
                    {t('requestForm.attachments.label')}
                  </div>
                  <Attachments files={attachments} readonly />
                </div>
              )}

              <div>
                <div className="fw-bold mb-3">{t('previewModal.changes')}</div>
                <div
                  className="fields-container"
                  style={{
                    maxHeight: '15rem',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 0.25rem',
                  }}>
                  <Stack gap={3}>
                    {recordFields
                      .filter(
                        (field) => field.fieldInfo.name in changesNewValues,
                      )
                      .map((field) => {
                        const newValue = changesNewValues[field.fieldInfo.name];
                        const oldValue = changesOldValues[field.fieldInfo.name];

                        return (
                          <div
                            key={field.fieldInfo.name}
                            className="bg-light rounded p-3">
                            <div className="fw-bold mb-2">
                              {field.fieldInfo.label}
                            </div>
                            <div className="text-break">
                              <FormatFieldValue
                                value={newValue}
                                type={field.fieldInfo.type}
                              />
                            </div>
                            <div className="text-muted text-decoration-line-through text-break">
                              <FormatFieldValue
                                value={oldValue}
                                type={field.fieldInfo.type}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </Stack>
                </div>
              </div>
            </Stack>
          }
          footer={
            request.flair__Status__c ===
              EmployeeDataChangeRequestStatus.Pending && (
              <ConfirmButton
                label={t('previewModal.cancelRequest')}
                variant="outline-danger"
                className="w-100"
                confirmTitle={t('previewModal.cancelConfirm.title')}
                confirmText={t('previewModal.cancelConfirm.text')}
                onConfirm={() => handleCancel(request.Id)}
                disabled={isCanceling}
              />
            )
          }
        />
      </Tab>
      <Tab
        eventKey="comments"
        title={`${t('previewModal.tabs.comments')} (${
          request.commentsCount || 0
        })`}
        className="h-100 comments-tab">
        {selectedTabKey === 'comments' && (
          <CommentListWrapper
            recordId={requestId}
            relatedObjectName={RelatedObjectNames.EmployeeDataChangeRequest}
          />
        )}
      </Tab>
    </Tabs>
  );

  const handleClose = () => {
    setSelectedTabKey('information');
    onHide();
  };

  return (
    <ModalSidebar
      show={show}
      onClose={handleClose}
      header={
        <Stack>
          <div>{t('previewModal.title')}</div>
          <div className="mt-2 fs-5 text-muted">{request.Name}</div>
        </Stack>
      }
      content={modalContent}
    />
  );
};

type QueryChangeRequest =
  ManagerChangeRequestByIdQuery['manager']['changeRequests'][number];

const getChangeRequestNewValues = (request: QueryChangeRequest) => {
  return request.flair__Changes__c ? JSON.parse(request.flair__Changes__c) : {};
};

const getChangeRequestOldValues = (
  request: QueryChangeRequest,
  recordFields: ReadonlyArray<ObjectFieldValueFragment>,
) => {
  // In pending state, the old values are taken from the record(like employee) data
  if (request.flair__Status__c === EmployeeDataChangeRequestStatus.Pending) {
    const oldValues: Record<string, any> = {};
    recordFields?.forEach((field) => {
      oldValues[field.fieldInfo.name] = field.value;
    });
    return oldValues;
  } else {
    // In approved/rejected state, the old values are taken from flair__Old_Values__c field inside the request
    return request.flair__Old_Values__c
      ? JSON.parse(request.flair__Old_Values__c)
      : {};
  }
};

const mapDocumentFile = (link: ContentDocumentLinkDataFragment) => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadUrl: link.downloadUrl,
});
