import {
  AbsenceType,
  MyAbsencesQuery,
  EmployeeAvatar,
  Maybe,
  AbsenceApprovalStatus,
  MyAbsenceFilters_AbsenceRequestFragment,
  ExtraFieldsTable_WithExtraFieldsFragment,
} from '../../../../__generated__/graphql';
import { ArrayElement } from '../../../../../../utils/arrayElement';
import { LoomVideo } from '../../../../components/LoomVideo';

export type MyAbsenceFilter = {
  type: string[];
  status: string[];
};

export const TYPE_FILTER = 'type';

export const STATUS_FILTER = 'status';

export const MyAbsenceFiltersKeysArray = [TYPE_FILTER, STATUS_FILTER] as const;

export type MyAbsenceFiltersKeys = (typeof MyAbsenceFiltersKeysArray)[number];

export type MyAbsenceFiltersType = Record<MyAbsenceFiltersKeys, string>;

export type Categories = ArrayElement<
  MyAbsencesQuery['me']['absenceCategories']
>;

export const filterDefaultData: MyAbsenceFiltersType = {
  status: '',
  type: '',
};

export const AbsenceTypeMap: Record<string, string> = {
  DAILY: 'days',
  HOURLY: 'hours',
  UNKNOWN: '',
};

export type GeneratedApproverAvatar = Maybe<
  { readonly __typename?: 'EmployeeAvatar' | undefined } & Pick<
    EmployeeAvatar,
    'url' | 'Id' | 'Name'
  >
>;

export type GeneratedAbsence = MyAbsencesQuery['me']['absences'][number] &
  ExtraFieldsTable_WithExtraFieldsFragment;

// todo: remove GeneratedApprovalRequest type?
export type GeneratedApprovalRequest = MyAbsenceFilters_AbsenceRequestFragment;

export type GeneratedCategories = ArrayElement<
  MyAbsencesQuery['me']['absenceCategories']
>;

export type GeneratedApprover = GeneratedApprovalRequest['approver'];

export type ApproverAvatar = {
  id: string | undefined;
  name: string | undefined;
  url: Maybe<string> | undefined;
  // urlLarge: Maybe<string> | undefined;
};

export type Approver = {
  id: string;
  name: string;
  firstName: Maybe<string> | null;
  lastName: Maybe<string> | null;
  avatar: ApproverAvatar;
};

export type ApprovalRequest = {
  id: string;
  approvalStatus: string;
  approverId: string;
  approver: Approver;
  approverComment: Maybe<string>;
};

export type Absence = Omit<
  GeneratedAbsence,
  'approvalRequests' | 'loomVideo'
> & {
  id: string;
  approvalStatus: AbsenceApprovalStatus;
  categoryName: string;
  categoryIcon: string;
  amount: number;
  workingAmount: number;
  startDate: string;
  startTime: string;
  startDateHalfDay: boolean;
  endDate: string;
  endTime: string;
  endDateHalfDay: boolean;
  categoryRestrictAbsenceDeletion: boolean;
  type: AbsenceType;
  createdDate: string;
  approvalRequests: ApprovalRequest[];
  recordId: string;
  commentsCount: number;
  deleteDisabled?: {
    reason: string;
  };
  loomVideo?: Maybe<LoomVideo>;
} & ExtraFieldsTable_WithExtraFieldsFragment;
