import { TypePolicies } from '@apollo/client';
import { mergeObjectWithPagination } from '../../../utils/pagination';

const possibleTypes = {
  AnyObject: ['Colleague', 'Project'],
  Commentable: [
    'Absence',
    'EmployeeCertificate',
    'Expense',
    'Celebration',
    'WorkflowItem',
    'TimeSheetDay',
    'EmployeeDocument',
    'Announcement',
    'JobCandidate',
    'CandidateEvaluation',
    'InventoryItem',
    'Ticket',
  ],
  WithLoomVideo: ['TimeSheetDay'],
  WithExtraFields: ['Expense', 'Absence'],
  Objective: ['EmployeeObjective', 'TeamObjective', 'CompanyObjective'],
  Navigatable: ['EmployeeDocument', 'EmployeeDocumentFolder'],
  TeamOrCompanyObjective: ['TeamObjective', 'CompanyObjective'],
  TeamOrEmployeeObjective: ['TeamObjective', 'EmployeeObjective'],
  ObjectiveHistoryItemCommon: [
    'ObjectiveHistoryItem',
    'ObjectiveReferenceHistoryItem',
  ],
  InventoryItem: ['InventoryItem'],
  LastUpdates: ['Announcement', 'Celebration', 'Colleague'],
  TimeTrackingErrorBase: [
    'TimeTrackingError',
    'TimeLimitErrorMaxDailyLimitExceeded',
  ],
  TimeTrackingWarning: ['TimeLimitWarningMaxDailyLimit'],
};

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      celebrationsPagination: {
        keyArgs: ['filter'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      lastUpdates: {
        keyArgs: ['filter'],
        merge: (existing, incoming) => {
          const existingData = existing?.data ?? [];
          return {
            offset: incoming.offset,
            data: existingData.concat(incoming.data),
          };
        },
      },
      slack: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      inventoryItemsPagination: {
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      ticketPagination: {
        keyArgs: ['filter', 'sort'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      timeSheetDay: {
        // cache redirect (https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects)
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'TimeSheetDay',
            flair__Employee__c: args!.employeeId,
            flair__Day__c: args!.day,
          });
        },
      },
      timeSheet: {
        // cache redirect (https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects)
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'TimeSheet',
            Id: args!.id,
          });
        },
      },
      reports: {
        merge: (_existent, incoming) => {
          return incoming;
        },
      },
      extraFields: {
        merge: (_existent, incoming) => {
          return incoming;
        },
      },
    },
  },
  Manager: {
    keyFields: ['Id'],
    fields: {
      timeSheetsPagination: {
        keyArgs: ['filter', 'sort'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      tickets: {
        keyArgs: ['filter', 'sort'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
    },
  },
  ManagerEmployeeNote: {
    keyFields: ['Id'],
  },
  Me: {
    keyFields: ['Id'],
    fields: {
      notifications: {
        keyArgs: ['filter'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      inboxNotifications: {
        keyArgs: false,
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      taskNotifications: {
        keyArgs: false,
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      snoozedNotifications: {
        keyArgs: false,
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      archivedNotifications: {
        keyArgs: ['filter'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
      timeSheetsPagination: {
        keyArgs: ['filter', 'sort'],
        merge: (existing, incoming, { args }) => {
          return mergeObjectWithPagination(
            existing,
            incoming,
            args?.pagination,
          );
        },
      },
    },
  },
  AbsenceApprovalRequestToMe: {
    keyFields: ['Id'],
  },
  Absence: {
    keyFields: ['Id'],
  },
  AbsenceYearlySummary: {
    keyFields: ['employeeAbsenceCategoryId'],
    fields: {
      previousPeriod: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      currentPeriod: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      nextPeriod: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  EmployeeAbsenceCategory: {
    keyFields: ['Id'],
  },
  AbsenceAllowance: {
    keyFields: ['Id'],
  },
  AllowanceWithdrawalDay: {
    keyFields: ['Id'],
  },
  Announcement: {
    keyFields: ['Id'],
  },
  BreakLegislationRule: {
    keyFields: ['Id'],
  },
  Celebration: {
    keyFields: ['Id'],
  },
  Certificate: {
    keyFields: ['Id'],
  },
  Colleague: {
    keyFields: ['Id'],
  },
  JobCandidate: {
    keyFields: ['Id'],
  },
  CandidateEvaluation: {
    keyFields: ['Id'],
  },
  Comment: {
    keyFields: ['Id'],
  },
  Employee: {
    keyFields: ['Id'],
  },
  EmployeeAvatar: {
    keyFields: ['Id'],
  },
  EmployeeCertificate: {
    keyFields: ['Id'],
  },
  EmployeeDocument: {
    keyFields: ['Id'],
  },
  EmployeeDocumentFolder: {
    keyFields: ['Id'],
  },
  EmployeeData: {
    keyFields: ['employeeId'],
  },
  EmployeeDataSection: {
    keyFields: ['employeeId', 'label'],
  },
  EmployeeEvaluation: {
    keyFields: ['Id'],
  },
  EmployeeSkillAssociation: {
    keyFields: ['Id'],
  },
  EmployeeFeedback: {
    keyFields: ['Id'],
  },
  EmployeeUploadedDocument: {
    keyFields: ['Id'],
  },
  Expense: {
    keyFields: ['Id'],
  },
  DocumentCategory: {
    keyFields: ['Id'],
  },
  FieldInfo: {
    keyFields: ['objectType', 'recordTypeId', 'name', 'locale'],
  },
  MyTimeEntry: {
    keyFields: ['Id'],
  },
  MyTimeEntryBreak: {
    keyFields: ['Id'],
  },
  MyTimeSheet: {
    keyFields: ['Id'],
  },
  RecruitingDocument: {
    keyFields: ['Id'],
  },
  TimeEntryBreakChangeRequest: {
    keyFields: ['Id'],
  },
  TimeEntryChangeRequest: {
    keyFields: ['Id'],
  },
  TimeEntry: {
    keyFields: ['Id'],
  },
  TimeEntryBreak: {
    keyFields: ['Id'],
  },
  TimeSheetDay: {
    keyFields: ['flair__Employee__c', 'flair__Day__c'],
  },
  MyWorkload: {
    keyFields: ['Id'],
  },
  Workflow: {
    keyFields: ['Id'],
  },
  TimeSheet: {
    keyFields: ['Id'],
  },
  TimesheetTrackedTime: {
    keyFields: ['timesheetId'],
  },
  TimesheetDayTrackedTime: {
    // merge is disabled because TimesheetDayTrackedTime.dayChanges is optional field
    // and if we remove dayChanges, then it will be not merged correctly
    merge: false,
  },
  WorkflowItem: {
    keyFields: ['Id'],
  },
  WorkflowStep: {
    keyFields: ['Id'],
  },
  EmployeeObjective: {
    keyFields: ['Id'],
  },
  EmployeeWorkload: {
    keyFields: ['Id'],
  },
  TeamObjective: {
    keyFields: ['Id'],
  },
  CompanyObjective: {
    keyFields: ['Id'],
  },
  CostCenter: {
    keyFields: ['Id'],
  },
  Skill: {
    keyFields: ['Id'],
  },
  Department: {
    keyFields: ['Id'],
  },
  Tag: {
    keyFields: ['Id'],
  },
  TimeFramework: {
    keyFields: ['Id'],
  },
  ObjectExtraFields: {
    keyFields: ['objectApiName'],
  },
  ObjectiveTimePeriod: {
    keyFields: ['Id'],
  },
  ObjectiveKeyResult: {
    keyFields: ['Id'],
  },
  ObjectiveKeyResultUpdate: {
    keyFields: ['Id'],
  },
  Project: {
    keyFields: ['Id'],
  },
  ProjectTimeEntry: {
    keyFields: ['Id'],
  },
  TimeSheetDayProjectTimeEntry: {
    keyFields: ['Id'],
  },
  AbsenceCategory: {
    keyFields: ['Id'],
  },
  AbsenceCategoriesGroup: {
    keyFields: ['Id'],
  },
  EnpsSurvey: {
    keyFields: ['Id'],
  },
  EnpsSurveyCycle: {
    keyFields: ['Id'],
  },
  EnpsSurveyAnswer: {
    keyFields: ['Id'],
  },
  CheckinsSurvey: {
    keyFields: ['Id'],
  },
  CheckinsSurveyCycle: {
    keyFields: ['Id'],
  },
  CheckinsSurveyAnswer: {
    keyFields: ['Id'],
  },
  EngagementSurvey: {
    keyFields: ['Id'],
  },
  EngagementSurveySection: {
    keyFields: ['Id'],
  },
  EngagementSurveyQuestion: {
    keyFields: ['Id'],
  },
  EngagementSurveyQuestionOption: {
    keyFields: ['Id'],
  },
  EngagementSurveyEmployeeResponse: {
    keyFields: ['Id'],
  },
  EngagementSurveyQuestionAnswer: {
    keyFields: ['Id'],
  },
  EngagementSurveyAnswerOption: {
    keyFields: ['Id'],
  },
  Notifications: {
    keyFields: ['Id'],
  },
  InventoryItem: {
    keyFields: ['Id'],
  },
  Ticket: {
    keyFields: ['Id'],
  },
  EmployeeSlackConnection: {
    keyFields: ['employeeId'],
  },
  RequestForm: {
    keyFields: ['Id'],
  },
  EmployeeDataChangeRequest: {
    keyFields: ['Id'],
  },
  WithExtraFields: {
    keyFields: ['Id'],
  },
  // Disable normalization for Mutation namespaces
  AbsenceMutations: {
    keyFields: false,
  },
  DocumentMutations: {
    keyFields: false,
  },
  EmployeeDataMutations: {
    keyFields: false,
  },
  ExpenseMutations: {
    keyFields: false,
  },
  TimeTrackingMutations: {
    keyFields: false,
  },
  WorkflowMutations: {
    keyFields: false,
  },
  ObjectivesMutations: {
    keyFields: false,
  },
  PerformanceReviewMutations: {
    keyFields: false,
  },
  ProjectMutations: {
    keyFields: false,
  },
  EmployeeSkillMutations: {
    keyFields: false,
  },
  EvaluationMutations: {
    keyFields: false,
  },
  SkillMutations: {
    keyFields: false,
  },
  CommentMutations: {
    keyFields: false,
  },
  NotificationMutations: {
    keyFields: false,
  },
  EmployeeDataChangeRequestMutations: {
    keyFields: false,
  },
  ExtraFields: {
    keyFields: false,
    fields: {
      withExtraFields: {
        // cache redirect (https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects)
        read(_, { args, toReference }) {
          const objectApiName = args!.objectApiName;
          const recordId = args!.recordId;
          switch (objectApiName) {
            case 'flair__Expense__c':
              return toReference({
                __typename: 'Expense',
                Id: recordId,
              });
            case 'flair__Absence__c':
              return toReference({
                __typename: 'Absence',
                Id: recordId,
              });
            default:
              throw new Error(
                'Please add cache redirect for new objectApiName: ' +
                  objectApiName,
              );
          }
        },
      },
    },
  },
  ExtraFieldValue: {
    keyFields: ['recordId', 'fieldApiName'],
  },
  Mutation: {
    fields: {
      absences: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      inventory: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      ticket: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      document: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      employeeData: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      timeTracking: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      workflow: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      evaluation: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      objectives: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      performanceReview: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      project: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      employeeSkills: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      expense: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      skills: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      comment: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
      notifications: {
        merge(_existent, incoming) {
          return incoming;
        },
      },
    },
  },
};

const apolloCacheConfig = {
  possibleTypes,
  typePolicies,
};

export default apolloCacheConfig;
