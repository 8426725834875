import * as React from 'react';
import SimpleUpdateCard from '../SimpleUpdateCard';
import CommentsPopover from '../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import {
  mapAnnouncement,
  useEmojiChange,
} from '../../../People/Announcements/logic';
import { AnnouncementFragment } from '../../../../__generated__/graphql';
import { CompanyAvatar } from '../../../../../../atomic/molecules/CompanyAvatar';
import { useUserInfo } from '../../../../context/UserInfo';
import { MenuHeaderProps } from '../../../../../../atomic/molecules/navigation/types';
import FlairLogo from '../../../../../../vendor/img/flair-logo.svg';
import './AnnouncementUpdateCard.css';
import Reactions from '../../../../components/Reactions';
import { Emoji, EMOJI_OPERATIONS } from '../../../../components/Announcement';
import StringToHtml from '../../../../../../components/StringToHtml';

type Props = {
  announcementFragment: AnnouncementFragment;
};

const AnnouncementUpdateCard: React.FC<Props> = ({ announcementFragment }) => {
  const { organisation, logoUrl } = useUserInfo();
  const announcement = mapAnnouncement(announcementFragment);

  //TODO::refactor when start using the new generic emoji object instead of Announcement's Emojis
  const { onEmojiChange, updatingItems } = useEmojiChange([announcement]);

  const { title, employee, publishedDate, body, commentsCount, id, emojis } =
    announcement;

  if (!publishedDate) {
    return null;
  }

  const companyLogo = logoUrl ?? FlairLogo;
  const logoSrc = employee ? employee.avatarUrl : companyLogo;
  const companyAvatarProps: MenuHeaderProps = {
    title,
    subtitle: employee ? employee.name : organisation,
    logoSrc,
  };

  const avatar = (
    <CompanyAvatar
      avatarSrc={companyAvatarProps.logoSrc}
      title={companyAvatarProps.title}
      subtitle={companyAvatarProps.subtitle}
      logoClass="me-2"
    />
  );

  const handleOnRemoveEmoji = (e: Emoji) => {
    onEmojiChange({ name: e.name, recordId: id }, EMOJI_OPERATIONS.DELETE);
  };

  const handleOnSelectEmoji = (name: string) => {
    onEmojiChange({ name, recordId: id }, EMOJI_OPERATIONS.CREATE);
  };

  return (
    <SimpleUpdateCard
      createdDate={publishedDate}
      avatar={avatar}
      contentClassName="py-3-5">
      <div className="mb-3 announcement-content">
        <StringToHtml text={body} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <Reactions
          emojis={emojis}
          onRemove={handleOnRemoveEmoji}
          onSelect={handleOnSelectEmoji}
          loading={updatingItems.includes(id)}
        />
        <div>
          {commentsCount != null && (
            <CommentsPopover
              recordId={id}
              relatedObjectName={RelatedObjectNames.Announcements}
              commentsCount={commentsCount}
              mode="text"
            />
          )}
        </div>
      </div>
    </SimpleUpdateCard>
  );
};

export default AnnouncementUpdateCard;
