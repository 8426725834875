import React, { useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import {
  useCreateDataChangeRequestMutation,
  useEmployeeDataChangeRequestFormsQuery,
  FieldType,
  ObjectFieldValue,
  useEmployeeRecordFieldsQuery,
} from '../../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../../context/Toast';
import { StepOne, StepOneData } from './StepOne';
import { StepTwo, StepTwoData } from './StepTwo';
import { StepThree } from './StepThree';
import { RecordField } from './types';
import { collectFiles } from '../../../../../../utils/file';

type NewChangeRequestModalProps = {
  show: boolean;
  recordType: string;
  showAllRequests: boolean;
  onHide: () => void;
};

export const ChangeRequestForm: React.FC<NewChangeRequestModalProps> = ({
  show,
  recordType,
  showAllRequests,
  onHide,
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<{
    stepOne?: StepOneData;
    stepTwo?: StepTwoData;
  }>({});
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null,
  );
  const [selectedEmployee, setSelectedEmployee] = useState<{
    Id: string;
    Name: string;
  } | null>(null);

  const { data, loading: loadingRequestForms } =
    useEmployeeDataChangeRequestFormsQuery();

  const [createDataChangeRequest, { loading: isSubmittingRequest }] =
    useCreateDataChangeRequestMutation();

  const selectedRequestForm = useMemo(() => {
    return data?.manager.requestForms.find(
      (form) => form.Id === formData.stepOne?.requestForm,
    );
  }, [data, formData.stepOne?.requestForm]);

  const objectFields = useMemo(() => {
    return (
      selectedRequestForm?.objectsFields.find(
        (field) => field.objectName === recordType,
      )?.fields || []
    );
  }, [selectedRequestForm, recordType]);

  const { data: recordFieldsData, loading: recordFieldsLoading } =
    useEmployeeRecordFieldsQuery({
      variables: {
        employeeId: selectedEmployeeId || '',
        recordType,
        fields: objectFields,
      },
      skip: !formData.stepOne?.requestForm || !selectedEmployeeId,
    });

  const handleStepOneSubmit = (submittedData: StepOneData) => {
    setFormData((prev) => ({ ...prev, stepOne: submittedData }));
    setCurrentStep(2);
  };

  const handleEmployeeSelect = (employeeId: string | null) => {
    setSelectedEmployeeId(employeeId);
    // Clear step two data when employee changes
    setFormData((prev) => ({
      ...prev,
      stepTwo: prev.stepTwo
        ? { ...prev.stepTwo, fields: {}, reason: '', files: [] }
        : undefined,
    }));
  };

  const handleStepTwoSubmit = (submittedData: StepTwoData) => {
    const employee = data?.manager.employees.find(
      (emp) => emp.Id === submittedData.employeeId,
    );
    setSelectedEmployee(employee || null);
    setFormData((prev) => ({ ...prev, stepTwo: submittedData }));
    setCurrentStep(3);
  };

  const handleStepThreeSubmit = async () => {
    if (!formData.stepOne?.requestForm || !formData.stepTwo) {
      return;
    }

    const collectedFiles = await collectFiles(formData.stepTwo.files);

    const input = {
      requestFormId: formData.stepOne.requestForm,
      payload: {
        objectsFieldsChanges: [
          {
            recordId: formData.stepTwo.employeeId,
            objectName: recordType,
            fieldChanges: Object.entries(formData.stepTwo.fields).map(
              ([fieldName, newValue]) => {
                const fieldInfo = recordFieldsData?.recordFields.find(
                  (field) => field.fieldInfo.name === fieldName,
                );
                return mapFieldChangesToRequestPayload(
                  fieldName,
                  newValue,
                  fieldInfo,
                );
              },
            ),
          },
        ],
        changeReason: formData.stepTwo.reason,
        files: collectedFiles.map((file) => ({
          fileName: file.fileName,
          fileContentBase64: file.fileBase64,
        })),
      },
    };

    try {
      await createDataChangeRequest({
        variables: {
          input,
        },
      });
      addSuccess(t('notifications.success'));
      //reset the form for the next request
      setCurrentStep(1);
      setFormData({});
      // close the modal
      onHide();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderStep = () => {
    const selectedForm = data?.manager.requestForms.find(
      (form) => form.Id === formData.stepOne?.requestForm,
    );

    switch (currentStep) {
      case 1:
        return (
          <StepOne
            requestForms={data?.manager.requestForms || []}
            onSubmit={handleStepOneSubmit}
            onCancel={onHide}
            initialData={formData.stepOne}
            loading={loadingRequestForms}
          />
        );

      case 2:
        return (
          <StepTwo
            onSubmit={handleStepTwoSubmit}
            onBack={handleBack}
            initialData={formData.stepTwo}
            employees={data?.manager.employees || []}
            selectedRequestForm={selectedForm}
            recordFields={(recordFieldsData?.recordFields || []).map(
              mapToRecordField,
            )}
            recordFieldsLoading={recordFieldsLoading}
            onEmployeeSelect={handleEmployeeSelect}
          />
        );

      case 3:
        return (
          <StepThree
            onSubmit={handleStepThreeSubmit}
            onBack={handleBack}
            formData={formData}
            isSubmitting={isSubmittingRequest}
            selectedRequestForm={selectedForm || null}
            recordFields={(recordFieldsData?.recordFields || []).map(
              mapToRecordField,
            )}
            selectedEmployee={selectedEmployee}
          />
        );
    }
  };

  const handleHide = () => {
    setCurrentStep(1);
    setFormData({});
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton={!isSubmittingRequest}>
        <div>
          <Modal.Title className="fs-2">
            {currentStep === 1
              ? t('title')
              : selectedRequestForm?.Name || t('title')}
          </Modal.Title>
          <div className="text-muted fs-5 mt-1">
            {currentStep === 1 && t('steps.stepOnePlaceholder')}
            {currentStep === 2 && t('steps.stepTwoPlaceholder')}
            {currentStep === 3 && t('steps.stepThreePlaceholder')}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>{renderStep()}</Modal.Body>
    </Modal>
  );
};

export const mapToRecordField = (src: ObjectFieldValue): RecordField => ({
  fieldInfo: {
    name: src.fieldInfo.name,
    type: src.fieldInfo.type,
    label: src.fieldInfo.label,
    disabled: false,
    required: false,
    options: src.fieldInfo.picklistValues.map((pickListItem) => ({
      label: pickListItem.label,
      value: pickListItem.value,
    })),
  },
  value: src.value,
});

const mapFieldChangesToRequestPayload = (
  fieldName: string,
  newValue: any,
  fieldInfo: ObjectFieldValue | undefined,
) => {
  let newFieldValue = newValue;

  if (fieldInfo?.fieldInfo.type === FieldType.Boolean) {
    newFieldValue = newValue.toString();
  }
  if (fieldInfo?.fieldInfo.type === FieldType.Date) {
    const date = new Date(newValue);
    newFieldValue = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    )
      .toISOString()
      .split('T')[0];
  }

  return {
    fieldName,
    fieldType: fieldInfo?.fieldInfo.type || 'String',
    newValue: newFieldValue,
  };
};
