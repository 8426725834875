import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { OptionBase } from '../../../../../components/Select/types';
import { useToasts } from '../../../../../context/Toast';
import { ModalFileRow } from './ModalFileRow';
import { useDocumentUploadModal } from '../hooks/useDocumentUploadModal';
import { DocumentVisiblity } from '../../../__generated__/graphql';

type Props = {
  visible: boolean;
  uploadInProgress: boolean;
  onClose: () => void;
  categories: OptionBase[];
  onUpload: (files: FileRowType[]) => Promise<void>;
  hideVisibilitySelection: boolean;
};

export type FileRowType = {
  file: File;
  category: string | null;
  visibility: DocumentVisiblity | null;
  label: string;
  type: string;
};

const i18Path = 'documents2.personal.upload';
export const FILE_SIZE_LIMIT = 4 * 1024 * 1024;
export const DocumentUploadModal: React.FC<Props> = ({
  visible,
  uploadInProgress,
  onClose,
  onUpload,
  categories,
  hideVisibilitySelection,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const { addError } = useToasts();
  const {
    handleDrop,
    handleChange,
    handleUpdateFileName,
    handleSetFileCategory,
    handleSetFileVisibility,
    handleRemoveFile,
    handleDrag,
    dragActive,
    inputId,
    isFilesValid,
    files,
    fileRows,
  } = useDocumentUploadModal({ t, addError, hideVisibilitySelection });

  const handleCloseModal: () => void = () => {
    files.clear();
    onClose();
  };

  const uploadFiles: () => void = () => {
    onUpload([...files.values()]).then(() => files.clear());
  };

  return (
    <Modal
      show={visible}
      onHide={handleCloseModal}
      centered
      contentClassName="border"
      dialogClassName={hideVisibilitySelection ? 'break-modal-494' : ''}>
      <Modal.Header closeButton className="card-header border-0">
        <h2 className="card-header-title text-center">{t('title')}</h2>
      </Modal.Header>
      <Modal.Body className={'pt-0'}>
        <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input
            className="file-input"
            type="file"
            multiple={true}
            id={inputId}
            onChange={handleChange}
          />
          <UploadDocumentWrapper
            dragActive={dragActive}
            htmlFor={inputId}
            className="d-flex my-4 pt-4 flex-column align-items-center justify-content-center cu-pointer">
            <FileArrowUp
              dragActive={dragActive}
              className="mb-3"
              size="2x"
              icon="file-arrow-up"
            />
            <div className="d-flex gap-2">
              <h4 className="mb-4">{t('description')}</h4>
              <ChooseFileWrapper>{t('chooseFile')}</ChooseFileWrapper>
            </div>
            <h6>{t('maxSize')}</h6>
          </UploadDocumentWrapper>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}></div>
          )}
          {files && [...files.values()].length > 0 && (
            <FileRowsWrapper className="mb-4">
              {fileRows.map((item, index) => {
                return (
                  <ModalFileRow
                    t={t}
                    key={index}
                    removeFile={handleRemoveFile}
                    updateFileName={handleUpdateFileName}
                    setFileCategory={handleSetFileCategory}
                    setFileVisibility={handleSetFileVisibility}
                    categories={categories}
                    fileRow={item}
                    hideVisibilitySelection={hideVisibilitySelection}
                  />
                );
              })}
            </FileRowsWrapper>
          )}
          <div></div>
          <div className={'d-flex justify-content-center gap-3'}>
            <Button
              variant="outline-primary"
              label={t('buttons.cancel')}
              onClick={handleCloseModal}
            />
            {files.size > 0 && (
              <Button
                isProcessing={uploadInProgress}
                disabled={isFilesValid}
                variant="primary"
                label={t('buttons.upload')}
                onClick={() => uploadFiles()}
              />
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const UploadDocumentWrapper = styled.label<{ dragActive: boolean }>`
  border: 1px dashed
    ${(props) => (props.dragActive ? Theme.color.blue3 : Theme.color.paper1)};
  border-radius: 0.625rem;
  height: 9.5rem;
  background-color: ${(props) =>
    props.dragActive ? Theme.color.blue6 : 'transparent'};
`;

const FileArrowUp = styled(FlairIcon)<{ dragActive: boolean }>`
  & path {
    stroke: ${(props) =>
      props.dragActive ? Theme.color.white : Theme.color.gray2};
    fill: ${(props) => (props.dragActive ? Theme.color.blue3 : 'none')};
  }
`;

const ChooseFileWrapper = styled.h4`
  font-weight: 700;
  color: ${Theme.color.blue3};
`;

const FileRowsWrapper = styled.div`
  max-height: 18.75rem;
  overflow-y: auto;
`;
