import { Maybe } from '../../../../utils/maybe';
import { Emojis } from '../../pages/People/Announcements/types';
import { ReactionFragment } from '../../__generated__/graphql';

export type CommentFile = {
  fileName: string;
  fileBase64?: string;
  size?: number;
  fileType: string;
  link?: string;
};

export type CommentPointer = {
  recordId: string;
  commentId?: string;
};

export type Comment = {
  id?: Maybe<string>;
  employee: Employee;
  text: Maybe<string>;
  createdDate: Date;
  selectedEmojis?: Emojis;
  files: CommentFile[];
  images: CommentFile[];
  isNew?: boolean;
  reactions: ReadonlyArray<ReactionFragment>;
  mentionedEmployees: MentionEmployee[];
};

export type UpsertComment = {
  id?: Maybe<string>;
  employee: Employee;
  text: string;
  createdDate: Date;
  selectedEmojis?: Emojis;
  files: CommentFile[];
  mentionedEmployees: MentionEmployee[];
};

export type Employee = {
  id: string;
  name: string;
  avatarUrl?: Maybe<string>;
  position?: Maybe<string>;
};

/* eslint-disable no-unused-vars */
export enum ActionTypes {
  Image = 'IMAGE',
  File = 'FILE',
  Link = 'LINK',
}

export type ActionType =
  | ActionTypes.Image
  | ActionTypes.File
  | ActionTypes.Link;

export enum RelatedObjectNames {
  Celebration = 'flair__Celebration__c',
  WorkflowItem = 'flair__Workflow_Item__c',
  Absence = 'flair__Absence__c',
  Expense = 'flair__Expense__c',
  EmployeeCertificate = 'flair__Employee_Certificate__c',
  CandidateEvaluation = 'flair__Candidate_Evaluation__c',
  Document = 'flair__Employee_Document__c',
  Inventory = 'flair__Inventory_Item__c',
  Ticket = 'flair__Ticket__c',
  Announcements = 'flair__Company_Announcement__c',
  TimeSheetDay = 'flair__Timesheet_Day__c',
  Candidate = 'flair__Candidate__c',
  EmployeeDataChangeRequest = 'flair__Employee_Data_Change_Request__c',
}

export type RelatedObjectName =
  | RelatedObjectNames.Celebration
  | RelatedObjectNames.WorkflowItem
  | RelatedObjectNames.Expense
  | RelatedObjectNames.EmployeeCertificate
  | RelatedObjectNames.Absence
  | RelatedObjectNames.Document
  | RelatedObjectNames.Inventory
  | RelatedObjectNames.Ticket
  | RelatedObjectNames.Announcements
  | RelatedObjectNames.TimeSheetDay
  | RelatedObjectNames.Candidate
  | RelatedObjectNames.CandidateEvaluation
  | RelatedObjectNames.EmployeeDataChangeRequest;

export type MentionEmployee = {
  id: string;
  hasAccess: boolean;
  name: string;
  avatarUrl: string | null;
  position: string;
  department: string;
  location: string;
  companyEmail: string;
  phone: string;
  manager: Manager | null;
};

type Manager = {
  id: string;
  name: string;
};
