import React from 'react';
import { ExtraFieldsError } from './ExtraFieldsError';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  error?: Error;
};

export class ExtraFieldsErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {}

  handleReset = (): void => {
    this.setState({ hasError: false, error: undefined });
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <ExtraFieldsError
          error={this.state.error?.message ?? 'Unknown error'}
        />
      );
    }

    return this.props.children;
  }
}

export default ExtraFieldsErrorBoundary;
