import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ApproverCommentModal } from '../../../pages/Absences/components/ApproverCommentModal';
import {
  AbsenceRequestFilterType,
  AbsenceTypeMap,
  defaultFilter,
} from './types';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { useAbsencesRequestsPaginationQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import {
  mapAbsenceRequestFilterInput,
  mapAbsenceRequests,
  mapSortBy,
} from './mappings';
import { routes as mainRoutes } from '../../../routes';
import { AbsenceRequestFiltersConnected } from './Filters/AbsenceRequestFiltersConnected';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { Maybe } from '../../../../../utils/maybe';
import { LoomVideo } from '../../../components/LoomVideo';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';

import { AbsenceRequest } from '../types';
import { EmptyAbsenceRequestsCard } from '../EmptyAbsenceRequestsCard';
import { useAbsenceRequestTableRenderSettings } from '../useAbsenceRequestTableRenderSettings';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTable,
  ExtraTableRenderSettings,
} from '../../../components/ExtraFields';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { ReviewActionsCell } from '../ReviewActionsCell';
import { useApproveAbsenceRequestTable } from '../useAbsencesRequestsTable';
import Avatar from '../../../pages/Absences/components/Avatar';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { ExtraFieldsTableId } from '../../../components/ExtraFields/types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import AbsenceStatus from '../../../pages/Absences/components/AbsenceStatus';
import { EmployeeWithAvatar } from '../../../components/Employee/EmployeeWithAvatar';
import { CellProps, Column } from 'react-table';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import AbsenceDateTimeRange from '../../../pages/Absences/components/AbsenceDateTimeRange';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_BREAKPOINT } from '../common';
import { TableContentManual } from '../../components/TableContentManual';

const PAGE_SIZE = 50;

const i18Path = 'absences.myAbsences.table';

export const AbsenceRequestsTable: React.FC = () => {
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);

  const { columns } = useAbsenceRequestTableRenderSettings({
    onLoomVideoClick: setSelectedLoomVideo,
  });

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const t = useNamespacedTranslation(i18Path);

  const {
    isAccepting,
    isRejecting,
    isInProgress,
    handleAccept,
    handleReject,
    handleRejectButtonClick,
    onModalClose,
    rejectingModalId,
  } = useApproveAbsenceRequestTable();

  const reviewCell = useCallback(
    (props: CellProps<AbsenceRequest>) => {
      if (props.row.original.approvalStatus === 'PENDING') {
        return (
          <ReviewActionsCell
            id={props.row.original.id}
            isAccepting={isAccepting}
            isRejecting={isRejecting}
            isInProgress={isInProgress}
            handleAccept={handleAccept}
            handleRejectButtonClick={handleRejectButtonClick}
          />
        );
      } else {
        const row = props.row.original;
        const reviewedRequest = row.approvalRequests.find((item) =>
          ['APPROVED', 'REJECTED'].includes(item.approvalStatus),
        );
        const avatar = reviewedRequest?.approver.avatar;
        const firstName = reviewedRequest?.approver.firstName;
        const lastName = reviewedRequest?.approver.lastName;
        const initials = `${firstName?.[0]}${lastName?.[0]}`;
        const fullName = reviewedRequest?.approver.name;
        return (
          <div className="d-flex align-items-center gap-2 ps-3">
            <Avatar
              avatar={avatar}
              initials={initials}
              fullName={fullName || ''}
            />
            <FormattedDate day={row.lastModifiedDate} format="short" />
          </div>
        );
      }
    },
    [
      isAccepting,
      isRejecting,
      isInProgress,
      handleAccept,
      handleRejectButtonClick,
    ],
  );

  const renderSettings: ExtraTableRenderSettings<AbsenceRequest> = useMemo(
    () => ({
      columns: {
        ...columns,
        flair__Approval_Status__c: {
          Header: t('status'),
          Cell: (props) => {
            const rejectedRequest = props.row.original.approvalRequests.find(
              (req) => req.approvalStatus === 'REJECTED',
            );
            const hasRejectionComment =
              rejectedRequest && rejectedRequest.approverComment;
            return (
              <div className="d-flex gap-1 align-items-center">
                <AbsenceStatus status={props.row.original.approvalStatus} />
                {hasRejectionComment && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip">
                        {rejectedRequest.approverComment}
                      </Tooltip>
                    }>
                    <span>
                      <FlairIcon
                        role="button"
                        className={'ms-2 cursor-auto text-primary'}
                        icon="chatbubble-outline"
                      />
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            );
          },
        },
      },
      afterPersistentColumns: {
        ActionColumn: {
          Header: t('review'),
          Cell: reviewCell,
        },
      },
    }),
    [columns, t, reviewCell],
  );

  const mobileColumns: Column<AbsenceRequest>[] = useMemo(
    () => [
      {
        Header: t('employee'),
        accessor: 'id',
        Cell: (props: CellProps<AbsenceRequest>) => (
          <Col>
            <Link
              to={routes.absenceRequestToMe.route}
              absenceRequestId={props.row.original.id}>
              <EmployeeWithAvatar employee={props.row.original.requester} />
            </Link>
            <Detail>
              <Link
                to={routes.absenceRequestToMe.route}
                absenceRequestId={props.row.original.id}>
                <AbsenceDateTimeRange absence={props.row.original} />
              </Link>
              <div>
                <span className="me-1">{t('mobile.workingTime')}</span>
                {`${props.row.original.workingAmount} ${
                  AbsenceTypeMap[props.row.original.type]
                }`}
              </div>
              <div>
                <span className="me-1">{t('mobile.type')}</span>
                {props.row.original.categoryName}
              </div>
              <div>
                <span className="me-1">{t('mobile.requestedAt')}</span>
                <FormattedDateTime
                  dateTime={props.row.original.createdDate}
                  format="long"
                />
              </div>
            </Detail>
          </Col>
        ),
      },
      {
        Header: t('review'),
        accessor: 'absenceId',
        Cell: reviewCell,
      },
    ],
    [t, reviewCell],
  );

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  } = useFlairTableWithPaginationAndFilter<
    AbsenceRequest,
    AbsenceRequestFilterType
  >({
    defaultFilter: defaultFilter,
    storageKey: 'absence_requests_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.allAbsenceRequests.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const [reportsMode] = useManagerReportsMode('absences');

  const { data, error, loading } = useAbsencesRequestsPaginationQuery({
    variables: {
      filter: mapAbsenceRequestFilterInput(tableState.filter, reportsMode),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  useEffect(() => {
    const totalCount =
      data?.manager.absenceRequestsPagination.paginationInfo?.totalCount;
    setTotalItemsCount(totalCount);
  }, [setTotalItemsCount, data]);

  if (error) return <ServerError />;

  const requests = data
    ? mapAbsenceRequests(data?.manager.absenceRequestsPagination.items)
    : undefined;

  const renderTable = (requests: AbsenceRequest[]) => {
    if (!requests.length) {
      return <EmptyAbsenceRequestsCard />;
    }
    if (!isMobile) {
      return (
        <ExtraFieldsTable
          useTableContentManual
          objectApiName={ExtraFieldsSupportedObject.Absence}
          renderSettings={renderSettings}
          tableId={ExtraFieldsTableId.AbsencesManager}
          records={requests}
          onRowClick={() => {}}
          pageSize={PAGE_SIZE}
          itemsCount={tableState.totalItemsCount}
          initialSortBy={tableState.sortBy}
          onSortByChanged={handleSortByChanged}
          initialPageIndex={tableState.pageIndex}
          onPageIndexChanged={handlePageIndexChanged}
        />
      );
    } else {
      return (
        <TableContentManual
          data={requests}
          columns={mobileColumns}
          onRowClick={() => {}}
          pageSize={PAGE_SIZE}
          itemsCount={tableState.totalItemsCount}
          initialSortBy={tableState.sortBy}
          onSortByChanged={handleSortByChanged}
          initialPageIndex={tableState.pageIndex}
          onPageIndexChanged={handlePageIndexChanged}
        />
      );
    }
  };

  return (
    <Card>
      <Header className="h-auto">
        <Row className="align-items-center">
          <Col className="small">
            <AbsenceRequestFiltersConnected
              filter={tableState.filter}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Header>

      {requests !== undefined && !loading ? (
        renderTable(requests)
      ) : (
        <SkeletonTable className="card-table" columns={5} rows={10} />
      )}

      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}

      <ApproverCommentModal
        requestId={rejectingModalId}
        onClose={onModalClose}
        onRejected={handleReject}
      />
    </Card>
  );
};

const Header = styled(Card.Header)({
  height: 'auto',
  minHeight: 60,
});

const Detail = styled.div({
  marginLeft: 32,
});
