import React from 'react';
import { OptionBase } from '../../../../../components/Select/types';
import { DocumentFileTypeIcon } from '../DocumentFileType';
import { Theme } from '../../../../../theme';
import { FileRowType } from './DocumentUploadModal';
import styled from '@emotion/styled';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { TOptions } from 'i18next';
import { DocumentVisiblity } from '../../../__generated__/graphql';

type FileRowProps = {
  t: (key: string, options?: TOptions) => string;
  fileRow: FileRowType;
  categories: OptionBase[];
  removeFile: (fileRow: FileRowType) => void;
  updateFileName: (fileRow: FileRowType, name: string) => void;
  setFileCategory: (fileRow: FileRowType, category: string | null) => void;
  setFileVisibility: (
    fileRow: FileRowType,
    visibility: DocumentVisiblity | null,
  ) => void;
  hideVisibilitySelection: boolean;
};

export const ModalFileRow: React.FC<FileRowProps> = React.memo(
  ({
    t,
    fileRow,
    categories,
    removeFile,
    updateFileName,
    setFileCategory,
    setFileVisibility,
    hideVisibilitySelection,
  }) => {
    const visibilityOptions: { value: DocumentVisiblity; label: string }[] = [
      {
        value: DocumentVisiblity.HrManagerEmployee,
        label: t('visibility.hrManagerEmployee'),
      },
      { value: DocumentVisiblity.Hr, label: t('visibility.hr') },
      { value: DocumentVisiblity.HrManager, label: t('visibility.hrManager') },
      {
        value: DocumentVisiblity.HrEmployee,
        label: t('visibility.hrEmployee'),
      },
    ];

    return (
      <>
        <FileRowItem className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center me-3">
            <DocumentFileTypeIcon fileType={fileRow.type} />
          </div>
          <div className="d-flex flex-row align-items-center flex-grow-1">
            <DocumentNameInput
              value={fileRow.label}
              onChange={(e) => updateFileName(fileRow, e.target.value)}
              name="documentName"
              placeholder={t('inputPlaceholder')}
              className="me-2"
            />
            <SingleFilterStyled
              placeholder={t('selectPlaceholder')}
              isSearchable={false}
              isClearable={false}
              options={categories}
              value={fileRow.category}
              heightMode="small"
              onChange={(value) => setFileCategory(fileRow, value)}
              className="me-2"
            />
            {!hideVisibilitySelection && (
              <SingleFilterStyled
                placeholder={t('selectVisibilityPlaceholder')}
                isSearchable={false}
                isClearable={false}
                options={visibilityOptions}
                value={fileRow.visibility}
                heightMode="small"
                onChange={(value) => {
                  if (isDocumentVisibility(value)) {
                    setFileVisibility(fileRow, value);
                  }
                }}
                className="me-2"
              />
            )}
          </div>
          <FlairIcon
            icon="close-outline"
            onClick={() => removeFile(fileRow)}
            color={Theme.color.danger}
            className="cu-pointer ms-2"
            size="sm"
          />
        </FileRowItem>
      </>
    );
  },
);

const isDocumentVisibility = (
  val: string | null,
): val is DocumentVisiblity | null => {
  return (
    val === null ||
    Object.values(DocumentVisiblity).includes(val as DocumentVisiblity)
  );
};

const DocumentNameInput = styled.input`
  border-radius: 0.375rem;
  min-width: 10.625rem;
  flex: 1;
  height: 1.5rem;
  border: 1px ${Theme.color.paper1} solid;
  padding: 0.313rem 0.5rem;
  outline: none;
  font-size: ${Theme.font.size.xs};
`;

const FileRowItem = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${Theme.color.paper4};
`;

const SingleFilterStyled = styled(SingleFilter)`
  width: 9.75rem;
  height: 1.5rem;
  border-bottom: 1px solid ${Theme.color.paper4};
  span {
    font-size: ${Theme.font.size.xs};
  }
`;
