import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import Button from '../../../../../../../components/button';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import './Attachments.css';

type DownloadableFile = {
  id: string;
  fileName: string;
  downloadUrl: string;
};

type AttachmentsProps = {
  files: Array<File | DownloadableFile>;
  onChange?: (files: File[]) => void;
  readonly?: boolean;
};

export const Attachments: React.FC<AttachmentsProps> = ({
  files,
  onChange,
  readonly = false,
}) => {
  if (files.length === 0) {
    return null;
  }

  const handleRemove = (indexToRemove: number) => {
    if (!onChange) return;
    const newFiles = files.filter(
      (_, index) => index !== indexToRemove,
    ) as File[];
    onChange(newFiles);
  };

  const isDownloadableFile = (
    file: File | DownloadableFile,
  ): file is DownloadableFile => {
    return 'downloadUrl' in file;
  };

  return (
    <Stack gap={2}>
      <Stack gap={2} className="attachmentsContainer">
        {files.map((file, index) => (
          <Card
            key={isDownloadableFile(file) ? file.id : `${file.name}-${index}`}
            className="mb-1 py-2 bg-primary-soft rounded">
            <Card.Body className="py-0 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-start">
                <FlairIcon icon="file-arrow-up" className="me-2 text-primary" />
                {isDownloadableFile(file) ? (
                  <a
                    href={file.downloadUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-decoration-none">
                    {file.fileName}
                  </a>
                ) : (
                  <div className="text-primary">{file.name}</div>
                )}
              </div>
              {!readonly && (
                <Button
                  variant="link"
                  className="p-0"
                  icon="trash-outline"
                  onClick={() => handleRemove(index)}
                />
              )}
            </Card.Body>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};
