import {
  useCancelDataChangeRequestMutation,
  useEmployeeRecordFieldsQuery,
  useManagerChangeRequestByIdQuery,
} from '../../../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { useToasts } from '../../../../../../../context/Toast';

export const useCancelChangeRequest = (onSuccess: () => void) => {
  const t = useNamespacedTranslation('changeRequests');
  const { addSuccess, addError } = useToasts();

  const [cancelRequest, { loading: isCanceling }] =
    useCancelDataChangeRequestMutation({
      onCompleted: () => {
        addSuccess(t('previewModal.cancelSuccess'));
        onSuccess();
      },
      onError: (error) => {
        addError(t('previewModal.cancelError'));
      },
      update: (cache, response, variables) => {
        if (!response.data || !variables) {
          return;
        }
        const changeRequestId = variables.variables?.id;
        const changeRequestCacheId = cache.identify({
          Id: changeRequestId,
          __typename: 'EmployeeDataChangeRequest',
        });
        cache.evict({
          id: changeRequestCacheId,
        });
        cache.gc();
      },
    });

  const handleCancel = async (id: string) => {
    await cancelRequest({
      variables: {
        id,
      },
    });
  };

  return {
    handleCancel,
    isCanceling,
  };
};

export const useChangeRequestData = (
  requestId: string,
  recordId: string,
  recordType: string,
  show: boolean,
) => {
  const { data: requestData, loading: loadingRequest } =
    useManagerChangeRequestByIdQuery({
      variables: { id: requestId },
      skip: !show,
    });

  const request = requestData?.manager.changeRequests[0];
  const changes = request ? JSON.parse(request.flair__Changes__c || '{}') : {};

  const {
    data: employeeRecordFieldsData,
    loading: loadingEmployeeRecordFields,
  } = useEmployeeRecordFieldsQuery({
    variables: {
      employeeId: recordId,
      recordType,
      fields: Object.keys(changes),
    },
    skip: !show || !request,
  });

  return {
    request,
    recordFields: employeeRecordFieldsData?.recordFields || [],
    isLoading:
      (show && loadingRequest) ||
      (show && request && loadingEmployeeRecordFields),
    changes,
  };
};
