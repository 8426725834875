import React, { useCallback, useState } from 'react';
import { DocumentUploadModal, FileRowType } from './DocumentUploadModal';
import { useUploadEmployeeDocumentMutation } from '../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../context/Toast';
import { useApolloCache } from '../../../hooks/useApolloCache';
import { toBase64 } from '../../../../../utils/file';
import { OptionBase } from '../../../../../components/Select/types';

const i18Path = 'documents2.personal';

type Props = {
  visible: boolean;
  onClose: () => void;
  categories: OptionBase[];
  currentFolderId: string;
  employeeId: string;
  hideVisibilitySelection: boolean;
  onSuccess?: () => void;
};

export const DocumentUploadModalConnected: React.FC<Props> = (props) => {
  const {
    currentFolderId,
    categories,
    onClose,
    visible,
    employeeId,
    onSuccess,
    hideVisibilitySelection,
  } = props;
  const [uploadPersonalDocument] = useUploadEmployeeDocumentMutation();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const t = useNamespacedTranslation(i18Path);
  const errorHandler = useMutationErrorHandler();
  const { addSuccess } = useToasts();
  const cache = useApolloCache();

  const uploadFilePromise = async (fileRow: FileRowType) => {
    const fileBase64 = await toBase64(fileRow.file);
    await uploadPersonalDocument({
      variables: {
        employeeId,
        input: {
          documentName: fileRow.label,
          category: fileRow.category!,
          visibility: fileRow.visibility,
          fileName: fileRow.file.name,
          fileContentBase64: fileBase64,
          folderId: currentFolderId,
        },
      },
    }).catch(errorHandler);
  };

  const folderCacheId: string | undefined = cache.identify({
    __typename: 'EmployeeDocumentFolder',
    Id: currentFolderId,
  });

  const invalidateCache = useCallback(() => {
    if (!folderCacheId) {
      return;
    }
    cache.evict({
      id: folderCacheId,
      fieldName: 'children',
    });
  }, [cache, folderCacheId]);

  const uploadFiles = (fileRows: FileRowType[]) => {
    const promises: any[] = fileRows.map(uploadFilePromise);
    setUploadInProgress(true);
    return Promise.all([...promises])
      .then(() => {
        addSuccess(t('upload.successText', { fileLength: fileRows.length }));
        onSuccess && onSuccess();
      })
      .catch(errorHandler)
      .finally(() => {
        invalidateCache();
        setUploadInProgress(false);
        onClose();
      });
  };

  if (visible) {
    return (
      <DocumentUploadModal
        categories={categories}
        visible={visible}
        onClose={() => onClose()}
        onUpload={uploadFiles}
        uploadInProgress={uploadInProgress}
        hideVisibilitySelection={hideVisibilitySelection}
      />
    );
  }
  return null;
};
