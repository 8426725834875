import React, { useMemo } from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Link } from '../../../../Router';
import {
  ExtraFieldsTableCustomColumns,
  ExtraTableRenderSettings,
} from '../../components/ExtraFields/types';
import { routes } from '../../routes';
import SoftBadge from '../../components/SoftBadge';
import AbsenceCategoryIcon from '../../components/AbsenceCategoryIcon';
import {
  AbsenceType,
  ExtraFieldsTable_WithExtraFieldsFragment,
} from '../../__generated__/graphql';
import CommentsPopover from '../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../components/Comment/types';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import AbsenceStatus from './components/AbsenceStatus';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Absence, ApprovalRequest } from './MyAbsences/MyAbsenceFilters/types';
import Approvers from './components/Approvers';
import { formatDateShort, parseDate } from '../../../../utils/dateUtils';
import { isAfter, startOfDay } from 'date-fns';
import AbsenceDateTimeRange, {
  AbsenceDateTimeRangeProps,
} from './components/AbsenceDateTimeRange';

const i18Path = 'absences';

const isUpcoming = (date: string): boolean =>
  isAfter(startOfDay(parseDate(date)), startOfDay(new Date()));

type Props = {
  onLoomVideoClick: (loomVideo: Absence['loomVideo']) => void;
};

type AbsenceInputType = ExtraFieldsTable_WithExtraFieldsFragment &
  AbsenceDateTimeRangeProps &
  Pick<
    Absence,
    | 'type'
    | 'categoryIcon'
    | 'categoryName'
    | 'commentsCount'
    | 'loomVideo'
    | 'approvalRequests'
    | 'approvalStatus'
  >;

export const useAbsencesTableRenderSettings = <T extends AbsenceInputType>({
  onLoomVideoClick,
}: Props) => {
  const t = useNamespacedTranslation(i18Path);

  const columns: ExtraTableRenderSettings<T>['columns'] = useMemo(() => {
    return {
      flair__Working_Amount__c: {
        Header: t('myAbsences.table.workingTime'),
        Cell: ({ row, value }) =>
          row.original.type === AbsenceType.Daily
            ? t('myAbsences.table.days', {
                count: value,
              })
            : t('myAbsences.table.hours', {
                count: value,
              }),
      },
      flair__Amount__c: {
        Header: t('myAbsences.table.calendarTime'),
        Cell: ({ row, value }) =>
          row.original.type === AbsenceType.Daily
            ? t('myAbsences.table.days', {
                count: value,
              })
            : t('myAbsences.table.hours', {
                count: value,
              }),
      },
      flair__Approval_Status__c: {
        Header: t('myAbsences.table.status'),
        Cell: ({ row }) => {
          const rejectedRequest = row.original.approvalRequests.find(
            (req: ApprovalRequest) => req.approvalStatus === 'REJECTED',
          );
          const hasRejectionComment =
            rejectedRequest && rejectedRequest.approverComment;
          return (
            <div className="d-flex gap-1 align-items-center">
              <AbsenceStatus status={row.original.approvalStatus} />
              {hasRejectionComment && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip`}>
                      {rejectedRequest.approverComment}
                    </Tooltip>
                  }>
                  <span>
                    <FlairIcon
                      icon="chatbubble-outline"
                      role="button"
                      className={'ms-2 cursor-auto text-primary'}
                    />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          );
        },
      },
      CreatedDate: {
        Header: t('myAbsences.table.requested'),
        Cell: ({ value }) => {
          return <div>{formatDateShort(parseDate(value))}</div>;
        },
      },
      [ExtraFieldsTableCustomColumns.absences.name]: {
        Header: t('myAbsences.table.duration'),
        Cell: ({ row }) => {
          return (
            <Link to={routes.myAbsenceDetail.route} absenceId={row.original.Id}>
              <div className="d-flex align-items-center gap-2">
                <AbsenceDateTimeRange absence={row.original} />
                {isUpcoming(row.original.startDate) && (
                  <SoftBadge variant="primary">
                    {t('myAbsences.table.upcoming')}
                  </SoftBadge>
                )}
              </div>
            </Link>
          );
        },
      },
      [ExtraFieldsTableCustomColumns.absences.absenceCategory]: {
        Header: t('myAbsences.table.type'),
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex gap-2">
              <AbsenceCategoryIcon icon={row.original.categoryIcon} />
              <span>{row.original.categoryName}</span>
            </div>
          );
        },
      },
      [ExtraFieldsTableCustomColumns.absences.comments]: {
        Header: t('myAbsences.table.comments'),
        Cell: ({ row }) => {
          return (
            <div className="pt-2">
              <CommentsPopover
                recordId={row.original.Id}
                relatedObjectName={RelatedObjectNames.Absence}
                commentsCount={row.original.commentsCount}
                mode="icon"
              />
            </div>
          );
        },
      },
      [ExtraFieldsTableCustomColumns.absences.loom]: {
        Header: t('myAbsences.table.loom'),
        Cell: ({ row }) => {
          return (
            <div className="pt-2">
              {row.original.loomVideo ? (
                <FlairIcon
                  icon="loom"
                  className="text-primary"
                  onClick={() => onLoomVideoClick(row.original.loomVideo)}
                />
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
      [ExtraFieldsTableCustomColumns.absences.approver]: {
        Header: t('myAbsences.table.reviewal'),
        Cell: ({ row }) => {
          return <Approvers approvalRequests={row.original.approvalRequests} />;
        },
      },
    };
  }, [t, onLoomVideoClick]);

  return { columns };
};
