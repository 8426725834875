import React from 'react';
import { Stack } from 'react-bootstrap';
import SkeletonInput from '../../../../../components/Skeleton/Input';
import SkeletonCard from '../../../../../components/Skeleton/Card';

export const Loading: React.FC = () => {
  return (
    <Stack gap={4} className="p-4">
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonCard height={100} />
      <SkeletonCard height={100} />
      <SkeletonCard height={100} />
    </Stack>
  );
};
