import {
  ExtraFieldFormInfoFragment,
  ObjectExtraFieldsFragment,
} from '../../__generated__/graphql';
import {
  parseVisibilitySchemaModel,
  VisibilitySchemaModel,
  VisibilitySchemaModelNode,
} from './extraFieldsVisibilitySchemaModel';
import { ExtraFieldContextParam, ExtraFieldsFormId } from './types';

type VisiblityContext = {
  recordTypeName: string | null;
  contextParams: ExtraFieldContextParam[];
  formId: ExtraFieldsFormId;
};

export const visibilityRecordTypeName = '$formValues.recordTypeName';
export const contextParamsPrefix = '$contextParams';

export class ExtraFieldsVisibility {
  constructor(private readonly objectExtraFields: ObjectExtraFieldsFragment) {}

  isFieldVisible(
    field: Pick<
      ExtraFieldFormInfoFragment,
      'visibilityCondition' | 'formId' | 'fieldApiName'
    >,
    context: VisiblityContext,
  ): boolean {
    if (!this.isFormVisible(field, context)) {
      return false;
    }
    if (!field.visibilityCondition) {
      return true;
    }

    try {
      const visiblitySchema = parseVisibilitySchemaModel(
        field.visibilityCondition,
      );
      return this.calculateVisibility(visiblitySchema, context);
    } catch (error) {
      throw new Error(
        `Error in "${field.fieldApiName}" Visibility Condition\n\n${error}\n\nVisibility Condition: ${field.visibilityCondition}`,
      );
    }
  }

  private isFormVisible(
    { formId }: Pick<ExtraFieldFormInfoFragment, 'formId'>,
    context: VisiblityContext,
  ): boolean {
    return formId === null || formId === context.formId;
  }
  private calculateVisibility(
    fieldVisibilitySchema: VisibilitySchemaModel,
    context: VisiblityContext,
  ): boolean {
    if (!fieldVisibilitySchema) {
      return true;
    }

    // Handle $and operator
    if (
      '$and' in fieldVisibilitySchema &&
      Array.isArray(fieldVisibilitySchema.$and)
    ) {
      return fieldVisibilitySchema.$and.every((condition) =>
        this.calculateVisibility(condition, context),
      );
    }

    // Handle $or operator
    if (
      '$or' in fieldVisibilitySchema &&
      Array.isArray(fieldVisibilitySchema.$or)
    ) {
      return fieldVisibilitySchema.$or.some((condition) =>
        this.calculateVisibility(condition, context),
      );
    }

    // Handle leaf nodes
    return Object.entries(fieldVisibilitySchema).every(([key, schemaNode]) => {
      if (key === visibilityRecordTypeName) {
        return this.testValue(schemaNode, context.recordTypeName);
      } else if (this.isContextParam(key)) {
        const contextParam = context.contextParams.find(
          (param) => param.name === this.getParamName(key),
        );
        return this.testValue(schemaNode, contextParam?.value);
      } else {
        throw new Error(`Unknown key: ${key}`);
      }
    });
  }

  private testValue(
    schemaNode: VisibilitySchemaModelNode,
    contextValue: string | undefined | null,
  ): boolean {
    if (schemaNode.type === '$eq') {
      return schemaNode.value.some(
        (value) => value.toLowerCase() === contextValue?.toLowerCase(),
      );
    } else if (schemaNode.type === '$ne') {
      return schemaNode.value.every(
        (value) => value.toLowerCase() !== contextValue?.toLowerCase(),
      );
    }
    return false;
  }

  private isContextParam(key: string): boolean {
    return key.startsWith(`${contextParamsPrefix}.`);
  }

  private getParamName(key: string): string {
    return key.replace(`${contextParamsPrefix}.`, '');
  }
}
