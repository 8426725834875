import React from 'react';
import { FieldType } from '../../../../__generated__/graphql';
import { FieldValue } from '../../../../components/EmployeeData/FieldValue';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type FormatFieldValueProps = {
  value: string | null;
  type: FieldType;
};

export const FormatFieldValue: React.FC<FormatFieldValueProps> = ({
  value,
  type,
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');

  if (value === null || value === '') {
    return <>{t('values.empty')}</>;
  }
  if (type === FieldType.Boolean) {
    const isTrue = value.toString() === 'true';
    return <>{isTrue ? t('values.checked') : t('values.unchecked')}</>;
  } else {
    return <FieldValue value={value.toString()} type={type} options={[]} />;
  }
};
