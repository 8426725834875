import React, { useState, useMemo, useCallback } from 'react';
import { Stack, Row, Col, Spinner, Form } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { StepIndicator } from './components/StepIndicator';
import { FieldInput } from '../../../../components/EmployeeData';
import { FormatFieldValue } from '../components/FormatFieldValue';
import { RecordField } from './types';
import { useToasts } from '../../../../../../context/Toast';
import { Form as CustomForm } from '../../../../../../components/form/Form';
import { SelectSingle } from '../../../../components/Selects/SelectSingle';
import { RequestFormFragment } from '../../../../__generated__/graphql';
import UploadFile from '../../../../../../components/UploadFile';
import { Attachments } from './components/Attachments';

export type StepTwoData = {
  employeeId: string;
  reason: string;
  fields: {
    [key: string]: string;
  };
  files: File[];
};

type StepTwoProps = {
  onSubmit: (data: StepTwoData) => void;
  onBack: () => void;
  initialData?: StepTwoData;
  employees: ReadonlyArray<{
    Id: string;
    Name: string;
  }>;
  selectedRequestForm?: RequestFormFragment | null;
  recordFields: Array<RecordField>;
  recordFieldsLoading: boolean;
  onEmployeeSelect: (employeeId: string | null) => void;
};

type NewFieldValues = {
  [key: string]: string;
};

export const StepTwo: React.FC<StepTwoProps> = ({
  onSubmit,
  onBack,
  initialData,
  employees,
  selectedRequestForm,
  recordFields,
  recordFieldsLoading,
  onEmployeeSelect,
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');
  const { addError } = useToasts();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    initialData?.employeeId || null,
  );
  const [reason, setReason] = useState(initialData?.reason || '');
  const [selectedFiles, setSelectedFiles] = useState<File[]>(
    initialData?.files || [],
  );

  const isReasonMandatory =
    selectedRequestForm?.flair__Mandatory_Reason__c || false;
  const disableNext = !selectedEmployeeId || (isReasonMandatory && !reason);

  // Create default values for the dynamic fields
  const defaultValues = useMemo(() => {
    const fieldValues: { [key: string]: string } = {};
    recordFields.forEach((field) => {
      fieldValues[field.fieldInfo.name] =
        initialData?.fields?.[field.fieldInfo.name] || field.value || '';
    });
    return fieldValues;
  }, [recordFields, initialData]);

  const handleSubmit = useCallback(
    async (formData: any) => {
      if (!selectedEmployeeId) {
        return;
      }

      const changedFields = getChangedFields(formData, recordFields);

      if (Object.keys(changedFields).length === 0) {
        addError(t('errors.noChanges'));
        return;
      }

      onSubmit({
        employeeId: selectedEmployeeId,
        reason,
        fields: changedFields,
        files: selectedFiles,
      });
    },
    [
      selectedEmployeeId,
      recordFields,
      selectedFiles,
      onSubmit,
      reason,
      addError,
      t,
    ],
  );

  return (
    <Stack gap={4}>
      <div>
        <Stack gap={3}>
          <SelectSingle
            name="employee"
            placeholder={t('employee.placeholder')}
            options={employees.map((emp) => ({
              value: emp.Id,
              label: emp.Name,
            }))}
            value={selectedEmployeeId || ''}
            onChange={(value) => {
              setSelectedEmployeeId(value);
              onEmployeeSelect(value);
            }}
          />

          {selectedEmployeeId && (
            <Stack gap={3}>
              <Form.Group>
                <Form.Label>
                  {t('reason.label')}
                  {isReasonMandatory && (
                    <span className="text-danger ms-1">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="reason"
                  placeholder={t('reason.placeholder')}
                  required={isReasonMandatory}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Group>
              <Stack gap={2}>
                <UploadFile
                  multiple={true}
                  onChange={(files) => {
                    const filesList = Array.from(files);
                    setSelectedFiles(filesList);
                  }}
                />
                <Attachments
                  files={selectedFiles}
                  onChange={setSelectedFiles}
                />
              </Stack>
            </Stack>
          )}

          {!selectedEmployeeId && (
            <div className="text-center text-muted">
              <p>{t('selectEmployeeFirst')}</p>
            </div>
          )}
        </Stack>
      </div>

      {selectedEmployeeId &&
        (recordFieldsLoading ? (
          <div className="text-center">
            <Spinner animation="border" size="sm" />
          </div>
        ) : (
          <CustomForm
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            isChangeable={true}>
            <div className="fields-section pt-4 border-top">
              <h4 className="ms-1 mb-4">{t('changes')}</h4>
              <Stack
                gap={3}
                className="fields-container"
                style={{
                  maxHeight: '15rem',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  padding: '0 0.25rem',
                }}>
                {recordFields.map((field) => (
                  <div key={field.fieldInfo.name} className="mb-3">
                    <FieldInput
                      fieldInfo={{
                        ...field.fieldInfo,
                        disabled: false,
                      }}
                    />
                    <div className="text-muted small d-flex gap-2 ms-1 mt-n3">
                      <span>{t('current')}:</span>
                      <span>
                        <FormatFieldValue
                          value={field.value}
                          type={field.fieldInfo.type}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </Stack>
            </div>
            <NavigationButtons onBack={onBack} disableNext={disableNext} />
          </CustomForm>
        ))}

      {!selectedEmployeeId && (
        <NavigationButtons onBack={onBack} disableNext={true} type="button" />
      )}
    </Stack>
  );
};

const getChangedFields = (
  currentValues: NewFieldValues,
  originalFields: Array<RecordField>,
): NewFieldValues => {
  const changedFields: NewFieldValues = {};

  for (const fieldName of Object.keys(currentValues)) {
    const originalField = originalFields.find(
      (field) => field.fieldInfo.name === fieldName,
    );
    const originalValue = originalField?.value || '';
    const newValue = currentValues[fieldName];

    if (newValue.toString() !== originalValue.toString()) {
      changedFields[fieldName] = newValue;
    }
  }

  return changedFields;
};

type NavigationButtonsProps = {
  onBack: () => void;
  disableNext?: boolean;
  type?: 'submit' | 'button';
};

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  onBack,
  disableNext = false,
  type = 'submit',
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');

  return (
    <Row className="align-items-center mt-3">
      <Col>
        <Button
          variant="outline-primary"
          label={t('buttons.back')}
          onClick={onBack}
        />
      </Col>
      <Col className="text-center">
        <StepIndicator totalSteps={3} currentStep={2} />
      </Col>
      <Col className="text-end">
        <Button
          type={type}
          variant="primary"
          label={t('buttons.next')}
          disabled={disableNext}
        />
      </Col>
    </Row>
  );
};
