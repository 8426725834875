import {
  ExpenseCategory,
  ExpensesQuery,
  ExpenseStatus,
  ExtraFieldsTable_WithExtraFieldsFragment,
  ManagerExpensesQuery,
} from '../../__generated__/graphql';

export type Expense = Omit<expenseSF, 'project' | 'merchant' | 'employee'> & {
  id: string;
  description: string | null;
  amount: number;
  expenseDate: Date;
  project: string | null;
  merchant: string;
  category: ExpenseCategory | null;
  status: ExpenseStatus;
  employee: Colleague;
  documents: DocumentFile[];
  declineReason: string | null;
  name: string;
  isNew: boolean;
  currencyIsoCode: string;
  commentsCount: number;
} & ExtraFieldsTable_WithExtraFieldsFragment;

export type Colleague =
  | ManagerExpensesQuery['manager']['expenses'][0]['employee']
  | ExpensesQuery['me']['myExpenses'][0]['employee'];

export type ContentDocumentLink =
  | ManagerExpensesQuery['manager']['expenses'][0]['ContentDocumentLinks'][0]
  | ExpensesQuery['me']['myExpenses'][0]['ContentDocumentLinks'][0];

export type expenseSF =
  | ManagerExpensesQuery['manager']['expenses'][0]
  | ExpensesQuery['me']['myExpenses'][0];

export const allExpenseStatuses = [
  ExpenseStatus.Approved,
  ExpenseStatus.Pending,
  ExpenseStatus.Declined,
  ExpenseStatus.Reimbursed,
];

export type ExpenseStatusInfo = {
  expenseId: string;
  status: ExpenseStatus;
  declineReason: string | null;
};

export const isExpenseStatus = (value: string): value is ExpenseStatus => {
  return allExpenseStatuses.some((x) => x === value);
};

export type DocumentFile = {
  id: string;
  fileName: string;
  downloadPublicUrl?: string;
};

export type Option = {
  label: string;
  value: string;
};

export type ExpenseFilter = {
  category: string | null;
  status: string | null;
  year: string | null;
};

export const emptyExpenseFilter: ExpenseFilter = {
  category: null,
  status: null,
  year: null,
};
