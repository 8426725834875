import React, { useMemo, useState } from 'react';
import { Column } from 'react-table';
import {
  Employee as EmployeeType,
  EmployeeAvatar as EmployeeAvatarType,
  EmployeeDataChangeRequest as EmployeeDataChangeRequestType,
  EmployeeDataChangeRequestStatus,
} from '../../../../__generated__/graphql';
import { TableContent } from '../../../components/TableContent';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import { Maybe } from '../../../../../../utils/maybe';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { StatusCircle } from '../../../../components/StatusCircle';
import { Theme } from '../../../../../../theme';
import CommentCount from '../../../../components/Comment/CommentCount/CommentCount';
import FormattedDateTime from '../../../../../../components/datetime/FormattedDateTime';
import { ChangeRequestsTableHeader } from './ChangeRequestsTableHeader';
import { ChangeRequestForm } from '../ChangeRequestForm';
import { ChangeRequestPreview } from './ChangeRequestPreview';
import { useSelectedItemWithComments } from '../../../../components/Comment';

type EmployeeDataChangeRequest = Pick<
  EmployeeDataChangeRequestType,
  | 'Id'
  | 'Name'
  | 'flair__Employee__c'
  | 'flair__Requested_At__c'
  | 'flair__Status__c'
  | 'LastModifiedDate'
  | 'commentsCount'
> & {
  employee: Maybe<
    {
      Id: EmployeeType['Id'];
      Name: EmployeeType['Name'];
    } & { avatar: Maybe<Pick<EmployeeAvatarType, 'Id' | 'url'>> }
  >;
};

type Props = {
  data: ReadonlyArray<EmployeeDataChangeRequest>;
  filter: string;
  pageSize: number;
  showAllRequests: boolean;
  onToggleShowAllRequests: () => void;
};

export type DataChangeRequestRow = {
  id: string;
  name: string;
  employee: {
    name: string;
    avatarUrl: string;
  };
  requestedDate: string | null;
  lastUpdatedDate: string;
  status: EmployeeDataChangeRequestStatus;
  commentsCount: number;
};

const transformData = (
  data: ReadonlyArray<EmployeeDataChangeRequest>,
): DataChangeRequestRow[] =>
  data.map((changeRequest) => ({
    id: changeRequest.Id,
    name: changeRequest.Name,
    employee: {
      name: changeRequest.employee?.Name || '',
      avatarUrl: changeRequest.employee?.avatar?.url || '',
    },
    requestedDate: changeRequest.flair__Requested_At__c,
    lastUpdatedDate: changeRequest.LastModifiedDate,
    status: changeRequest.flair__Status__c,
    commentsCount: changeRequest.commentsCount,
  }));

const recordType = 'flair__Employee__c';

const ChangeRequestsTable: React.FC<Props> = ({
  data,
  filter,
  pageSize,
  showAllRequests,
  onToggleShowAllRequests,
}) => {
  const t = useNamespacedTranslation('changeRequests');
  const { defaultSelectedItem } = useSelectedItemWithComments();

  const [showNewRequestModal, setShowNewRequestModal] = React.useState(false);

  const [previewRequestId, setPreviewRequestId] = useState<string | null>(
    defaultSelectedItem?.id ?? null,
  );
  const [showComments, setShowComments] = useState(
    defaultSelectedItem?.showComments ?? false,
  );

  const previewRecordId = useMemo(() => {
    if (previewRequestId) {
      return data.find((request) => request.Id === previewRequestId)?.[
        recordType
      ];
    }
    return null;
  }, [previewRequestId, data]);

  const onItemClick = (row: DataChangeRequestRow) => {
    setShowComments(false);
    setPreviewRequestId(row.id);
  };

  const onCommentsClick = (row: { id: string }) => {
    setShowComments(true);
    setPreviewRequestId(row.id);
  };

  const columns: Column<DataChangeRequestRow>[] = useMemo(
    () => [
      {
        Header: () => t('table.header.name'),
        accessor: 'name',
        Cell: ({ value, row }) => (
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => onItemClick(row.original)}>
            {value}
          </button>
        ),
      },
      {
        Header: () => t('table.header.employee'),
        accessor: 'employee',
        Cell: (props) => {
          return (
            <EmployeeWithAvatar employee={props.value}></EmployeeWithAvatar>
          );
        },
      },
      {
        Header: () => t('table.header.requestedAt'),
        accessor: 'requestedDate',
        Cell: (props) =>
          props.value ? <FormattedDateTime dateTime={props.value} /> : <></>,
      },
      {
        Header: () => t('table.header.lastUpdate'),
        accessor: 'lastUpdatedDate',
        Cell: (props) => <FormattedDateTime dateTime={props.value} />,
      },
      {
        Header: () => t('table.header.status'),
        accessor: 'status',
        Cell: (props) => <ChangeRequestStatusCell status={props.value} />,
      },
      {
        Header: () => t('table.header.comments'),
        accessor: 'commentsCount',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <CommentCount
            count={value}
            onClick={() => onCommentsClick({ id: row.original.id })}
          />
        ),
      },
    ],
    [t],
  );

  const dataMemo = useMemo(() => transformData(data), [data]);

  return (
    <>
      <ChangeRequestsTableHeader
        data={dataMemo}
        showAllRequests={showAllRequests}
        onToggleShowAllRequests={onToggleShowAllRequests}
        onNewRequest={() => setShowNewRequestModal(true)}
      />
      <TableContent
        columns={columns}
        data={dataMemo}
        pageSize={pageSize}
        filter={filter}
      />
      <ChangeRequestForm
        show={showNewRequestModal}
        recordType={recordType}
        showAllRequests={showAllRequests}
        onHide={() => setShowNewRequestModal(false)}
      />
      {previewRequestId && previewRecordId && (
        <ChangeRequestPreview
          show={true}
          onHide={() => {
            setPreviewRequestId(null);
            setShowComments(false);
          }}
          requestId={previewRequestId}
          recordId={previewRecordId}
          recordType={recordType}
          showItemComments={showComments}
        />
      )}
    </>
  );
};

const ChangeRequestStatusCell = (props: {
  status: EmployeeDataChangeRequestStatus;
}) => {
  const t = useNamespacedTranslation('changeRequests.table.status');
  const getItemStatusColor = (status: EmployeeDataChangeRequestStatus) =>
    Theme.changeRequest.status[status].color;
  return (
    <div className="d-flex flex-row align-items-center gap-sm-2">
      <StatusCircle color={getItemStatusColor(props.status)} size={5} />
      <span>{t(`${props.status}`)}</span>
    </div>
  );
};

export default ChangeRequestsTable;
