import React from 'react';
import { Theme } from '../../../../../../../theme';

type StepIndicatorProps = {
  totalSteps: number;
  currentStep: number;
};

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  totalSteps,
  currentStep,
}) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex gap-1">
        {Array.from({ length: totalSteps }, (_, index) => (
          <div
            key={index}
            className="rounded-pill"
            style={{
              width: index + 1 === currentStep ? '1.5rem' : '0.5rem',
              height: '0.5rem',
              backgroundColor:
                index + 1 === currentStep
                  ? Theme.color.blue5
                  : Theme.color.blue6,
            }}
          />
        ))}
      </div>
    </div>
  );
};
