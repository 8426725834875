import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Column, CellProps } from 'react-table';
import { Card, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { TableContent } from '../../components/TableContent';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  TYPE_FILTER,
  COLLEAGUES_IDS_FILTER,
  AbsenceRequestFiltersType,
  AbsenceTypeMap,
} from './types';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import Avatar from '../../../pages/Absences/components/Avatar';
import AbsenceDateTimeRange from '../../../pages/Absences/components/AbsenceDateTimeRange';
import Filters from './Filters';
import { useAbsenceRequestFilters } from './Filters/useAbsenceRequestFilters';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useApproveAbsenceRequestTable } from '../useAbsencesRequestsTable';
import { ApproverCommentModal } from '../../../pages/Absences/components/ApproverCommentModal';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { LoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';
import { IndeterminateCheckbox } from '../../../components/Checkbox/IndeterminateCheckbox';
import { TableBottomBanner } from './TableBottomBanner';
import { SelectionBottomPanel } from '../../../components/SelectionBottomPanel/SelectionBottomPanel';
import { AbsenceRequest } from '../types';
import { EmptyAbsenceRequestsCard } from '../EmptyAbsenceRequestsCard';
import { MOBILE_BREAKPOINT } from '../common';
import { ReviewActionsCell } from '../ReviewActionsCell';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTable,
  ExtraTableRenderSettings,
} from '../../../components/ExtraFields';
import { useAbsenceRequestTableRenderSettings } from '../useAbsenceRequestTableRenderSettings';
import { ExtraFieldsTableId } from '../../../components/ExtraFields/types';

const Detail = styled.div({
  marginLeft: 32,
});

const Header = styled(Card.Header)({
  height: 'auto',
  minHeight: 60,
});

const i18Path = 'absences.myAbsences.table';

type Props = {
  requests: AbsenceRequest[];
};

const getQueryFiltersData = (
  query: URLSearchParams,
): AbsenceRequestFiltersType => {
  return {
    type: query.get(TYPE_FILTER) || '',
    colleaguesIds: query.get(COLLEAGUES_IDS_FILTER) || '',
  };
};

export const AbsenceRequestsTable: React.FC<Props> = ({ requests }) => {
  const t = useNamespacedTranslation(i18Path);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filterDataFromUrl = getQueryFiltersData(query);
  const filteredAbsences = useAbsenceRequestFilters(
    filterDataFromUrl,
    requests,
  );
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const {
    isAccepting,
    isRejecting,
    isInProgress,
    handleAccept,
    handleReject,
    handleMassAccept,
    handleMassReject,
    handleRejectButtonClick,
    onModalClose,
    rejectingModalId,
    getSelectedRowsCount,
    addSelectedRow,
    resetSelectedRows,
    selectAllRows,
    setSelectAllRows,
    setResetSelectedRows,
  } = useApproveAbsenceRequestTable();

  const { columns } = useAbsenceRequestTableRenderSettings({
    onLoomVideoClick: setSelectedLoomVideo,
  });

  const reviewCell = useCallback(
    ({ row }: CellProps<AbsenceRequest>) => {
      if (row.original.approvalStatus === 'PENDING') {
        return (
          <ReviewActionsCell
            id={row.original.id}
            isAccepting={isAccepting}
            isRejecting={isRejecting}
            isInProgress={isInProgress}
            handleAccept={handleAccept}
            handleRejectButtonClick={handleRejectButtonClick}
          />
        );
      } else {
        const reviewedRequest = row.original.approvalRequests.find((item) =>
          ['APPROVED', 'REJECTED'].includes(item.approvalStatus),
        );
        const avatar = reviewedRequest?.approver.avatar;
        const firstName = reviewedRequest?.approver.firstName;
        const lastName = reviewedRequest?.approver.lastName;
        const initials = `${firstName?.[0]}${lastName?.[0]}`;
        const fullName = reviewedRequest?.approver.name;
        return (
          <div className="d-flex align-items-center gap-2 ps-3">
            <Avatar
              avatar={avatar}
              initials={initials}
              fullName={fullName || ''}
            />
            <FormattedDate day={row.original.lastModifiedDate} format="short" />
          </div>
        );
      }
    },
    [
      isAccepting,
      isRejecting,
      isInProgress,
      handleAccept,
      handleRejectButtonClick,
    ],
  );

  const renderSettings: ExtraTableRenderSettings<AbsenceRequest> = useMemo(
    () => ({
      columns,
      beforePersistentColumns: {
        SelectionColumn: {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: (
            props: React.PropsWithChildren<CellProps<AbsenceRequest, string>>,
          ) => (
            <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
          ),
          disableSortBy: true,
        },
      },
      afterPersistentColumns: {
        ActionColumn: {
          Header: t('review'),
          Cell: reviewCell,
        },
      },
      responsibleMobileColumns: {},
    }),
    [columns, reviewCell, t],
  );

  const mobileColumns: Column<AbsenceRequest>[] = useMemo(
    () => [
      {
        Header: t('employee'),
        accessor: 'id',
        Cell: (props: CellProps<AbsenceRequest>) => (
          <Col>
            <Link
              to={routes.absenceRequestToMe.route}
              absenceRequestId={props.row.original.id}>
              <EmployeeWithAvatar employee={props.row.original.requester} />
            </Link>
            <Detail>
              <Link
                to={routes.absenceRequestToMe.route}
                absenceRequestId={props.row.original.id}>
                <AbsenceDateTimeRange absence={props.row.original} />
              </Link>
              <div>
                <span className="me-1">{t('mobile.workingTime')}</span>
                {`${props.row.original.workingAmount} ${
                  AbsenceTypeMap[props.row.original.type]
                }`}
              </div>
              <div>
                <span className="me-1">{t('mobile.type')}</span>
                {props.row.original.categoryName}
              </div>
              <div>
                <span className="me-1">{t('mobile.requestedAt')}</span>
                <FormattedDateTime
                  dateTime={props.row.original.createdDate}
                  format="long"
                />
              </div>
            </Detail>
          </Col>
        ),
      },
      {
        Header: t('review'),
        accessor: 'absenceId',
        Cell: reviewCell,
      },
    ],
    [t, reviewCell],
  );

  const renderTable = () => {
    if (!isMobile) {
      return (
        <ExtraFieldsTable
          objectApiName={ExtraFieldsSupportedObject.Absence}
          renderSettings={renderSettings}
          tableId={ExtraFieldsTableId.AbsencesManager}
          records={filteredAbsences}
          selectAllRows={selectAllRows}
          resetSelectedRows={resetSelectedRows}
          onRowSelection={addSelectedRow}
          onRowClick={() => {}}
          pageSize={50}
          initialSortBy={[{ id: 'createdDate', desc: true }]}
        />
      );
    } else {
      return (
        <TableContent
          data={filteredAbsences}
          columns={mobileColumns}
          selectAllRows={selectAllRows}
          resetSelectedRows={resetSelectedRows}
          onRowSelection={addSelectedRow}
          onRowClick={() => {}}
          pageSize={50}
          initialSortBy={[{ id: 'createdDate', desc: true }]}
        />
      );
    }
  };

  return (
    <Card>
      <Header className="h-auto">
        <Row className="align-items-center">
          <Col className="small">
            <Filters data={requests} filterData={filterDataFromUrl} />
          </Col>
        </Row>
      </Header>
      {filteredAbsences.length > 0 ? (
        <>
          {renderTable()}
          <ApproverCommentModal
            requestId={rejectingModalId}
            onClose={onModalClose}
            onRejected={handleReject}
          />
          <SelectionBottomPanel
            selectedCount={getSelectedRowsCount()}
            onSelectAll={() => setSelectAllRows(true)}
            onCancel={() => {
              setResetSelectedRows(true);
            }}>
            <TableBottomBanner
              onAccept={handleMassAccept}
              onReject={handleMassReject}></TableBottomBanner>
          </SelectionBottomPanel>
        </>
      ) : (
        <EmptyAbsenceRequestsCard />
      )}
      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}
    </Card>
  );
};
