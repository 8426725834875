import React from 'react';
import { FieldInput } from '../../EmployeeData';
import { getExtraFieldInputName, recordTypeFormKey } from '../logic';
import {
  AvailableRecordTypeFragment,
  FieldType,
  ObjectExtraFieldsFragment,
} from '../../../__generated__/graphql';
import Skeleton from 'react-loading-skeleton';
import { useFormContext } from 'react-hook-form';
import { ExtraFieldsVisibility } from '../extraFieldsVisibility';
import i18next from 'i18next';
import { ExtraFieldLookup } from '../ExtraFieldLookup/ExtraFieldLookup';
import { ExtraFieldContextParam, ExtraFieldsFormId } from '../types';
import { toHtmlInputStepAttribute } from './helpers';

type Props = {
  objectExtraFields: ObjectExtraFieldsFragment | undefined;
  contextParams?: ExtraFieldContextParam[];
  formId: ExtraFieldsFormId;
};

export const ExtraFieldsAreaContent: React.FC<Props> = ({
  objectExtraFields,
  contextParams,
  formId,
}) => {
  const { watch } = useFormContext();
  const recordTypeName = watch(recordTypeFormKey);

  if (!objectExtraFields) {
    return null;
  }
  const visibility = new ExtraFieldsVisibility(objectExtraFields);

  const { form: fields, availableRecordTypes } = objectExtraFields;

  return (
    <>
      {getRecordTypeFormControl(availableRecordTypes)}
      {fields
        .filter((f) =>
          visibility.isFieldVisible(f, {
            recordTypeName,
            contextParams: contextParams ?? [],
            formId,
          }),
        )
        .map(
          ({ fieldApiName, fieldInfo, required, id, visibleLines }, index) => {
            const inputFieldInfo = {
              name: getExtraFieldInputName(fieldApiName, fieldInfo.type),
              label: fieldInfo.label,
              type: fieldInfo.type,
              hint: fieldInfo.inlineHelpText ?? undefined,
              disabled: false,
              minRows: visibleLines ?? undefined,
              step: toHtmlInputStepAttribute(fieldInfo.decimalPlaces),
              required,
              options:
                fieldInfo.picklistValues?.map((pv) => ({
                  label: pv.label,
                  value: pv.value,
                })) || [],
            };
            if (fieldInfo.type === 'REFERENCE') {
              return (
                <ExtraFieldLookup
                  key={index}
                  fieldId={id}
                  contextParams={contextParams}
                  fieldInfo={inputFieldInfo}
                />
              );
            }
            return <FieldInput key={index} fieldInfo={inputFieldInfo} />;
          },
        )}
    </>
  );
};

export const Loading: React.FC = () => (
  <div className="my-3">
    <Skeleton height={40} className="mt-3" />
    <Skeleton height={40} className="mt-3" />
    <Skeleton height={40} className="mt-3" />
  </div>
);

export function getRecordTypeFormControl(
  availableRecordTypes: readonly AvailableRecordTypeFragment[],
) {
  if (availableRecordTypes.length === 0) {
    return null;
  }
  const dropDownfieldInfo = {
    name: recordTypeFormKey,
    label: i18next.t('extraFields.recordType'),
    type: FieldType.Picklist,
    disabled: availableRecordTypes.length === 1,
    required: true,
    doNotInsertDefaultOptions: true,
    options: availableRecordTypes.map((rt) => ({
      label: rt.Label,
      value: rt.Name,
    })),
  };
  return <FieldInput fieldInfo={dropDownfieldInfo} />;
}
