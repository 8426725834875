import {
  AnnouncementEmojiFragment,
  AnnouncementFragment,
  AnnouncementQuery,
  useCreateAnnouncementEmojiMutation,
  useDeleteAnnouncementEmojiMutation,
} from '../../../__generated__/graphql';
import { AnnouncementType } from './types';
import { groupBy, orderBy } from 'lodash';
import { Emoji, EMOJI_OPERATIONS } from '../../../components/Announcement';
import { useCallback, useState } from 'react';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useUserInfo } from '../../../context/UserInfo';

export const mapAnnouncements = (
  announcements?: AnnouncementQuery['announcements'],
): AnnouncementType[] => {
  if (!announcements) {
    return [];
  }
  return orderBy(
    announcements.map(mapAnnouncement),
    (item) => item.createdDate,
    'desc',
  );
};

export const searchItems = (
  announcements: AnnouncementType[],
  searchText: string,
): AnnouncementType[] => {
  if (searchText === '') {
    return announcements;
  }
  return announcements.filter((a) =>
    a.title.toLowerCase().includes(searchText.toLowerCase()),
  );
};

export const mapAnnouncement = (a: AnnouncementFragment): AnnouncementType => {
  const emojis = groupBy(a.emojis.map(mapEmoji), 'name');
  return {
    id: a.Id,
    title: a.Name,
    createdDate: new Date(a.CreatedDate),
    publishedDate: a.flair__Published_Date__c
      ? new Date(a.flair__Published_Date__c)
      : null,
    body: a.flair__Announcement_Text__c ?? '',
    emojis,
    employee: a.employee
      ? {
          name: a.employee.Name,
          avatarUrl: a.employee.avatar?.url ?? '',
        }
      : null,
    commentsCount: a.commentsCount,
  };
};

const mapEmoji = (src: AnnouncementEmojiFragment): Emoji => ({
  id: src.Id,
  name: src.Name,
  recordId: src.flair__Company_Announcement__c,
  employee: {
    id: src.employee.Id,
    name: src.employee.Name,
  },
});

export const useEmojiChange = (announcements: AnnouncementType[]) => {
  const { id: employeeId } = useUserInfo();
  const [createEmoji] = useCreateAnnouncementEmojiMutation();
  const [deleteEmoji] = useDeleteAnnouncementEmojiMutation();
  const errorHandler = useMutationErrorHandler();
  const [updatingItems, setUpdatingItems] = useState<string[]>([]);

  const onEmojiChange = useCallback(
    (data: Pick<any, 'name' | 'recordId'>, operation: string) => {
      const announcementId = data.recordId;
      const emojis = announcements.find((a) => a.id === announcementId)?.emojis;

      const emoji = emojis?.[data.name]?.find(
        (e) => e.name === data.name && e.employee.id === employeeId,
      );

      if (emojis) {
        switch (operation) {
          case EMOJI_OPERATIONS.CREATE:
            // If emoji is not selected before
            if (!emoji) {
              setUpdatingItems((prev) => [...prev, announcementId]);
              createEmoji({
                variables: {
                  input: {
                    name: data.name,
                    announcementId,
                    employeeId: employeeId,
                  },
                },
              })
                .catch(errorHandler)
                .finally(() => {
                  setUpdatingItems((prev) =>
                    prev.filter((x) => x !== announcementId),
                  );
                });
            }
            break;
          case EMOJI_OPERATIONS.DELETE:
            if (emoji) {
              setUpdatingItems((prev) => [...prev, announcementId]);
              deleteEmoji({
                variables: {
                  input: { id: emoji.id },
                },
              })
                .catch(errorHandler)
                .finally(() => {
                  setUpdatingItems((prev) =>
                    prev.filter((x) => x !== announcementId),
                  );
                });
            }
            break;
        }
      }
    },
    [announcements, createEmoji, deleteEmoji, employeeId, errorHandler],
  );

  return { onEmojiChange, updatingItems };
};
