import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedDateTime from '../../../../components/datetime/FormattedDateTime';
import { AbsenceApprovalRequestToMeQuery } from '../../__generated__/graphql';
import AbsenceAmount from '../Absences/components/AbsenceAmount';
import AbsenceDateTime from '../Absences/components/AbsenceDateTime';
import { mapDocumentFile } from '../Absences/mappings';
import { LoomVideoPreview, mapLoomVideo } from '../../components/LoomVideo';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsViewArea,
} from '../../components/ExtraFields';
import { ExtraFieldsValueToRender } from '../../components/ExtraFields/ExtraFieldsViewArea/ExtraFieldsViewAreaContent';
import { FieldValue } from '../../components/EmployeeData/FieldValue';
import { ExtraFieldsFormId } from '../../components/ExtraFields/types';

type Props = {
  absence: AbsenceApprovalRequestToMeQuery['me']['absenceRequestToMe']['absence'];
};

const Detail: React.FC<Props> = ({ absence }) => {
  const { t } = useTranslation();
  if (
    !absence.employeeAbsenceCategory ||
    !absence.approvalRequests ||
    !absence.CreatedDate
  ) {
    // not allow to see sensitive data
    return null;
  }
  const availableAmount =
    absence.employeeAbsenceCategory.allowancesSummary.availableAmount;
  const rejectedRequest = absence.approvalRequests.find(
    (request) => request.flair__Approval_Status__c === 'REJECTED',
  );
  const hasRejectionComment =
    rejectedRequest && rejectedRequest.flair__Approver_Comment__c;

  const attachments =
    absence.ContentDocumentLinks__r?.map(mapDocumentFile) ?? [];
  const attachment = attachments.length > 0 ? attachments[0] : null;

  const loomVideo = mapLoomVideo(absence.loomVideo);

  const renderItems = (items: ExtraFieldsValueToRender[]) => {
    return (
      <>
        {items.map((item, index) => (
          <ListGroup.Item key={item.fieldApiName}>
            <Row className="align-items-center">
              <Col>
                <b>{item.label}</b>
              </Col>
              <Col className="col-auto">
                <FieldValue
                  type={item.fieldType}
                  options={[]}
                  value={item.inputValue}
                />
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </>
    );
  };

  return (
    <Card>
      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.employee')}</b>
              </Col>
              <Col className="col-auto">{absence.employee.Name}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.from')}</b>
              </Col>
              <Col className="col-auto">
                <AbsenceDateTime
                  date={absence.flair__Start_Date__c}
                  time={absence.flair__Start_Time__c}
                  type={absence.flair__Type__c}
                  halfDay={absence.flair__Start_Date_Half_Day__c}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.to')}</b>
              </Col>
              <Col className="col-auto">
                <AbsenceDateTime
                  date={absence.flair__End_Date__c}
                  time={absence.flair__End_Time__c}
                  type={absence.flair__Type__c}
                  halfDay={absence.flair__End_Date_Half_Day__c}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.amount')}</b>
              </Col>
              <Col className="col-auto">
                <AbsenceAmount
                  type={absence.flair__Type__c}
                  amount={absence.flair__Working_Amount__c}
                  variant="work"
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.category')}</b>
              </Col>
              <Col className="col-auto">{absence.flair__Category_Name__c}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.remainingAmount')}</b>
              </Col>
              <Col className="col-auto">
                <AbsenceAmount
                  type={absence.flair__Type__c}
                  amount={availableAmount}
                  variant="work"
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.requestedAt')}</b>
              </Col>
              <Col className="col-auto">
                <FormattedDateTime
                  dateTime={absence.CreatedDate}
                  format="long"
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.comment')}</b>
              </Col>
              <Col className="col-auto">
                {absence.flair__Comment__c ? (
                  <q>{absence.flair__Comment__c}</q>
                ) : (
                  <span className="text-muted">
                    {t('general.notAvailable')}
                  </span>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col>
                <b>{t('absenceRequest.fields.reviewerComment')}</b>
              </Col>
              <Col className="col-auto">
                {hasRejectionComment ? (
                  <q>{rejectedRequest.flair__Approver_Comment__c}</q>
                ) : (
                  <span className="text-muted">
                    {t('general.notAvailable')}
                  </span>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
          {attachment && (
            <ListGroup.Item>
              <Row className="align-items-center">
                <Col>
                  <b>{t('absenceRequest.fields.attachment')}</b>
                </Col>
                <Col className="col-auto">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={attachment.downloadPublicUrl}>
                    {attachment.fileName}
                  </a>
                </Col>
              </Row>
            </ListGroup.Item>
          )}
          {loomVideo && (
            <ListGroup.Item>
              <Row className="align-items-center">
                <Col>
                  <b>{t('absenceRequest.fields.loom')}</b>
                </Col>
                <Col className="col-auto">
                  <LoomVideoPreview
                    loomVideo={loomVideo}
                    previewWidth={153}
                    previewHeight={100}
                  />
                </Col>
              </Row>
            </ListGroup.Item>
          )}
          <ExtraFieldsViewArea
            objectApiName={ExtraFieldsSupportedObject.Absence}
            formId={ExtraFieldsFormId.AbsencesManagerDetails}
            recordId={absence.Id}
            renderItems={renderItems}
          />
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Detail;
