import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Expense, ExpenseStatusInfo } from './types';
import { ExpenseStatusCell } from './Common/ExpenseStatusCell';
import { EmptyExpenses } from './EmptyExpenses';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  Item,
  useExpenseTableRenderSettings,
} from './useExpenseTableRenderSettings';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTable,
  ExtraTableRenderSettings,
} from '../../components/ExtraFields';
import { ExtraFieldsTableId } from '../../components/ExtraFields/types';
import { mapExpenseStatus } from './mappings';
import { ExpenseStatus } from '../../__generated__/graphql';

type Props = {
  onItemClick: (item: Maybe<Item>) => void;
  expenses: Expense[];
  updatingItems: ExpenseStatusInfo[];
  onStatusChange: (info: ExpenseStatusInfo) => void;
};

type ExpenseWithUpdatingStatus = Expense & {
  updatingToStatus: ExpenseStatus | null;
};

export const ExpensesTable: React.FC<Props> = ({
  onItemClick,
  expenses,
  updatingItems,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const items: ExpenseWithUpdatingStatus[] = expenses.map((x) =>
    enrichWithUpdatingStatus(x, updatingItems),
  );

  const { columns } = useExpenseTableRenderSettings({
    onItemClick,
  });

  const renderSettings: ExtraTableRenderSettings<ExpenseWithUpdatingStatus> =
    useMemo(
      () => ({
        columns: {
          ...columns,
          flair__Status__c: {
            Header: () => (
              <Trans t={t} i18nKey="expenses.table.header.status" />
            ),
            Cell: ({ row }) => {
              const readonly = false;
              const id = row.original.id;

              const statusInfo: ExpenseStatusInfo = {
                expenseId: id,
                status: mapExpenseStatus({
                  flair__Status__c: row.original.flair__Status__c,
                  flair__Reimbursed__c: row.original.flair__Reimbursed__c,
                }),
                declineReason: null,
              };
              // todo: map original fields to extra table item itself?!
              const updatingToStatus = row.original.updatingToStatus;
              return (
                <ExpenseStatusCell
                  value={statusInfo}
                  readonly={readonly}
                  onChange={onStatusChange}
                  updatingToStatus={updatingToStatus}
                />
              );
            },
          },
        },
      }),
      [columns, t, onStatusChange],
    );

  if (!expenses.length) {
    return <EmptyExpenses />;
  }

  return (
    <ExtraFieldsTable
      records={items}
      objectApiName={ExtraFieldsSupportedObject.Expense}
      renderSettings={renderSettings}
      tableId={ExtraFieldsTableId.ExpensesManager}
    />
  );
};

function enrichWithUpdatingStatus<T extends { id: string }>(
  src: T,
  updatingItems: ExpenseStatusInfo[],
): T & { updatingToStatus: ExpenseStatus | null } {
  const updatingToStatus =
    updatingItems.find((x) => x.expenseId === src.id)?.status ?? null;
  return {
    ...src,
    updatingToStatus,
  };
}
