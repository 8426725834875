export type VisibilitySchemaModel =
  | { $and?: VisibilitySchemaModel[] }
  | { $or?: VisibilitySchemaModel[] }
  | {
      [key: string]: VisibilitySchemaModelNode;
    };

export type VisibilitySchemaModelNode =
  | VisibilitySchemaModelEqualNode
  | VisibilitySchemaModelNotEqualNode;

export type VisibilitySchemaModelEqualNode = {
  type: '$eq';
  value: string[];
};

export type VisibilitySchemaModelNotEqualNode = {
  type: '$ne';
  value: string[];
};

export function parseVisibilitySchemaModel(
  jsonModel: string,
): VisibilitySchemaModel {
  const parsed = JSON.parse(jsonModel);
  return parseModelNode(parsed);
}

function parseModelNode(node: any): VisibilitySchemaModel {
  // Handle $and operator
  if ('$and' in node) {
    if (!Array.isArray(node.$and)) {
      throw new Error(`$and value should be an array: ${JSON.stringify(node)}`);
    }
    if (node.$and.length === 0) {
      throw new Error(
        `$and value should not be empty: ${JSON.stringify(node)}`,
      );
    }
    return {
      $and: node.$and.map((subNode: any) => parseModelNode(subNode)),
    };
  }

  // Handle $or operator
  if ('$or' in node) {
    if (!Array.isArray(node.$or)) {
      throw new Error(`$or value should be an array: ${JSON.stringify(node)}`);
    }
    if (node.$or.length === 0) {
      throw new Error(`$or value should not be empty: ${JSON.stringify(node)}`);
    }
    return {
      $or: node.$or.map((subNode: any) => parseModelNode(subNode)),
    };
  }

  // Handle leaf nodes
  const result: { [key: string]: VisibilitySchemaModelNode } = {};

  for (const [key, value] of Object.entries(node)) {
    if (typeof value === 'string') {
      // Single string becomes array for consistency
      result[key] = { type: '$eq', value: [value] };
    } else if (Array.isArray(value)) {
      result[key] = { type: '$eq', value: value };
    } else if (typeof value === 'object' && value !== null) {
      if ('$ne' in value) {
        const neValue = Array.isArray(value.$ne) ? value.$ne : [value.$ne];
        result[key] = { type: '$ne', value: neValue };
      } else if ('$eq' in value) {
        const eqValue = Array.isArray(value.$eq) ? value.$eq : [value.$eq];
        result[key] = { type: '$eq', value: eqValue };
      } else {
        throw new Error(`Invalid model node: ${JSON.stringify(value)}`);
      }
    } else {
      throw new Error(`Invalid model value type: ${typeof value}`);
    }
  }

  return result;
}
