import React, { useState, useMemo } from 'react';
import {
  Stack,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Form } from '../../../../../../components/form/Form';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { StepIndicator } from './components/StepIndicator';
import Skeleton from 'react-loading-skeleton';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

export type StepOneData = {
  requestForm: string | null;
};

type StepOneProps = {
  requestForms: ReadonlyArray<{
    Id: string;
    Name: string;
    flair__Information__c: string | null;
  }>;
  onSubmit: (data: StepOneData) => void;
  onCancel: () => void;
  initialData?: StepOneData;
  loading: boolean;
};

export const StepOne: React.FC<StepOneProps> = ({
  requestForms,
  onSubmit,
  onCancel,
  initialData,
  loading,
}) => {
  const t = useNamespacedTranslation('changeRequests.requestForm');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFormId, setSelectedFormId] = useState<string | null>(
    initialData?.requestForm || null,
  );

  const filteredForms = useMemo(() => {
    return requestForms.filter((form) =>
      form.Name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [requestForms, searchTerm]);

  const handleSubmit = () => {
    if (selectedFormId) {
      onSubmit({
        requestForm: selectedFormId,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={3}>
        <div className="ps-3 mb-3 input-group-flush border rounded d-flex gap-3 input-group-merge">
          <InputGroup.Text className="d-flex align-items-center p-0">
            <FlairIcon icon="search-filled-new" />
          </InputGroup.Text>
          <input
            className="list-search form-control"
            type="search"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={t('requestForm.search')}
          />
        </div>

        <Stack gap={2}>
          {loading ? (
            <LoadingRequestForms />
          ) : (
            filteredForms.map((form) => (
              <OverlayTrigger
                key={form.Id}
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-${form.Id}`}>
                    {form.flair__Information__c}
                  </Tooltip>
                }>
                <div>
                  <Button
                    label={form.Name}
                    variant={
                      selectedFormId === form.Id ? 'primary' : 'outline-primary'
                    }
                    className="text-start p-3 w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedFormId(form.Id);
                    }}
                  />
                </div>
              </OverlayTrigger>
            ))
          )}
        </Stack>

        <Row className="align-items-center mt-3">
          <Col>
            <Button
              variant="outline-primary"
              label={t('buttons.cancel')}
              onClick={onCancel}
            />
          </Col>
          <Col className="text-center">
            <StepIndicator totalSteps={3} currentStep={1} />
          </Col>
          <Col className="text-end">
            <Button
              type="submit"
              variant="primary"
              label={t('buttons.next')}
              disabled={!selectedFormId}
            />
          </Col>
        </Row>
      </Stack>
    </Form>
  );
};

const LoadingRequestForms = () => {
  return (
    <Stack gap={2}>
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </Stack>
  );
};
