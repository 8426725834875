import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import RedirectToHome from '../../components/RedirectToHome';
import { routes } from './routes';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { MyTeamNav } from './MyTeamNav';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

export const MyTeamTabs: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.people');

  useFlairBreadcrumbHook([{ label: t('title') }]);

  return (
    <>
      <PageHeader title={t('title')}>
        <MyTeamNav />
      </PageHeader>

      <Content />
    </>
  );
};
