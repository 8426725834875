import React from 'react';
import PageHeaderNav, { TabItem } from '../../components/PageHeaderNav';
import { useUserInfo } from '../../context/UserInfo';
import { routes } from '../../routes';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

export const MyTeamNav: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.people.tabs');
  const userInfo = useUserInfo();

  const activeTabs: (TabItem | null)[] = userInfo.isActive
    ? [
        {
          label: t('overview'),
          route: routes.myTeamOverview.route,
        },
        {
          label: t('changeRequests'),
          route: routes.myTeamChangeRequests.route,
        },
      ]
    : [];

  return (
    <PageHeaderNav
      tabItems={activeTabs.filter((x): x is TabItem => x !== null)}
    />
  );
};
