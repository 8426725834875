import { useCallback } from 'react';
import { useToasts } from '../context/Toast';
import {
  addErrorMetadataToMessage,
  ERROR_AUTO_DISMISS_TIMEOUT_SECONDS,
  useSystemErrorMessageHandler,
} from './useMutationErrorHandler';
import { ApolloError } from '@apollo/client';

export const useErrorHandler = () => {
  const { addError } = useToasts();
  const defaultMessageHandler = useSystemErrorMessageHandler();

  const errorHandler = useCallback(
    (error: ApolloError) => {
      let defaultMessage = defaultMessageHandler(error);

      addError(addErrorMetadataToMessage(null, defaultMessage, error), {
        autoDismissTimeout: ERROR_AUTO_DISMISS_TIMEOUT_SECONDS * 1000,
      });
    },
    [addError, defaultMessageHandler],
  );

  return errorHandler;
};
