import {
  CommentRelatedAbsenceFragment,
  CommentRelatedEmployeeDocumentFragment,
  CommentRelatedInventoryItemFragment,
  CommentRelatedTimeSheetDayFragment,
  CommentRelatedWorkflowItemFragment,
  CommentWithDeepLinkDataFragment,
  WorkloadTrackingType,
} from '../../../__generated__/graphql';
import { routes } from '../../../routes';
import { createShowCommentQueryParams } from '../../../components/Comment';
import { getWorkloadForDate } from '../../../utils/timesheetHelper';
import { createTimeSheetPageQueryParams } from '../../TimeTracking/MyTimeSheet';
import { createDocumentsPathQueryParams } from '../../Documents2';
import { createProjectTimesheetsQueryParams } from '../../Projects/ProjectsTimesheets';
import { createTicketQueryParams } from '../../Support/Tickets/hooks/useTicketSidebar';

type CommentType = CommentWithDeepLinkDataFragment;

export function createCommentDeepLinkRoute(meId: string, comment: CommentType) {
  const related = comment?.related;
  if (!related) {
    return null;
  }
  const isManager = comment.employee.flair__Manager__c === meId;
  // From packages/server/src/apps/internal/services/commentService.ts
  switch (related.__typename) {
    case 'WorkflowItem':
      return workflowRoute(comment, related);
    case 'Absence':
      return absenceRoute(comment, related, meId, isManager);
    case 'TimeSheetDay':
      return timeSheetDayRoute(comment, related, isManager);
    case 'Expense':
      return expenseRoute(comment, related, isManager);
    case 'EmployeeDocument':
      return documentsRoute(comment, related, meId, isManager);
    case 'InventoryItem':
      return inventoryRoute(comment, related, isManager);
    case 'Ticket':
      return ticketRoute(comment, related, isManager);
    case 'EmployeeDataChangeRequest':
      return employeeDataChangeRequestRoute(comment, related);

    // Simple routes
    case 'EmployeeCertificate':
      return routes.myCertificates.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({});
    case 'CandidateEvaluation':
      // todo: Can be improved https://linear.app/flair/issue/CHR-614/candidate-evaluation-comment-deep-link-bug
      return routes.candidateDetails.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({
          candidateId: related.flair__Candidate__c,
        });
    case 'JobCandidate':
      return routes.candidateDetails.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({
          candidateId: related.Id,
        });
    case 'Celebration':
      // todo: https://linear.app/flair/issue/CHR-615/cheers-and-announcement-commenting-deep-link-bug
      // We have the infinity scrollong in cheers
      // So if the target cheer doesn't shown at the first load,
      // then the comments panel will not be scrolled to the target cheer
      return routes.celebrationsFeed.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({});
    // todo: https://linear.app/flair/issue/CHR-615/cheers-and-announcement-commenting-deep-link-bug
    // We have the infinity scrollong in cheers
    // So if the target cheer doesn't shown at the first load,
    // then the comments panel will not be scrolled to the target cheer
    case 'Announcement':
      return routes.announcements.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({});
  }
  return null;
}

function workflowRoute(
  comment: CommentType,
  related: CommentRelatedWorkflowItemFragment,
) {
  if (!related) {
    return null;
  }
  return routes.workflowsAllWorkflowItems.route
    .withQueryParams(createQueryParams(comment.Id, related.Id))
    .create({
      workflowId: related.flair__Workflow__c,
    });
}

function absenceRoute(
  comment: CommentType,
  related: CommentRelatedAbsenceFragment,
  meId: string,
  isManager: boolean,
) {
  if (!related) {
    return null;
  }
  if (isManager) {
    const absenceRequestToMe = related.approvalRequests?.find(
      (x) => x.flair__Approver__c === meId,
    );
    if (absenceRequestToMe) {
      return routes.absenceRequestToMe.route
        .withQueryParams(createQueryParams(comment.Id, related.Id))
        .create({
          absenceRequestId: absenceRequestToMe.Id,
        });
    }
  } else if (related.flair__EmployeeId__c === meId) {
    return routes.myAbsenceDetail.route
      .withQueryParams(createQueryParams(comment.Id, related.Id))
      .create({
        absenceId: related.Id,
      });
  }
  return null;
}

function timeSheetDayRoute(
  comment: CommentType,
  related: CommentRelatedTimeSheetDayFragment,
  isManager: boolean,
) {
  const onlyProjectTrackingWorkload =
    isOnlyProjectTrackingEmployeeWorkload(related);

  const timeSheetId = related.timeSheet.Id;
  if (!related) {
    return null;
  }
  if (isManager) {
    // For manager we always redirect to time sheet details view (with time tracking)
    // It is because we don't have the routes.projectsTracking for manager role yet
    return routes.timeSheet.route
      .withQueryParams(createQueryParams(comment.Id, related.Id))
      .create({ timeSheetId });
  }
  if (!isManager && onlyProjectTrackingWorkload) {
    return routes.projectsTracking.route
      .withQueryParams({
        ...createProjectTimesheetsQueryParams(timeSheetId),
        ...createQueryParams(comment.Id, related.Id),
      })
      .create({});
  }
  if (!isManager && !onlyProjectTrackingWorkload) {
    return routes.timeTracking.route
      .withQueryParams({
        ...createTimeSheetPageQueryParams({
          startDate: related.timeSheet.flair__Start_Date__c,
          workloadType: related.timeSheet.flair__Type__c,
        }),
        ...createQueryParams(comment.Id, related.Id),
      })
      .create({});
  }
  return null;
}

function expenseRoute(
  comment: CommentType,
  related: CommentType['related'],
  isManager: boolean,
) {
  if (!related) {
    return null;
  }
  if (isManager) {
    return routes.expense.route
      .withQueryParams(createQueryParams(comment.Id, related.Id))
      .create({ id: related.Id });
  }
  return routes.myExpense.route
    .withQueryParams(createQueryParams(comment.Id, related.Id))
    .create({ id: related.Id });
}

function documentsRoute(
  comment: CommentType,
  related: CommentRelatedEmployeeDocumentFragment,
  meId: string,
  isManager: boolean,
) {
  const documentQueryParams = related.flair__Employee_Document_Folder__c
    ? createDocumentsPathQueryParams(related.flair__Employee_Document_Folder__c)
    : {};

  if (related.flair__Employee1__c === meId) {
    return routes.documentsPersonal.route
      .withQueryParams({
        ...documentQueryParams,
        ...createQueryParams(comment.Id, related.Id),
      })
      .create({});
  }

  if (isManager) {
    return routes.documents.route
      .withQueryParams({
        ...documentQueryParams,
        ...createQueryParams(comment.Id, related.Id),
      })
      .create({ employeeId: related.flair__Employee1__c });
  }
  return null;
}

function inventoryRoute(
  comment: CommentType,
  related: CommentRelatedInventoryItemFragment,
  isManager: boolean,
) {
  if (isManager) {
    const employeeId = related.flair__Employee__c;

    if (!employeeId) {
      return null;
    }
    return routes.employeeInventory.route
      .withQueryParams(createQueryParams(comment.Id, related.Id))
      .create({ employeeId });
  }
  return routes.inventory.route
    .withQueryParams(createQueryParams(comment.Id, related.Id))
    .create({});
}

function ticketRoute(
  comment: CommentType,
  related: CommentType['related'],
  isManager: boolean,
) {
  if (!related) {
    return null;
  }
  const ticketQueryParams = createTicketQueryParams(related.Id);

  if (isManager) {
    return routes.managerTickets.route
      .withQueryParams({
        ...ticketQueryParams,
        ...createQueryParams(comment.Id, related.Id),
      })
      .create({});
  }
  return routes.tickets.route
    .withQueryParams({
      ...ticketQueryParams,
      ...createQueryParams(comment.Id, related.Id),
    })
    .create({});
}

function employeeDataChangeRequestRoute(
  comment: CommentType,
  related: CommentType['related'],
) {
  if (!related) {
    return null;
  }

  return routes.myTeamChangeRequests.route
    .withQueryParams(createQueryParams(comment.Id, related.Id))
    .create({});
}

function createQueryParams(commentId: string, relatedId: string) {
  return createShowCommentQueryParams({
    recordId: relatedId,
    commentId,
  });
}

function isOnlyProjectTrackingEmployeeWorkload(
  related: CommentRelatedTimeSheetDayFragment,
): boolean {
  const workload = getWorkloadForDate(related.timeSheet.workloads, new Date());
  return (
    workload?.flair__Time_Tracking_Type__c ===
    WorkloadTrackingType.ProjectTracking
  );
}
