import React, { useCallback, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { ExpensesTable } from '../../pages/Expenses/ExpensesTable';
import {
  collectCategoryFilterOptions,
  collectYearFilterOptions,
  ExpensesFilter,
} from '../../pages/Expenses/Filters/ExpensesFilter';
import { ExpenseSidebar } from '../../pages/Expenses/ExpenseSidebar/ExpenseSidebar';
import {
  ExpenseStatus,
  useManagerExpensesQuery,
  useUpdateExpenseStatusMutation,
} from '../../__generated__/graphql';
import {
  emptyExpenseFilter,
  Expense,
  ExpenseFilter,
  ExpenseStatusInfo,
} from '../../pages/Expenses/types';
import { mapExpense } from '../../pages/Expenses/mappings';
import ServerError from '../../../../components/ServerError';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import ConfirmExpenseDeclineModal from '../../pages/Expenses/ConfirmExpenseDeclineModal';
import { SkeletonTable } from '../../components/Skeleton/Table';
import { routes as mainRoutes } from '../../routes';
import { applyExpenseFilter } from '../../pages/Expenses/filters';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../components/ManagerReportsModeSwitch';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useHistory, useParams } from 'react-router-dom';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import {
  createShowCommentQueryParams,
  useSelectedItemWithComments,
} from '../../components/Comment';
import { Item } from '../../pages/Expenses/useExpenseTableRenderSettings';

const Expenses: React.FC = () => {
  const history = useHistory();
  const { id: routeExpenseId } = useParams<{ id: string }>();
  const { defaultSelectedItem: selectedItemWithComments } =
    useSelectedItemWithComments();
  const tNavigation = useNamespacedTranslation('navigation.menuItems.expenses');
  useFlairBreadcrumbHook([{ label: tNavigation('title') }]);

  const [reportsMode, setReportsMode] = useManagerReportsMode('expenses');

  const { data, loading, error } = useManagerExpensesQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });
  const [updateExpenseStatus] = useUpdateExpenseStatusMutation();
  const [updatingItems, setUpdatingItems] = useState<ExpenseStatusInfo[]>([]);
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const { t } = useTranslation();

  const [declineExpenseId, setDeclineExpenseId] = useState<string | null>(null);

  const [filter, setFilter] = useFlairPersistentFilter<ExpenseFilter>({
    defaultFilter: emptyExpenseFilter,
    storageKey: 'manager_expense_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.expenses.route.withQueryParams(queryParams).create({}),
  });

  const onDeclineModalClose = useCallback(
    () => setDeclineExpenseId(null),
    [setDeclineExpenseId],
  );

  const expenseStatusChange = useCallback(
    (info: ExpenseStatusInfo) => {
      setUpdatingItems((prev) => [...prev, info]);
      return updateExpenseStatus({
        variables: {
          input: {
            id: info.expenseId,
            status: info.status as ExpenseStatus,
            declineReason: '',
          },
        },
      })
        .then(() => {
          addSuccess(t('expenses.statusUpdatedToast'));
          setDeclineExpenseId(null);
        })
        .catch(errorHandler)
        .finally(() => {
          setUpdatingItems((prev) =>
            prev.filter((x) => x.expenseId !== info.expenseId),
          );
        });
    },
    [updateExpenseStatus, setUpdatingItems, addSuccess, errorHandler, t],
  );

  const handleStatusChange = useCallback(
    (info: ExpenseStatusInfo) => {
      if (info.status === 'DECLINED') {
        setDeclineExpenseId(info.expenseId);
        return Promise.resolve();
      }
      return expenseStatusChange(info);
    },
    [expenseStatusChange],
  );

  const handleExpenseDeclineSubmit = useCallback(
    (declineReason: string | null, expenseId: string) => {
      return expenseStatusChange({
        expenseId,
        status: ExpenseStatus.Declined,
        declineReason: declineReason,
      });
    },
    [expenseStatusChange],
  );

  if (error) {
    return <ServerError />;
  }

  const handleOnItemClick = (item: Maybe<Item>) => {
    if (item?.id) {
      const currentQueryParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
      );

      const commentParams = item.showComments
        ? createShowCommentQueryParams({ recordId: item.id })
        : {};

      const allQueryParams = {
        ...currentQueryParams,
        ...commentParams,
      };

      history.push(
        mainRoutes.expense.route
          .withQueryParams(allQueryParams)
          .create({ id: item.id }),
      );
    } else {
      history.push(mainRoutes.expenses.route.create({}));
    }
  };

  const allExpenses: Expense[] = data?.manager.expenses.map(mapExpense) || [];

  const renderContent = () => {
    if (loading || !data) {
      return <Loading />;
    }

    const expenses = applyExpenseFilter(filter, allExpenses);

    return (
      <ExpensesTable
        onItemClick={handleOnItemClick}
        expenses={expenses}
        updatingItems={updatingItems}
        onStatusChange={handleStatusChange}
      />
    );
  };

  const selectedItemId = routeExpenseId ?? selectedItemWithComments?.id;
  const showComments = selectedItemWithComments?.showComments ?? false;
  let item = allExpenses?.find((x) => x.id === selectedItemId) ?? null;
  return (
    <>
      <PageHeader
        title={t('expenses.title')}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }
      />
      <Card>
        <ExpensesFilter
          filter={filter}
          categoryOptions={collectCategoryFilterOptions(allExpenses)}
          yearOptions={collectYearFilterOptions(allExpenses)}
          onChange={setFilter}
        />
        {renderContent()}
      </Card>
      {item && (
        <ExpenseSidebar
          isManager={true}
          expense={item}
          onStatusChange={handleStatusChange}
          onClose={() => handleOnItemClick(null)}
          showComments={showComments}
        />
      )}
      <ConfirmExpenseDeclineModal
        expenseId={declineExpenseId}
        show={!!declineExpenseId}
        onClose={onDeclineModalClose}
        onSubmit={handleExpenseDeclineSubmit}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={5} rows={5} />;
};

export default Expenses;
