import { formatISO, startOfDay } from 'date-fns';
import { parseDate } from '../../../../../utils/dateUtils';
import {
  GeneratedAbsence,
  GeneratedApprovalRequest,
  GeneratedApprover,
  GeneratedApproverAvatar,
  ApprovalRequest,
  Absence,
  ApproverAvatar,
  Approver,
} from './MyAbsenceFilters/types';
import i18next from 'i18next';
import { mapLoomVideo } from '../../../components/LoomVideo';

export const mapAvatar = (src: GeneratedApproverAvatar): ApproverAvatar => ({
  id: src?.Id,
  name: src?.Name,
  url: src?.url,
});

export const mapApprover = (src: GeneratedApprover): Approver => ({
  id: src.Id,
  name: src.Name,
  firstName: src.flair__First_Name__c,
  lastName: src.flair__Last_Name__c,
  avatar: mapAvatar(src.avatar),
});

export const mapApprovalRequests = (
  src: ReadonlyArray<GeneratedApprovalRequest> | null,
): ApprovalRequest[] => {
  return src
    ? src.map((item) => ({
        id: item.Id,
        approvalStatus: item.flair__Approval_Status__c,
        approverId: item.flair__Approver__c,
        approver: mapApprover(item.approver),
        approverComment: item.flair__Approver_Comment__c,
      }))
    : [];
};

export const mapAbsences = (
  now: Date,
  src: ReadonlyArray<GeneratedAbsence>,
): Absence[] => {
  return src.map((item) => mapAbsence(now, item));
};

const mapAbsence = (now: Date, item: GeneratedAbsence): Absence => ({
  ...item,
  id: item.Id,
  approvalStatus: item.flair__Approval_Status__c,
  categoryName: item.flair__Category_Name__c,
  categoryIcon: item.flair__Category_Icon__c,
  amount: item.flair__Amount__c,
  workingAmount: item.flair__Working_Amount__c,
  startDate: item.flair__Start_Date__c,
  startTime: item.flair__Start_Time__c,
  startDateHalfDay: item.flair__Start_Date_Half_Day__c,
  endDate: item.flair__End_Date__c,
  endTime: item.flair__End_Time__c,
  endDateHalfDay: item.flair__End_Date_Half_Day__c,
  categoryRestrictAbsenceDeletion:
    item.flair__Category_Restrict_Absence_Deletion__c ?? false,
  type: item.flair__Type__c,
  createdDate: item.CreatedDate ?? formatISO(now),
  approvalRequests: mapApprovalRequests(item.approvalRequests),
  recordId: item.Id,
  commentsCount: item.commentsCount,
  loomVideo: mapLoomVideo(item.loomVideo),
  ...mapDeleteDisabled(now, item.flair__Start_Date__c),
});

export const mapDeleteDisabled = (now: Date, startDate: string) => {
  if (startOfDay(parseDate(startDate)) <= startOfDay(now)) {
    return {
      deleteDisabled: {
        reason: i18next.t(
          'absences.cards.upcomingAbsences.actions.deleteDisabledReason',
        ),
      },
    };
  }
  return {};
};
